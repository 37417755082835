import React from 'react';
import styled from "styled-components";


const DictWrapper = styled.div`
  background: #38466D;
  border: 1px solid #2C3653;
  padding: 2px 3px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  .tier_color {
    &.challenger {
      color: #DB7F48;
    }
    &.grandmaster {
      color: #CF475B;
    }
    &.master {
      color: #A67ACB;
    }
    &.diamond {
      color: #2CABE2;
    }
    &.platinum {
      color: #20B19D;
    }
    &.gold {
      color: #BA8D26;
    }
    &.silver {
      color: #8A9BD5;
    }
    &.bronze {
      color: #BBA05D;
    }
    &.iron {
      color: #ACACAC;
    }
    &.unrank {
      color: #313131;
    }
`;

const DictText = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 11px;
  text-transform: capitalize;
`;

const Year = styled.i`
  font-size: 11px;
  display: inline-block;
  color: #E5E5E5;
`;


const SummonerDict = ({dict}) => {

    return (
        <>
            {dict && Object.entries(dict).map((item, index) => {
                const [key, value] = item;
                return (
                    <DictWrapper key={index + 'dict'}>
                        <Year>2021 &nbsp;
                            <DictText
                                className={`tier_color ${value.toLowerCase()}`}>{value.toLowerCase()}</DictText>
                        </Year>
                    </DictWrapper>
                );
            })}

        </>
    );
};

export default SummonerDict;