import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useTranslation} from 'react-i18next';

const ChartAi = ({data}) => {
    const {t} = useTranslation();
    const {scoreArr, xAxisArr, gameArr, winloseArr, ratingArr} = data;
    let last10scoreArr = [];
    let last10xAxisArr = [];
    let last10gameArr = [];
    let last10winloseArr = [];
    let last10ratingArr = [];
    if (gameArr !== undefined) {
        last10scoreArr = scoreArr.length > 10 ? scoreArr.slice(-10) : scoreArr;
        last10xAxisArr = xAxisArr.length > 10 ? xAxisArr.slice(-10) : xAxisArr;
        last10gameArr = gameArr.length > 10 ? gameArr.slice(-10) : gameArr;
        last10winloseArr = winloseArr.length > 10 ? winloseArr.slice(-10) : winloseArr;
        last10ratingArr = ratingArr.length > 10 ? ratingArr.slice(-10) : ratingArr;
    }

    const options = {
        chart: {
            type: 'line',
            plotBackgroundColor: '#EAECEE',
            backgroundColor: 'none',
            width: 530,
            height: 190,
            marginTop: 28,
            marginBottom: 65,
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        legend: {},
        xAxis: {
            labels: {
                style: {
                    fontFamily: 'Noto Sans KR',
                    fontSize: '11px',
                    color: '#313131',
                    margin: 0,
                    border: 0
                },
                useHTML: true,
                align: 'center',
                y: 12,
                formatter: function () {
                    if (this.pos !== 0) return;
                    let result = '<div class="chart_ai_label" >';
                    result += `<div style="display: flex; flex: 1 1 100%; background-color: white; flex-direction: row;">`
                    for (let pos in last10winloseArr) {
                        const winlose = last10winloseArr[pos]
                            ? t('summoner.contractionWin')
                            : t('summoner.contractionLose');
                        result += `<span class="${last10winloseArr[pos] ? 'win' : 'lose'}" style="${Number(pos) !== last10winloseArr.length - 1 ? 'margin-right: 1px;' : ""}">${winlose}</span>`
                    }
                    result += '</div>';


                    result += `<div style="display: flex; flex: 1 1 100%; background-color: white; flex-direction: row;">`
                    for (let pos in last10winloseArr) {
                        result += `<span class="rating" style="${Number(pos) !== last10winloseArr.length - 1 ? 'margin-right: 1px;' : ""}">${
                            last10ratingArr[pos] === 1
                                ? last10ratingArr[pos] + t('summoner.firstGrade')
                                : last10ratingArr[pos] === 2
                                    ? last10ratingArr[pos] + t('summoner.secondGrade')
                                    : last10ratingArr[pos] === 3
                                        ? last10ratingArr[pos] + t('summoner.thirdGrade')
                                        : last10ratingArr[pos] + t('summoner.grade')
                        }</span>`
                    }
                    result += '</div>';
                    result += `<div style="display: flex; flex: 1 1 100%; background-color: white; flex-direction: row;">`

                    const reduced = last10xAxisArr.reduce((sum, data, index) => {
                        const newDate = data.setHours(0, 0, 0, 0);
                        const findIndex = sum.findIndex((current, index) => {
                            const zeroHour = current.data.setHours(0, 0, 0, 0);
                            return zeroHour === newDate;
                        })

                        if (findIndex === -1) {
                            sum.push({
                                data: data,
                                count: 1,
                            })
                        } else {
                            sum[findIndex] = {
                                ...sum[findIndex],
                                count: sum[findIndex].count + 1,
                            }
                        }

                        return sum;
                    }, [])


                    for (let pos in reduced) {
                        let newResult = reduced[pos].data;
                        if (newResult) {
                            result += `<span class="date" style="flex:${(reduced[pos].count / last10xAxisArr.length) + reduced[pos].count - 0.02};
                                                ${Number(pos) !== reduced.length - 1 ? 'margin-right: 1px;' : ""} ">${newResult.getMonth() + 1}.${newResult.getDate()}</span>`
                        }
                    }
                    result += '</div></div>';
                    return result;
                },
            },
            gridLineWidth: 1,
            gridLineColor: '#fff',
            categories: last10xAxisArr, // 보여지는 x축 값
        },
        yAxis: {
            title: {
                enabled: false,
            },
            labels: {
                style: {
                    fontFamily: 'Noto Sans KR',
                    fontSize: '11px',
                    color: 'rgba(49, 49, 49, 0.6)',
                },
            },
            min: 0,
            max: 100,
            gridLineWidth: 0,
            lineWidth: 0,
            lineColor: '#f4f4f5',
            plotLines: [
                {
                    color: '#006D24',
                    width: 1,
                    value: 50,
                    dashStyle: 'Dash',
                },
            ],
            opposite: false,
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    padding: 0,
                    formatter: function () {
                        let result = '';
                        // 보통 상태
                        if (this.y >= 50) {
                            result = `<span class="chart_dot good">${this.y.toFixed()}</span>`;
                        } else {
                            result = `<span class="chart_dot bad">${this.y.toFixed()}</span>`;
                        }
                        // mvp or ace 상태
                        for (let i = 0; i < last10gameArr.length; i++) {
                            if (this.y === last10gameArr[i].minmax_z * 100 && last10gameArr[i].mvp) {
                                if (this.y >= 50) {
                                    result = `<span class="chart_dot good"><i class="crown mvp"></i>${this.y.toFixed()}</span>`;
                                } else {
                                    result = `<span class="chart_dot bad"><i class="crown mvp"></i>${this.y.toFixed()}</span>`;
                                }
                            } else if (this.y === last10gameArr[i].minmax_z * 100 && last10gameArr[i].ace) {
                                if (this.y >= 50) {
                                    result = `<span class="chart_dot good"><i class="crown ace"></i>${this.y.toFixed()}</span>`;
                                } else {
                                    result = `<span class="chart_dot bad"><i class="crown ace"></i>${this.y.toFixed()}</span>`;
                                }
                            }
                        }
                        return result;
                    },
                },
                marker: {
                    enabled: true,
                    lineWidth: 2,
                    radius: 6,
                    width: 6,
                },
            },
        },
        series: [
            {
                name: 'ai score',
                type: 'line',
                showInLegend: false,
                data: last10scoreArr,
                zones: [
                    {
                        value: 24,
                        color: '#69788d',
                    },
                    {
                        value: 49,
                        color: '#de5353',
                    },
                    {
                        value: 75,
                        color: '#259a7e',
                    },
                    {
                        color: '#f19000',
                    },
                ],
                lineWidth: 2,
            },
        ],
        tooltip: {
            enabled: false,
        },
    };

    return (
        <div className="chart_box">
            <h5>AI-Score Graph</h5>
            <HighchartsReact highcharts={Highcharts} options={options}/>
        </div>
    );
};

export default React.memo(ChartAi);
