import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Loading} from '../../components';
import {tierText} from '../../function';
import SummonerDict from "../common/SummonerDict";
import IngameEmblem from "./Ingame/IngameEmblem";
import styled from "styled-components";
import SummonerTags from "./SummonerTags";
import InfluInfo from "./Profile/InfluInfo";


const DictWrapper = styled.div`

  min-height: 15px;
  min-width: 60px;
`;

const TagWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
  flex-direction: ${props => {
    let direction = 'row';
    if (props.side === 'red') direction = 'row-reverse';
    return direction;
  }};
`;
const IngameSummoner = ({gameInfo, teamInfo, lane, duoList, tierInfo, side, feature, queue, ranks}) => {


    console.log(side);
    const {t} = useTranslation();
    const region = useSelector((state) => state.region.result);
    const [summoner, setSummoner] = useState([]);
    // const rankQueue =
    //   queue === 420 ? 'RANKED_SOLO_5x5' : queue === 440 ? 'RANKED_FLEX_SR' : 'RANKED_SOLO_5x5';
    const rankQueue = 'RANKED_SOLO_5x5';
    const getData = useCallback(() => {

        let summonerName = teamInfo.summonerName.replace(/\s/gi, '');
        if (summonerName.length === 2) {
            const result = teamInfo.summonerName.split('').join('%20');
            summonerName = result;
        }
        axios.get(`${process.env.REACT_APP_API}summoner?summonerName=${encodeURI(summonerName)}&region=${region}`)
            .then((res) => {
                const result = res.data.result;
                setSummoner(result);
                if (result.rank_json[rankQueue]) {
                    tierInfo(
                        result.rank_json[rankQueue].tier,
                        result.rank_json[rankQueue].division,
                        side,
                        lane,
                        feature
                    );
                }
            });
    }, [feature, lane, region, side, teamInfo.summonerId, tierInfo]);

    useEffect(() => {
        if (summoner.length === 0) {
            getData();
        }
    }, [getData, summoner]);

    return (
        <div>
            <div className="summoner_info">
                {(summoner.rank_json !== undefined && summoner.rank_json != {}) ? (
                    <>
                        <TagWrapper side={side}>
                            <DictWrapper>
                                <SummonerDict
                                    dict={summoner.season_tier_dict}
                                />
                            </DictWrapper>
                            <SummonerTags
                                ranks={ranks}
                                data={gameInfo}
                            />
                        </TagWrapper>
                        <IngameEmblem
                            summoner={summoner}
                            gameInfo={gameInfo}
                            lane={lane}
                            rankQueue={rankQueue}
                        />
                        <div className="profile_data">
                            {summoner.rank_json[rankQueue]?.hasOwnProperty('tier') ? (
                                <div className="texts">

                                    {summoner?.pro_streamer_info?.status !== '-' &&
                                    <dl className="t2">
                                        <InfluInfo
                                            maxWidthText={80}
                                            direction={side === 'blue' ? "row" : "row-reverse"}
                                            textColor={'#000'}
                                            showLinks={false}
                                            influData={summoner.pro_streamer_info}
                                        />
                                    </dl>}
                                    {/*                {summoner.pro_streamer_info.team !== '-' && (*/}
                                    {/*                    <span className="pro">*/}
                                    {/*{summoner.pro_streamer_info.team.length > 14*/}
                                    {/*    ? summoner.pro_streamer_info.team*/}
                                    {/*    : summoner.pro_streamer_info.alias}{' '}*/}
                                    {/*                        {summoner.pro_streamer_info.name}</span>*/}
                                    {/*                )}*/}

                                    <Link
                                        replace
                                        to={`/summoner/${summoner.summoner_name}/${region}`}
                                        target="_blank"
                                        className="common_search"
                                    >
                                        {summoner.summoner_name}
                                    </Link>
                                    <dl className="t1">
                                        <dt>
                                            {tierText(
                                                summoner.rank_json[rankQueue].tier,
                                                summoner.rank_json[rankQueue].division
                                            )}
                                            /
                                        </dt>
                                        <dd>{summoner.rank_json[rankQueue].leaguePoints.toLocaleString()} LP</dd>
                                    </dl>
                                    <dl className="t2">
                                        {Object.values(summoner.rank_json[rankQueue]).length !== 0 ? (
                                            <dt>
                                                <span>
                                                    {isNaN(
                                                        summoner.rank_json[rankQueue].wins /
                                                        (summoner.rank_json[rankQueue].wins +
                                                            summoner.rank_json[rankQueue].losses)
                                                    )
                                                        ? 0 : (
                                                            (summoner.rank_json[rankQueue].wins /
                                                                (summoner.rank_json[rankQueue].wins +
                                                                    summoner.rank_json[rankQueue].losses)) * 100).toFixed(1)}
                                                </span>
                                                %
                                            </dt>
                                        ) : (
                                            <dt>
                                                <span>-</span>%
                                            </dt>
                                        )}
                                        {Object.values(summoner.rank_json[rankQueue]).length !== 0 ? (
                                            <dd>
                      <span>
                        {(
                            summoner.rank_json[rankQueue].wins + summoner.rank_json[rankQueue].losses
                        ).toLocaleString()}
                      </span>
                                                {t('summoner.games')}
                                            </dd>
                                        ) : (
                                            <dd>
                                                <span>-</span>
                                                {t('summoner.games')}
                                            </dd>
                                        )}
                                    </dl>
                                    {summoner.rank_json[rankQueue].promos && (
                                        <div className="promos">
                                            {summoner.rank_json[rankQueue].promos.progress_list.map((item, index) => (
                                                <span key={index} className={item}>{item}</span>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ) : (

                                <>
                                    <div className="texts">
                                        <div style={{
                                            display: 'inline-block'
                                        }}>
                                            <Link
                                                replace
                                                to={`/summoner/${summoner.summoner_name}/${region}`}
                                                target="_blank"
                                                className="common_search"
                                            >
                                                {summoner.summoner_name}
                                            </Link>
                                        </div>
                                        <div>{t('summoner.unranked')}</div>
                                    </div>
                                </>

                            )}
                        </div>
                    </>
                ) : (
                    <Loading/>
                )}
            </div>
        </div>
    );
};

export default React.memo(IngameSummoner);
