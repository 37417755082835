import React, {useCallback, useEffect, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';

import {ChartAnalysis, ChartScore, ImageChamp, ImageLane, Loading} from '../../components';
import {getSortedArray, getSortedObject, scoreColor} from '../../function';
import {useTranslation} from "react-i18next";


const DetailTab2 = ({match, myMatch}) => {
    const [originArr, setOriginArr] = useState([]);
    const [ratingAI, setRatingAI] = useState([]);
    const [defaultTabIndex] = useState(myMatch.frameId - 1);

    const initialFunc = useCallback(() => {
        // 원래 match_json
        setOriginArr(getSortedObject(getSortedArray(match.match_json), 'frameId', 'ascending'));

        // AI스코어순 정렬
        setRatingAI(getSortedObject(getSortedArray(match.match_json), 'minmax_z', 'descending'));
    }, [match]);

    useEffect(() => {
        initialFunc();
    }, [initialFunc]);

    return (
        <>
            {ratingAI.length !== 0 ? (
                <Tabs className="analysis_time" defaultIndex={defaultTabIndex}>
                    <TabList className="tab">
                        <div className="t_head">
              <span>
                <ImageLane lane="Top"/>
                <i>Top</i>
              </span>
                            <span>
                <ImageLane lane="Jungle"/>
                <i>Jug</i>
              </span>
                            <span>
                <ImageLane lane="Middle"/>
                <i>Mid</i>
              </span>
                            <span>
                <ImageLane lane="Bot"/>
                <i>Bot</i>
              </span>
                            <span>
                <ImageLane lane="Supporter"/>
                <i>Sup</i>
              </span>
                        </div>
                        <div className="t_body">
                            <div className="team">
                                {originArr.map((item) => (
                                    <CustomTab
                                        info={item}
                                        myMatch={myMatch}
                                        className={`${item.win ? 'win' : 'lose'}`}
                                        key={item.frameId}
                                    >
                                        {match}
                                    </CustomTab>
                                ))}
                            </div>
                        </div>
                    </TabList>
                    {originArr.map((item) => (
                        <CustomTabPanel info={item} myMatch={myMatch} key={item.frameId} originArr={originArr}>
                            {match}
                        </CustomTabPanel>
                    ))}
                </Tabs>
            ) : (
                <Loading/>
            )}
        </>
    );
};

// 시간대별 분석 탭 버튼
const CustomTab = ({children, info, myMatch, ...otherProps}) => {
    const {t} = useTranslation();
    return (
        <Tab {...otherProps}>
            <div className="rating">
                {info.mvp && <span className="crown mvp"></span>}
                {info.ace && <span className="crown ace"></span>}
                {info.rating === 1
                    ? `1${t('summoner.firstGrade')}`
                    : info.rating === 2
                        ? `2${t('summoner.secondGrade')}`
                        : info.rating === 3
                            ? `3${t('summoner.thirdGrade')}`
                            : info.rating + t('summoner.grade')}
            </div>
            <div className="champ">
                <ImageChamp champId={info.championId}/>
            </div>
            <div className="score">{scoreColor(info.ai_score)}</div>
            <div className="chart">
                <ChartScore
                    data={children.inference_json}
                    myMatch={info}
                    width={90}
                    height={40}
                    label={false}
                />
            </div>
        </Tab>
    )
};
CustomTab.tabsRole = 'Tab';

// 시간대별 분석 탭 패널
const CustomTabPanel = ({children, info, myMatch, originArr, ...otherProps}) => (
    <TabPanel {...otherProps}>
        <ChartAnalysis
            inferenceData={children.inference_json}
            info={info}
            myMatch={myMatch}
            originArr={originArr}
        />
    </TabPanel>
);
CustomTabPanel.tabsRole = 'TabPanel';

export default DetailTab2;
