import _ from 'lodash';

// 액션 생성
const BOOKMARKLIST = 'bookmarkList/BOOKMARKLIST';

// 액션 함수 내보내기
export const bookmarkList = (bookmarks, flag) => ({ type: BOOKMARKLIST, bookmarks, flag });

// 초기 상태 값
const initialState = {
  bookmarks: [],
  flag: 'add',
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOKMARKLIST:
      return {
        bookmarks:
          action.flag === 'add'
            ? _.uniqBy([...state.bookmarks, action.bookmarks], (e) => e.summoner_id)
            : state.bookmarks.filter((e) => e.summoner_id !== action.bookmarks.summoner_id),
      };
    default:
      return state;
  }
};

export default reducer;
