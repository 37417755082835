import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Overview from './Overview';
import MatchItem from './MatchItem';
import {MatchListContainer} from '../../styled/SummonerStyle';
import {GET_MATCH_BY_QUEUE_REQUEST, GET_MORE_MATCHES_REQUEST} from "../../store/reducer/summoner";
import Loading from "../common/Loading";
import OverviewSkeleton from "../Skeleton/Overview";


const MatchList = ({updatePer, region}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const summoner = useSelector((state) => state.summoner.summoner);
    const {getSummonerInfoDone, getMoreMatchesDone,
        getMatchByQueueLoading,
        getMatchByQueueDone,
        getMatchByQueueError} = useSelector((state) => state.summoner);
    const [openId, setOpenId] = useState(0);
    const [queueNum, setQueueNum] = useState(0);
    const [champId, setChampId] = useState(0);
    const matchInfo = useSelector((state) => state.summoner.match);
    const currentLang = useSelector((state) => state.translation.lang);
    const [disabled, setDisabled] = useState(false);



    useEffect(() => {
        if (getMoreMatchesDone) {
            setDisabled(false);
        }
    }, [getMoreMatchesDone])


    const onPressHandleMoreGame = (summoner, btn) => {
        setDisabled(true);
        dispatch({
            type: GET_MORE_MATCHES_REQUEST,
            data: {
                champId: champId,
                queueNum: queueNum,
                region: region,
            }
        })
    }

    // 챔피언 선택
    const handleChampFilter = (champId) => {
        const result = Number(champId);
        setChampId(result);
        dispatch({
            type: GET_MATCH_BY_QUEUE_REQUEST,
            data: {
                champId: result ? result : 0,
                queueNum: queueNum,
                region: region,
            }
        })

    };

    // 큐 선택
    const handleQueueFilter = (queueNum) => {
        setQueueNum(queueNum);
        dispatch({
            type: GET_MATCH_BY_QUEUE_REQUEST,
            data: {
                champId: champId,
                queueNum: queueNum,
                region: region,
            }
        })
    };

    // 정보 더 보기
    const handleMoreInfo = (gameId) => {
        const currentId = openId;
        if (currentId === gameId) {
            setOpenId(0);
        } else {
            setOpenId(gameId);
        }
    };


    if (!getSummonerInfoDone) return (
        <div style={{height: 100}}>
            <Loading/>
        </div>
    )

    return (
        <>
            <Overview
                matchInfo={matchInfo}
                handleQueueFilter={handleQueueFilter}
                handleChampFilter={handleChampFilter}
                queueNum={queueNum}
            />
            {Array.isArray(matchInfo) && matchInfo.length !== 0 ? (
                <>

                    <MatchListContainer>
                        {matchInfo.map((match, index) => {
                            return (
                                <MatchItem
                                    key={index + 'match'}
                                    summoner={summoner}
                                    currentLang={currentLang}
                                    region={region}
                                    match={match}
                                    openId={openId}
                                    handleMoreInfo={handleMoreInfo}
                                />

                            );
                        })}

                        <button
                            className="more_game"
                            disabled={disabled}
                            onClick={() => onPressHandleMoreGame(summoner, 'btnClick')}
                        >
                            {updatePer !== 100 && <i className="fill" style={{width: updatePer + '%'}}></i>}
                            <span>{`${updatePer !== 100 ? updatePer + '%' : t('summoner.more')}`}</span>
                        </button>
                    </MatchListContainer>
                </>
            ) : (
                <div className="no_game">
                    <p>{t('notice.noGame')}</p>
                </div>
            )}
        </>
    );
};

export default React.memo(MatchList);
