import React from 'react';
import styled from "styled-components";

const SkeletonWrapper = styled.div`
  background-color: #ccc
`

const SkeletonImage = ({width = 60,height = 60}) => {
    return (
        <>
            <SkeletonWrapper>

            </SkeletonWrapper>
        </>
    );
};

export default SkeletonImage;