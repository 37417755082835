// 액션 생성
const LANGUAGE = 'translation/LANGUAGE';

// 액션 함수 내보내기
export const translation = (lang) => ({ type: LANGUAGE, lang });

// 초기 상태 값
const lang = window.navigator.language.toLowerCase().substring(0, 2);

const initialState = {
  lang: lang === 'ko' ? 'KOR' : 'ENG',
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE:
      const subText = action.lang.toLowerCase().substring(0, 2);

      if(subText) {
        const meta = document.querySelector('meta[http-equiv=content-language]');
        meta.setAttribute('content',subText === 'ko' ? 'ko' : 'en');
      }

      return {
        lang: subText === 'ko' ? 'KOR' : 'ENG',
      };
    default:
      return state;
  }
};
export default reducer;
