import React, {useCallback, useEffect, useState} from 'react';

import IngameItem from './IngameItem';
import {getTierScore} from '../../function';

const IngameList = ({ingameBasic, ingamePart, getAvg, getAvgTier, getWinrate}) => {
    const [updatedState, setUpdatedState] = useState({});
    const [list, setList] = useState([]);
    const [blueDuoList, setBlueDuoList] = useState([]);
    const [redDuoList, setRedDuoList] = useState([]);
    const [blueTierArr, setBlueTierArr] = useState([]);
    const [redTierArr, setRedTierArr] = useState([]);
    const [blueObj, setBlueObj] = useState([]);
    const [redObj, setRedObj] = useState([]);
    const [openLane, setOpenLane] = useState(null);

    // 정보 더 보기
    const handleMoreInfo = (gameLane) => {
        const currentLane = openLane;
        if (currentLane === gameLane) {
            setOpenLane(null);
        } else {
            setOpenLane(gameLane);
        }
    };

    // 평균값 구하는 함수
    const getAverage = (arr) => {
        return arr.reduce((total, current) => (total += current), 0) / arr.length;
    };

    // 팀별 티어 가져오기
    const tierInfo = useCallback((tier = 'IRON', division = 'IV', side, lane, feature) => {
        let name = '';
        switch (lane) {
            case 'Top':
                name = 'top';
                break;
            case 'Jungle':
                name = 'jun';
                break;
            case 'Middle':
                name = 'mid';
                break;
            case 'Bot':
                name = 'bot';
                break;
            case 'Supporter':
                name = 'sup';
                break;
            default:
                name = '';
                break;
        }
        if (side === 'blue') {
            setBlueTierArr((prev) => [...prev, getTierScore(`${tier} ${division}`).num]);
            setBlueObj((prev) => [...prev, {feature, tier, division, name}]);
        } else if (side === 'red') {
            setRedTierArr((prev) => [...prev, getTierScore(`${tier} ${division}`).num]);
            setRedObj((prev) => [...prev, {feature, tier, division, name}]);
        }
    }, []);

    // 모델용 데이터
    const getUseModelData = useCallback(
        (blue, red) => {
            let result = {
                top: [],
                jun: [],
                mid: [],
                bot: [],
                sup: [],
                tier: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                division: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            };
            let laneList = ['top', 'jun', 'mid', 'bot', 'sup'];
            for (let i = 0; i < blue.length; i++) {
                result.tier[laneList.indexOf(blue[i].name)] = blue[i].tier;
                result.division[laneList.indexOf(blue[i].name)] = blue[i].division;
                if (blue[i].name === 'top') {
                    result.top = blue[i].feature;
                } else if (blue[i].name === 'jun') {
                    result.jun = blue[i].feature;
                } else if (blue[i].name === 'mid') {
                    result.mid = blue[i].feature;
                } else if (blue[i].name === 'bot') {
                    result.bot = blue[i].feature;
                } else if (blue[i].name === 'sup') {
                    result.sup = blue[i].feature;
                }
            }
            for (let i = 0; i < red.length; i++) {
                result.tier[laneList.indexOf(red[i].name) + 5] = red[i].tier;
                result.division[laneList.indexOf(red[i].name) + 5] = red[i].division;
            }
            getWinrate(result);
        },
        [getWinrate]
    );

    // 팀별 점수, 등수 구하기
    const getProcessData = useCallback(
        (arr) => {
            let newArr = [];
            arr.forEach((item, index) => {
                if (index !== 0) {
                    newArr.push(item);
                }
            });

            let blueTeam = {
                score: 0,
                rating: 0,
            };
            let redTeam = {
                score: 0,
                rating: 0,
            };
            let blueDuo = [];
            let redDuo = [];

            let blueLength = 0;
            let redLength = 0;
            newArr.forEach((item, index) => {
                if (item.B.summoner_match_list.length !== 0) {
                    blueTeam.score += item.B.ai_score_total;
                    blueTeam.rating += item.B.ai_score_rank_total;
                    blueLength += 1;
                }
                if (item.R.summoner_match_list.length !== 0) {
                    redTeam.score += item.R.ai_score_total;
                    redTeam.rating += item.R.ai_score_rank_total;
                    redLength += 1;
                }

                let blueId = 0;
                let blueSide = '';
                item.B.summoner_match_list.forEach((itm) => {
                    blueId = itm.matchId;
                    blueSide = itm.side;
                    newArr.forEach((ii, xx) => {
                        if (index !== xx) {
                            ii.B.summoner_match_list.forEach((i) => {
                                if (blueId === i.matchId && blueSide === i.side) {
                                    blueDuo.push(ii.B.summoner_id);
                                }
                            });
                        }
                    });
                });

                let redId = 0;
                let redSide = '';
                item.R.summoner_match_list.forEach((itm) => {
                    redId = itm.matchId;
                    redSide = itm.side;
                    newArr.forEach((ii, xx) => {
                        if (index !== xx) {
                            ii.R.summoner_match_list.forEach((i) => {
                                if (redId === i.matchId && redSide === i.side) {
                                    redDuo.push(ii.R.summoner_id);
                                }
                            });
                        }
                    });
                });
            });

            let b_set = new Set(blueDuo);
            setBlueDuoList([...b_set]);
            let r_set = new Set(redDuo);
            setRedDuoList([...r_set]);

            getAvg(blueTeam, redTeam, blueLength, redLength);

            if (newArr.length === 5) {
                getUseModelData(blueObj, redObj);
            }
        },
        [getAvg, getUseModelData, blueObj, redObj]
    );

    useEffect(() => {
        setList((prev) => {
            return [...prev, updatedState];
        });
    }, [updatedState]);

    useEffect(() => {
        getProcessData(list);
    }, [getProcessData, list]);

    useEffect(() => {
        if (blueTierArr.length === 5 && redTierArr.length === 5) {
            const avg_b = getAverage(blueTierArr);
            const avg_r = getAverage(redTierArr);
            getAvgTier(avg_b, avg_r);
        }
    }, [blueTierArr, redTierArr, getAvgTier]);



    return (
        <ul className="ingame_list">
            {ingamePart.map((item, index) => {
                    if (ingameBasic.summoner_list[index] === undefined) return (<></>);
                    let ranks = {B:0,R:0}
                    if(ingamePart.length > 4) {
                        ranks = ingamePart.reduce((sum, data, redueIndex) => {
                            if (index !== redueIndex) {
                                if (data.B.ai_score_total === item.B.ai_score_total || data.B.ai_score_total > item.B.ai_score_total) {
                                    sum.B += 1;
                                }
                                if (data.R.ai_score_total === item.R.ai_score_total || data.R.ai_score_total > item.R.ai_score_total) {
                                    sum.R += 1;
                                }

                            }
                            return sum;
                        }, {B: 1, R: 1});
                    }



                    return (
                        <IngameItem
                            ranks={ranks}
                            key={index}
                            part={item}
                            basick={ingameBasic.summoner_list[index]}
                            queue={ingameBasic.queue}
                            setUpdatedState={setUpdatedState}
                            blueDuoList={blueDuoList}
                            redDuoList={redDuoList}
                            tierInfo={tierInfo}
                            handleMoreInfo={handleMoreInfo}
                            openLane={openLane}
                        />
                    )
                }
            )}
        </ul>
    );
};

export default React.memo(IngameList);
