import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

import {getGameTime, getLPScore, getSortedObject, kdaColor, scoreColor, tierText,} from '../../function';
import {ChartLp, ImageChamp, Loading} from '../../components';
import {InfoContainer} from '../../styled/SummonerStyle';

const Info = ({region}) => {
    const {t} = useTranslation();
    const summoner = useSelector((state) => state.summoner.summoner);
    const summonerStat = useSelector((state) => state.summoner.summonerStatus);
    const currentLang = useSelector((state) => state.translation.lang);
    const tierChart = useSelector((state) => state.summoner.tierChart);
    const [soloTierChart, setSoloTierChart] = useState({});
    const [flexTierChart, setFlexTierChart] = useState({});


    useEffect(() => {
        if (tierChart) {
            for (const key in tierChart) {
                switch (key) {
                    case 'RANKED_SOLO_5x5':
                        setSoloTierChart(getChartOptions(tierChart[key]));
                        break;
                    case 'RANKED_FLEX_SR':
                        setFlexTierChart(getChartOptions(tierChart[key]));
                        break;
                    default:
                        break;
                }
            }
        }
    }, [tierChart])
    // 차트옵션 만들기
    const getChartOptions = useCallback(
        (data) => {
            let originalArr = []; // 원래 data값을 배열로
            let calculatedArr = []; // 계산된 lp 값
            let dateArr = []; // xAxis categories 값
            let tierArr = []; // tier, order 배열

            for (const i in data) {
                originalArr.push(data[i]);

                // 티어별 order값
                let tierOrder = 0;
                if (data[i].tier === 'IRON') tierOrder = 1;
                else if (data[i].tier === 'BRONZE') tierOrder = 2;
                else if (data[i].tier === 'SILVER') tierOrder = 3;
                else if (data[i].tier === 'GOLD') tierOrder = 4;
                else if (data[i].tier === 'PLATINUM') tierOrder = 5;
                else if (data[i].tier === 'DIAMOND') tierOrder = 6;
                else if (data[i].tier === 'MASTER') tierOrder = 7;
                else if (data[i].tier === 'GRANDMASTER') tierOrder = 8;
                else if (data[i].tier === 'CHALLENGER') tierOrder = 9;
                tierArr.push({
                    tier: data[i].tier,
                    order: tierOrder,
                });

                // 계산된 lp로 입력
                calculatedArr.push(getLPScore(data[i].tier, data[i].division, data[i].lp));

                // xAxis categories (날짜데이터)
                // let idxDate = new Date(i * 1000);
                let idxDate = getGameTime(i, currentLang);
                dateArr.push(idxDate);
            }

            // calculatedArr로 티어별 최소,최대값 구하기
            let minVal =
                Math.min.apply(null, calculatedArr) < 50 ? 0 : Math.min.apply(null, calculatedArr) - 50;
            let maxVal = Math.max.apply(null, calculatedArr) + 50;
            let yTickPositions = [minVal, maxVal]; // yAxis tickPositions 값

            // 객체배열 중복제거
            tierArr = _.uniqBy(tierArr, 'tier');
            tierArr = tierArr.sort(function (a, b) {
                // order 순으로 정렬
                return a.order - b.order;
            });

            // 티어별 yAxis tickPositions 값 넣기
            for (const i in tierArr) {
                if (tierArr[i].tier === 'IRON') yTickPositions.push(0);
                else if (tierArr[i].tier === 'BRONZE') yTickPositions.push(400);
                else if (tierArr[i].tier === 'SILVER') yTickPositions.push(800);
                else if (tierArr[i].tier === 'GOLD') yTickPositions.push(1200);
                else if (tierArr[i].tier === 'PLATINUM') yTickPositions.push(1600);
                else if (tierArr[i].tier === 'DIAMOND') yTickPositions.push(2000);
                else if (tierArr[i].tier === 'MASTER') yTickPositions.push(2400);
                else if (tierArr[i].tier === 'GRANDMASTER') yTickPositions.push(3400);
                else if (tierArr[i].tier === 'CHALLENGER') yTickPositions.push(4400);
            }

            // yTickPositions 앞뒤로 400 추가 (왜 하는거지?)
            let minTP = yTickPositions[0];
            let maxTP = yTickPositions[yTickPositions.length - 1];
            if (minTP !== 0) yTickPositions.unshift(minTP - 400);
            if (maxTP < 2400) yTickPositions.push(maxTP + 400);
            else yTickPositions.push(maxTP + 1000);

            // 내림차순 정렬
            yTickPositions = yTickPositions.sort(function (a, b) {
                return a - b;
            });

            return {originalArr, calculatedArr, dateArr, yTickPositions};
        },
        [currentLang]
    );

    // 소환사 데이터 가져오기
    // const getData = useCallback(() => {
    // 소환사 stat 정보
    // axios
    //   .get(
    //     `${process.env.REACT_APP_API}summoner_stat?summonerId=${summoner.summoner_id}&region=${region}&season=13`
    //   )
    //   .then((res) => {
    //     const data = res.data.result;
    //     setSummonerStat(data);
    //   });

    // 소환사 티어차트 정보
    //   axios
    //     .get(
    //       `${process.env.REACT_APP_API}tier_chart?summonerId=${summoner.summoner_id}&region=${region}`
    //     )
    //     .then((res) => {
    //       const data = res.data.result;
    //     });
    // }, [getChartOptions, region, summoner]);


    return (
        <InfoContainer>
            <div className="p__box">
                <Tabs className="tier">
                    <TabList>
                        {summoner?.rank_json['RANKED_SOLO_5x5'] ?
                            <CustomTab
                                info={summoner?.rank_json['RANKED_SOLO_5x5'] && summoner?.rank_json['RANKED_SOLO_5x5']}
                                summoner={summoner}>{t('summoner.rankedSolo')}</CustomTab>
                            :
                            <CustomTab info="Unranked">{t('summoner.rankedFlex')}</CustomTab>
                        }
                        {summoner?.rank_json['RANKED_FLEX_SR'] ?
                            <CustomTab info={summoner?.rank_json['RANKED_FLEX_SR']} summoner={summoner}
                                       flag={'flex'}>{t('summoner.rankedFlex')}</CustomTab>
                            :
                            <CustomTab info="Unranked">{t('summoner.rankedFlex')}</CustomTab>
                        }
                    </TabList>
                    <TabPanel>
                        <div className="title">
                            LP {t('summoner.graph')}{' '}
                            <span>
                ({t('summoner.recent')} 60{t('summoner.day')})
              </span>
                        </div>
                        {!summonerStat.total ? <Loading/> :
                            <ChartLp tierChart={soloTierChart}/>
                        }
                    </TabPanel>
                    <TabPanel>
                        <div className="title">
                            LP {t('summoner.graph')}{' '}
                            <span>
                ({t('summoner.recent')} 60{t('summoner.day')})
              </span>
                        </div>
                        {!summonerStat.total ? <Loading/> :
                            <ChartLp tierChart={flexTierChart}/>
                        }
                    </TabPanel>
                </Tabs>
                {!summonerStat.total ? (
                    <div className="champ">
                        <Loading/>
                    </div>
                ) : (
                    <Tabs className="champ">
                        <TabList>
                            <Tab>{t("tabs.total")}</Tab>
                            <Tab>{t("tabs.rankedSolo")}</Tab>
                            <Tab>{t("tabs.rankedFlex")}</Tab>
                        </TabList>
                        {Object.keys(summonerStat).map((item) => {
                            const result = getSortedObject(summonerStat[item], 'games');
                            return (
                                <CustomTabPanel key={item} stat={result}>
                                    {item}
                                </CustomTabPanel>
                            );
                        })}
                    </Tabs>
                )}
            </div>
        </InfoContainer>
    );
};

// 솔로랭크, 자유랭크 탭 버튼
const CustomTab = ({children, info, summoner, flag, ...otherProps}) => {
    const {t} = useTranslation();

    return (
        <Tab {...otherProps}>
            {info === 'Unranked' ? (
                <>
                    <div className="title">
                        <h5>{children}</h5>
                        <p></p>
                    </div>
                    <div className="info">
                        <img
                            src={`/images/Emblem_${info.toUpperCase()}.png`}
                            className="imgEmblem"
                            alt={info}
                        />
                        <div className="t_group">
                            <p className="t1">
                                <span>{info}</span>
                            </p>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="title">
                        <h5>{children}</h5>
                        {flag !== 'flex' &&
                        <dl>
                            {summoner.ranking === -1 ? null : (
                                <dt>
                                    {t('summoner.ranking')}
                                    <span>{summoner.ranking.toLocaleString('ko-KR')}</span>
                                </dt>
                            )}
                            {summoner.rank_rate === -1 ? null : <dd>({summoner.rank_rate}%)</dd>}
                        </dl>
                        }
                    </div>
                    <div className="info">
                        <img src={`/images/Emblem_${info.tier}.png`} className="imgEmblem" alt={info.tier}/>
                        <div className="t_group">
                            <p className="t1">{tierText(info.tier, info.division)}</p>
                            <p className="t2">
                <span className="lp">
                  <i>{info.leaguePoints}</i> LP
                </span>
                                <span className="game">
                  <i>{((info.wins / (info.wins + info.losses)) * 100).toFixed(1)}</i> %
                </span>
                            </p>
                            <p className="t3">
                <span className="game">
                  <i>{info.wins}</i> {t('summoner.contractionWin')}
                </span>
                                <span className="game">
                  <i>{info.losses}</i> {t('summoner.contractionLose')}
                </span>
                            </p>
                            {info.promos && (
                                <div className="promos">
                                    {info.promos.progress_list.map((item, index) => (
                                        <span key={index} className={item}>
                      {item}
                    </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </Tab>
    );
};
CustomTab.tabsRole = 'Tab';

// 챔피언 전적정보 탭 패널
const CustomTabPanel = ({children, stat, ...otherProps}) => {
    const {t} = useTranslation();

    return (
        <TabPanel {...otherProps}>
            {/* <h1>{children}</h1> */}
            <table>
                <colgroup>
                    <col width="10%"/>
                    <col width="10%"/>
                    <col width="12%"/>
                    <col width="18%"/>
                    <col width="10%"/>
                    <col width="11%"/>
                    <col width="*"/>
                    <col width="10%"/>
                </colgroup>
                <thead>
                <tr>
                    <th rowSpan="2">{t('tabs.champ')}</th>
                    <th rowSpan="2">{t('tabs.games')}</th>
                    <th rowSpan="2">{t('tabs.winrate')}</th>
                    <th rowSpan="2">{t('tabs.kda')}</th>
                    <th rowSpan="2">{t('tabs.csm')}</th>
                    <th rowSpan="2">{t('tabs.kp')}</th>
                    <th colSpan="2">AI-Score</th>
                </tr>
                <tr>
                    <th>
                        <p>
                            <span>{t('tabs.winlose')}</span>
                        </p>
                    </th>
                    <th>
                        <p>
                            <span>{t('tabs.avg')}</span>
                        </p>
                    </th>
                </tr>
                </thead>
                <tbody>
                {stat.length !== 0 ? (
                    stat.map(
                        (game, index) =>
                            index <= 4 && (
                                <tr key={game.cid}>
                                    <td>
                                        <ImageChamp champId={game.cid}/>
                                    </td>
                                    <td>
                                        <span>{game.games}</span>
                                    </td>
                                    <td>{scoreColor(game.winrate.toFixed(1))}%</td>
                                    <td>
                                        {kdaColor(game.kda.toFixed(2))}
                                        <p>
                                            {game.kills_per_g.toFixed(1)} /
                                            <i className="death">{game.deaths_per_g.toFixed(1)}</i> /
                                            {game.assists_per_g.toFixed(1)}
                                        </p>
                                    </td>
                                    <td>{game.cs_per_m.toFixed(1)}</td>
                                    <td>{game.kill_point_per_g.toFixed(1)}%</td>
                                    <td>
                                        {game.d_score_win_per_g !== null ? (
                                            scoreColor(game.d_score_win_per_g.toFixed(1))
                                        ) : (
                                            <span>0</span>
                                        )}
                                        <i>/</i>
                                        {game.d_score_loss_per_g !== null ? (
                                            scoreColor(game.d_score_loss_per_g.toFixed(1))
                                        ) : (
                                            <span>0</span>
                                        )}
                                    </td>
                                    <td>
                                        {game.d_score_per_g !== null ? (
                                            scoreColor(game.d_score_per_g.toFixed(1))
                                        ) : (
                                            <span>0</span>
                                        )}
                                    </td>
                                </tr>
                            )
                    )
                ) : (
                    <tr>
                        <td colSpan="8" className="no_data">
                            {t('notice.noData')}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </TabPanel>
    );
};
CustomTabPanel.tabsRole = 'TabPanel';

export default Info;
