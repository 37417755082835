import React, {useCallback, useState} from 'react';
import {useSelector} from "react-redux";

const SearchChampions = ({handleChampFilter, t}) => {
    const [champName, setChampName] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [autocomplete, setAutocomplete] = useState([]);
    const [selectChamp, setSelectChamp] = useState(0);
    const currentLang = useSelector((state) => state.translation.lang);
    const champions = useSelector((state) => state.imageStore.champions);


    const handleChampName = useCallback((e) => {
        const {value} = e.target;
        setChampName(value);

        if (value.length !== 0) {
            if (champions.length > 0) {
                const regExp = new RegExp(`${value}`);
                const filtered = champions.filter((data, index) => {
                    return regExp.test(data.champion_name_kr) || regExp.test(data.champion_name_en);
                });

                if (filtered.length > 0) {
                    setAutocomplete(filtered);
                    setIsOpen(true);
                } else {
                    setIsOpen(false);
                }

            }
        } else {
            setAutocomplete([]);
            setIsOpen(false);
        }


    }, [champions]);


    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleChampSearch();
    };


    const handleChampSelect = (item) => {
        currentLang === 'KOR'
            ? setChampName(item.champion_name_kr)
            : setChampName(item.champion_name_en);
        setSelectChamp(item.championId);
        setIsOpen(false);
    };

    const handleChampSearch = () => {
        setIsOpen(false);
        if (selectChamp === 0) {
            if (autocomplete.length !== 0) {
                handleChampFilter(autocomplete[0].championId);
            } else {
                setChampName('');
                handleChampFilter(0);
            }
        } else {
            handleChampFilter(selectChamp);
        }
    };


    return (
        <>
            <div className="champ__search">
                <input
                    type="text"
                    value={champName}
                    onChange={handleChampName}
                    onKeyPress={handleKeyPress}
                    placeholder={t('summoner.searchChampion')}
                />
                <button type="button" onClick={handleChampSearch}>
                    {t('summoner.search')}
                </button>
                {isOpen && (
                    <ul className="champ__autocomplete">
                        {autocomplete.map((item) => (
                            <li
                                key={item.championId}
                                className="champ__autocomplete-item"
                                onClick={() => handleChampSelect(item)}

                            >
                                {currentLang === 'KOR' ? item.champion_name_kr : item.champion_name_en}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

        </>
    );
};

export default SearchChampions;