import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import socketio from 'socket.io-client';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {ImageChamp, ImageLane, Loading} from '../../components';
import {cutLaneName, getShortTime, kdaColor, scoreColor, tierText} from '../../function';
import styled from "styled-components";
import SummonerDict from "../common/SummonerDict";
import SummonerTags from "../summoner/SummonerTags";
import MultiItemScore from "./MultiItem/MultiItemScore";
import InfluInfo from "../summoner/Profile/InfluInfo";


const PrevSeasonTag = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #90d489;
  border: 1px solid #779f73;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 700;
  padding: 1px 3px;
`

const TagWrapper = styled.div`
  position: absolute;
  left: 16px;
  margin: 6px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const DictWrapper = styled.div`
  min-width: 66px;
  white-space: nowrap;
`;

const BlankDiv = styled.div`
  min-width: 66px;
  height: 30px;
`;

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InfluWrapper = styled.div`
    width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MultiItem = ({
                       multiItem,
                       setNewItem,
                       duoList,
                       openId,
                       handleLayer,
                       ranks,
                       renderType,
                       typeButton,
                       setTypeButton
                   }) => {
    const {t} = useTranslation();
    const region = useSelector((state) => state.region.result);
    const currentLang = useSelector((state) => state.translation.lang);
    const [state, setState] = useState(multiItem);
    const [updated, setUpdated] = useState(100);

    // 소환사 하나씩 데이터 받아오기
    const getSummonerData = useCallback(() => {
        let name = multiItem.summoner_info.summoner_name;
        // 공백제거
        if (name.indexOf(' ') !== -1) {
            const result = name.replace(' ', '%20');
            name = result;
        }

        axios
            .get(
                `${process.env.REACT_APP_API}get_multisearch?summonerName=${encodeURI(
                    name
                )}&season=13&region=${region}`
            )
            .then((res) => {
                const result = res.data.result.a;

                if (result === undefined) return;
                setState(result);
                setNewItem(result);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [multiItem.summoner_info.summoner_name, region, setNewItem]);

    // 소환사 업데이트
    const getSummonerUpdate = useCallback(() => {
        // 소켓 도메인/네임스페이스
        const namespace = `${process.env.REACT_APP_API}update_match_multi`;
        // 소켓 연결
        const socket = socketio.connect(
            namespace,
            {
                transports: ['websocket'],
                upgrade: false,
                secure: true,
            },
            {forceNew: true}
        );
        // 업데이트 연결 시 User Connected emit
        socket.on('connect', () => {
            socket.emit('my event multi', {
                data: 'User Connected',
            });
            socket.emit('my event multi', {
                summoner_id: multiItem.summoner_info.summoner_id,
                region: region,
                season: 13,
                count: 0,
            });
        });
        // 서버에서 데이터 받는 경우
        socket.on('my response multi', (msg) => {
            // 카운트
            if (msg.finish_num !== undefined) {
                let per = Math.floor((msg.finish_num / msg.match_len) * 100);
                setUpdated(per);
            }
            // finish가 100이면(서버에서 종료 요청) 소켓 disconnect
            if (msg.finish === 100) {
                socket.disconnect();
                getSummonerData();
                setUpdated(100);
            }
            // 받은 데이터에 summoner_id 값이 있으면, 다시 서버에 진행정도 확인 요청
            if (msg.hasOwnProperty('summoner_id')) {
                socket.emit('my event multi', {
                    summoner_id: msg.summoner_id,
                    summoner_key: msg.summoner_key,
                    region: msg.region,
                    match_len: msg.match_len,
                    finish_check: 100,
                    count: msg.count,
                });
            }
        });
    }, [getSummonerData, multiItem.summoner_info.summoner_id, region]);

    useEffect(() => {
        getSummonerUpdate();
    }, [getSummonerUpdate]);

    return (
        <>
            <div className={`row ${updated === 100 ? 'complete' : 'incomplete'}`}>
                {updated !== 100 && <Loading/>}
                <TagWrapper>
                    <DictWrapper>
                        <SummonerDict
                            summoner={multiItem}
                            dict={multiItem?.summoner_info?.season_tier_dict}
                        />
                    </DictWrapper>
                    <SummonerTags
                        ranks={ranks}
                        data={state}
                    />
                </TagWrapper>

                <div className="col flex_column1">
                    <BlankDiv/>
                    <div style={{display: 'flex', flexDirection: 'row', width: "100%"}}>
                        <div className="icons">
                            <div>
                            </div>
                            {state.summoner_info.rank_json.RANKED_SOLO_5x5.hasOwnProperty('tier') ? (
                                <img
                                    src={`/images/Emblem_${state.summoner_info.rank_json.RANKED_SOLO_5x5.tier}.png`}
                                    className="imgEmblem"
                                    width={55}
                                    height={55}
                                    alt={"???"}
                                />
                            ) : (
                                <img src="/images/Emblem_UNRANKED.png"
                                     width={55}
                                     height={55}
                                     className="imgEmblem" alt="UNRANKED"/>
                            )}
                            <p>
                                <ImageLane lane={state.position.reco_lane[0]}/>
                                <span>{cutLaneName(state.position.reco_lane[0])}</span>
                            </p>
                        </div>
                        <div
                            className={`texts ${
                                state.continue_winloss.count > 1 ||
                                state.tags.carry_tag === 1 ||
                                state.tags.check_away_tag === 1 ||
                                state.tags.solo_kill_tag === 1 ||
                                state.tags.solo_death_tag === 1 ||
                                state.tags.roaming_kill_tag === 1 ||
                                state.tags.roaming_death_tag === 1 ||
                                state.tags.ganking_kill_tag === 1 ||
                                state.tags.ganking_death_tag === 1
                                    ? 'tag_on'
                                    : 'tag_off'
                            }`}
                        >
                            {state?.summoner_info?.pro_streamer_info?.status !== '-' &&
                            <InfluWrapper>
                                <InfluInfo
                                    maxWidthText={80}
                                    textColor={'#000'}
                                    showLinks={false}
                                    influData={state?.summoner_info?.pro_streamer_info}
                                />
                            </InfluWrapper>
                            }

                            {/*                {state.summoner_info.pro_streamer_info.team !== '-' && (*/}
                            {/*                    <span>*/}
                            {/*  {state.summoner_info.pro_streamer_info.team} {state.summoner_info.pro_streamer_info.name}*/}
                            {/*</span>*/}
                            {/*                )}*/}
                            <Link
                                to={{
                                    pathname: `/summoner/${state.summoner_info.summoner_name}/${region}`,

                                }}
                                target="_blank"
                                className="common_search"
                            >

                                {state.summoner_info.summoner_name}
                            </Link>
                            <dl className="t1">
                                <dt>
                                    {tierText(
                                        state.summoner_info.rank_json.RANKED_SOLO_5x5.tier,
                                        state.summoner_info.rank_json.RANKED_SOLO_5x5.division
                                    )}
                                    /
                                </dt>
                                <dd style={{flexDirection: 'row'}}>{state.summoner_info.rank_json.RANKED_SOLO_5x5.leaguePoints} LP</dd>
                            </dl>
                            <dl className="t2">
                                {Object.values(state.summoner_info.rank_json.RANKED_SOLO_5x5).length !== 0 ? (
                                    <dt>
                <span>
                  {state.summoner_info.rank_json.RANKED_SOLO_5x5.wins !== 0
                      ? (
                          (state.summoner_info.rank_json.RANKED_SOLO_5x5.wins /
                              (state.summoner_info.rank_json.RANKED_SOLO_5x5.wins +
                                  state.summoner_info.rank_json.RANKED_SOLO_5x5.losses)) *
                          100
                      ).toFixed(1)
                      : 0}
                </span>
                                        %
                                    </dt>
                                ) : (
                                    <dt>
                                        <span>-</span>%
                                    </dt>
                                )}
                                /
                                {Object.values(state.summoner_info.rank_json.RANKED_SOLO_5x5).length !== 0 ? (
                                    <dd>
                <span>
                  {state.summoner_info.rank_json.RANKED_SOLO_5x5.wins +
                  state.summoner_info.rank_json.RANKED_SOLO_5x5.losses}
                </span>
                                        {t('summoner.games')}
                                    </dd>
                                ) : (
                                    <dd>
                                        <span>-</span>
                                        {t('summoner.games')}
                                    </dd>
                                )}
                            </dl>
                            {state.summoner_info.rank_json.RANKED_SOLO_5x5.promos && (
                                <div className="promos">
                                    {state.summoner_info.rank_json.RANKED_SOLO_5x5.promos.progress_list.map(
                                        (item, index) => (
                                            <span key={index} className={item}>
                    {item}
                  </span>
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col flex_column2">
                    <div className="aiscore">
                        <p>
                            {state.ai_score_win_count}
                            {t('summoner.contractionWin')}
                            {scoreColor(state.ai_score_win)}
                        </p>
                        <p>
                            {state.ai_score_loss_count}
                            {t('summoner.contractionLose')}
                            {scoreColor(state.ai_score_loss)}
                        </p>
                        <strong>{scoreColor(state.ai_score_total)}</strong>
                    </div>
                </div>
                <div className="col flex_column3">
                    {state.summoner_match_list.length !== 0 ? (
                        <ul className="match_list">
                            {state.summoner_match_list.map((item, index) => (
                                <li key={item.timestamp} className="match_item">
                                    <div className={`box ${item.win ? 'win' : 'lose'}`}>
                                        <div className="champ">
                                            <ImageChamp champId={item.championId}/>
                                            <ImageLane lane={item.lane} color="white"/>
                                        </div>
                                        <ScoreWrapper>
                                            <MultiItemScore
                                                setTypeButton={setTypeButton}
                                                typeButton={typeButton}
                                                target={renderType}
                                                item={item}
                                                t={t}
                                            />
                                        </ScoreWrapper>
                                        {/*<div className="score">*/}
                                        {/*    {item.check_away ? scoreColor(0) : scoreColor(item.ai_score.toFixed(0))}*/}
                                        {/*</div>*/}
                                        {/*<div className="kda">*/}
                                        {/*    {item.check_away ? (*/}
                                        {/*        '-'*/}
                                        {/*    ) : item.death === 0 ? (*/}
                                        {/*        <span className="kda_color perfect">Perfect</span>*/}
                                        {/*    ) : (*/}
                                        {/*        kdaColor(item.kda.toFixed(2))*/}
                                        {/*    )}*/}
                                        {/*</div>*/}

                                        {/*<div className="rating">*/}
                                        {/*    {item.mvp === 1 && <img src="/images/ico_crown_gold_w.svg" alt="mvp"/>}*/}
                                        {/*    {item.ace === 1 && <img src="/images/ico_crown_silver_w.svg" alt="mvp"/>}*/}
                                        {/*    {item.check_away ? (*/}
                                        {/*        <span className="away">{t('tags.escape')}</span>*/}
                                        {/*    ) : item.ai_rank === 1 ? (*/}
                                        {/*        item.ai_rank + t('summoner.firstGrade')*/}
                                        {/*    ) : item.ai_rank === 2 ? (*/}
                                        {/*        item.ai_rank + t('summoner.secondGrade')*/}
                                        {/*    ) : item.ai_rank === 3 ? (*/}
                                        {/*        item.ai_rank + t('summoner.thirdGrade')*/}
                                        {/*    ) : (*/}
                                        {/*        item.ai_rank + t('summoner.grade')*/}
                                        {/*    )}*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="time">{getShortTime(item.timestamp, currentLang)}</div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="no_list">최근 게임 기록이 없습니다.</div>
                    )}
                </div>
                <div className="col flex_column4">
                    {state.summoner_match_list.length !== 0 ? (
                        <div className={`float ${openId === state.summoner_info.summoner_id ? 'open' : 'close'}`}>
                            <ul className="champ_list">
                                {state.champion_stat.map((item, index) => {
                                    return (
                                        <li key={index} className="champ_item">
                                            <div className="champ">
                                                <ImageChamp champId={Object.keys(item)[0]}/>
                                            </div>
                                            <div className="games">
                                                <span>{Object.values(item)[0].games}</span>
                                            </div>
                                            <div className="winrate">{scoreColor(Object.values(item)[0].winrate)}%</div>
                                            <div className="kda">{kdaColor(Object.values(item)[0].kda.toFixed(2))}KDA
                                            </div>
                                            <div className="score">{scoreColor(Object.values(item)[0].ai_score)}</div>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="more">
                                <button onClick={() => handleLayer(multiItem.summoner_info.summoner_id)}>
                                    <img
                                        className={openId === state.summoner_info.summoner_id ? 'open' : 'close'}
                                        src="/images/icon_arrow_b.svg"
                                        alt="열기/닫기"
                                    />
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="no_list">최근 게임 기록이 없습니다.</div>
                    )}
                </div>
            </div>
        </>
    )
        ;
};

export default MultiItem;
