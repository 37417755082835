import React, {useCallback, useEffect, useState} from 'react';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import dompurify from 'dompurify';

import MultiItem from './MultiItem';
import {MultiListContainer} from '../../styled/MultiStyle';
import MultiListType from "./MultiList/MultiListType";

const MultiList = ({multiList, getAvg}) => {
    const {t} = useTranslation();
    const sanitizer = dompurify.sanitize;
    const [newItem, setNewItem] = useState({});
    const [list, setList] = useState([]);
    const [duoList, setDuoList] = useState([]);
    const [openId, setOpenId] = useState(0);
    const [renderType,setRenderType]= useState(0);
    const [typeButtonPressed,onChangeTypeButtonPressed] = useState(false);

    const mainList = _.uniqBy(multiList, (e) => e.summoner_info.summoner_id);

    const handleLayer = (id) => {
        const currentId = openId;
        if (currentId === id) {
            setOpenId(0);
        } else {
            setOpenId(id);
        }
    };

    const getProcessData = useCallback(
        (arr) => {
            let newArr = [];
            arr.forEach((item, index) => {
                if (index !== 0) {
                    newArr.push(item);
                }
            });

            let total = 0;
            let tmp = [];
            newArr.forEach((item, index) => {
                total += item.ai_score_total;
                let aa = 0;
                let bb = '';
                item.summoner_match_list.forEach((itm, idx) => {
                    aa = itm.matchId;
                    bb = itm.side;
                    newArr.forEach((ii, xx) => {
                        if (index !== xx) {
                            ii.summoner_match_list.forEach((i, x) => {
                                if (aa === i.matchId && bb === i.side) {
                                    tmp.push(ii.summoner_info.summoner_name);
                                }
                            });
                        }
                    });
                });
            });

            let set = new Set(tmp);
            setDuoList([...set]);

            getAvg((total / newArr.length).toFixed(1));
        },
        [getAvg]
    );

    useEffect(() => {
        setList((list) => {
            return [...list, newItem];
        });
    }, [newItem]);

    useEffect(() => {
        getProcessData(list);
    }, [getProcessData, list]);


    const onClickTypeButton = (index) => {
        setRenderType(index);
        onChangeTypeButtonPressed(true);
    }


    return (
        <MultiListContainer>
            <div className="t_head">
                <div className="row">
                    <div className="col flex_column1">{t('multi.head1')}</div>
                    <div className="col flex_column2">{t('multi.head2')}</div>
                    <div className="col flex_column3">{t('multi.head3')}
                        <span>{t('multi.head3Extra')}</span>
                        <MultiListType
                            renderType={renderType}
                            onClickButton={onClickTypeButton}
                        />
                    </div>
                    <div className="col flex_column4">{t('multi.head4')}</div>
                </div>
            </div>
            <div className="t_body">
                {mainList.length > 0 && mainList.map((multiItem, index) => {
                    const ranks = mainList.reduce((sum, data, redueIndex) => {
                        if (index !== redueIndex) {
                            if (data.ai_score_total === multiItem.ai_score_total || data.ai_score_total > multiItem.ai_score_total) {
                                sum++;
                            }
                        }
                        return sum;
                    }, 1);

                    return (
                        <MultiItem
                            setTypeButton={onChangeTypeButtonPressed}
                            typeButton={typeButtonPressed}
                            renderType={renderType}
                            ranks={ranks}
                            key={index}
                            multiItem={multiItem}
                            setNewItem={setNewItem}
                            duoList={duoList}
                            openId={openId}
                            handleLayer={handleLayer}
                        />
                    )
                })}
            </div>
        </MultiListContainer>
    );
};

export default MultiList;
