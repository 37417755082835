import React, {useEffect, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {useTranslation} from 'react-i18next';

import DetailTab1 from './DetailTab1';
import DetailTab2 from './DetailTab2';
import {MatchDetailContainer} from '../../styled/SummonerStyle';

const MatchDetail = ({match, myMatch,openIndex}) => {
    const {t} = useTranslation();



    return (
        <MatchDetailContainer>
            <Tabs
                defaultIndex={openIndex}
            >
                <TabList>
                    <Tab>{t('tabs.aiAnalysis')}</Tab>
                    <Tab>{t('tabs.aiGraph')}</Tab>
                </TabList>
                <TabPanel>
                    <DetailTab1 match={match} myMatch={myMatch}/>
                </TabPanel>
                <TabPanel>
                    <DetailTab2 match={match} myMatch={myMatch}/>
                </TabPanel>
            </Tabs>
        </MatchDetailContainer>
    );
};

export default React.memo(MatchDetail);
