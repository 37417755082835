import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useTranslation} from 'react-i18next';

import {ImageMonster} from '../../components';
import ChartModal from './ChartModal';

const ChartAnalysis = ({inferenceData, myMatch, info, originArr, inModal, inModalTime,handleModalFunction = () => {}}) => {
    const {t} = useTranslation();
    const [blueMonsters, setBlueMonsters] = useState([]);
    const [redMonsters, setRedMonsters] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalIndex = (arg, flag) => {
        if (flag === 'prev') {
            setModalData((mData) => ({...mData, modalIndex: arg - 1}));
        } else if (flag === 'next') {
            setModalData((mData) => ({...mData, modalIndex: arg + 1}));
        }
    };

    // 포지션 찾기
    let side = info.side === 'blue' ? 'B_' : 'R_';
    let lane = '';
    switch (info.lane) {
        case 'Top':
            lane = 'Top';
            break;
        case 'Jungle':
            lane = 'Jun';
            break;
        case 'Middle':
            lane = 'Mid';
            break;
        case 'Bot':
            lane = 'Bot';
            break;
        case 'Supporter':
            lane = 'Sup';
            break;
        default:
            break;
    }
    const position = side + lane;
    const xAxisArr = inferenceData.team_lane_time_dict[position];
    const scoreArr = inferenceData.team_lane_score_dict[position];
    const eventArr = inferenceData.team_lane_index_dict[position];
    const detailEvent = inferenceData.detail_event;
    const concatEvent = inferenceData.concat_event;

    let xAxisTeamArr = '';
    let teamArr = '';
    if (inferenceData.team_lane_total_time_dict !== undefined) {
        xAxisTeamArr = inferenceData.team_lane_total_time_dict[position];
        teamArr = inferenceData.team_lane_total_score_dict[position];
    } else {
        xAxisTeamArr = inferenceData.team_lane_time_dict[position];
        teamArr = inferenceData.team_lane_concat_event_team_score_dict[position];
        teamArr[0] = 50;
    }

    // x,y 값
    let maxTime = detailEvent[Object.keys(detailEvent).length - 1]
        ? Math.floor(detailEvent[Object.keys(detailEvent).length - 1].time) + 5
        : 15;
    let timeArr = []; // x좌표
    let dataObj = []; // y좌표 개인 데이터
    var teamObj = []; // y좌표 팀평균 데이터

    for (let i = 0; i < maxTime; i++) {
        i % 5 === 0 && timeArr.push(i);
    }

    xAxisArr.forEach((item, index) => {
        // 개인 데이터
        dataObj.push({
            x: xAxisArr[index],
            y: scoreArr[index].y ? scoreArr[index].y : scoreArr[index],
        });
    });
    xAxisTeamArr.forEach((item, index) => {
        // 팀평균 데이터
        teamObj.push({
            x: xAxisTeamArr[index],
            y: teamArr[index].y ? teamArr[index].y : teamArr[index],
        });
    });

    // ai-score 최소, 최대값 구하기
    let max = 50;
    let min = 50;
    let cValue = 0;
    for (let i = 0; i < scoreArr.length; i++) {
        if (typeof scoreArr[i] == 'object') {
            if (scoreArr[i].y >= max) {
                max = scoreArr[i].y;
            }
            if (scoreArr[i].y <= min) {
                min = scoreArr[i].y;
            }
        } else {
            if (scoreArr[i] >= max) {
                max = scoreArr[i];
            }
            if (scoreArr[i] <= min) {
                min = scoreArr[i];
            }
        }
    }
    if (max - 50 >= 50 - min) {
        max = max + 5;
        if (max >= 100) {
            max = 100;
        }
        min = 100 - max;
    } else {
        min = min - 5;
        if (min <= 0) {
            min = 0;
        }
        max = 100 - min;
    }
    max = Math.floor(max);
    if (max % 5 !== 0) {
        max = max + (5 - (max % 5));
    }
    min = Math.round(min);
    min = min - (min % 5);
    cValue = (max - min) / 2 + min;

    const options = {
        chart: {
            type: 'line',
            plotBackgroundColor: 'rgba(52, 49, 62, 0.08)',
            plotBorderWidth: 1,
            plotBorderColor: '#A5BED2',
            backgroundColor: 'none',
            height: inModal ? 300 : 377,
            marginTop: 20,
            marginRight: 30,
            events: {
                load: function () {
                    const updateObj = {
                        marker: {
                            radius: 12,
                            width: 12,
                            fillColor: null,
                            lineWidth: 0,
                        },
                        dataLabels: {
                            enabled: true,
                        },
                    };
                    const point = this.series[0].points;

                    let scoreArr = [];
                    point.forEach((p) => {
                        scoreArr.push(p.y);
                    });
                    point.forEach((p, idx) => {
                        const thisValue = p.y; //현재점수
                        const lastValue = p.series.yData[p.series.yData.length - 1]; //최종점수
                        const maxValue = Math.max.apply(null, scoreArr); // 큰점수
                        const minValue = Math.min.apply(null, scoreArr); // 작은점수

                        if (thisValue === maxValue && maxValue !== 50) {
                            p.update(updateObj);
                        }
                        if (thisValue === minValue && minValue !== 50) {
                            p.update(updateObj);
                        }
                        if (thisValue === lastValue) {
                            p.update(updateObj);
                        }

                        // 오브젝트 관여시 state 변경
                        const detailIndexSet = eventArr[idx];
                        const lastTime = p.series.xData[p.series.xData.length - 1];
                        if (detailIndexSet.length !== 0) {
                            for (var i = 0; i < detailIndexSet.length; i++) {
                                if (detailEvent[detailIndexSet[i]].event.type === 'Monster') {
                                    let target = detailEvent[detailIndexSet[i]].event.target;
                                    if (detailEvent[detailIndexSet[i]].event.side === 'B') {
                                        setBlueMonsters((monsters) => [
                                            ...monsters,
                                            {name: target, x: p.x, gameTime: lastTime},
                                        ]);
                                    } else if (detailEvent[detailIndexSet[i]].event.side === 'R') {
                                        setRedMonsters((monsters) => [
                                            ...monsters,
                                            {name: target, x: p.x, gameTime: lastTime},
                                        ]);
                                    }
                                }
                            }
                        }
                    });
                },
            },
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        legend: {},
        xAxis: {
            title: {
                enabled: false,
            },
            labels: {
                enabled: true,
                style: {
                    fontFamily: 'Noto Sans KR',
                    fontSize: '11px',
                    color: '#313131',
                },
                useHTML: true,
                formatter: function () {
                    return `<span>${this.value}${t('summoner.minute')}</span>`;
                },
            },
            crosshair: {
                color: '#313131',
            },
            tickWidth: 0,
            lineColor: '#8388A5',
            lineWidth: 2,
            gridLineWidth: 1,
            gridLineColor: 'rgba(49, 49, 49, 0.1)',
            // tickmarkPlacement: "on",
            tickPositions: timeArr, // 보여지는 x축 값
            plotLines: [
                // {
                //   color: 'rgba(49, 49, 49, 0.2)',
                //   width: 1,
                //   value: 15,
                //   dashStyle: 'dash',
                // },
                {
                    color: 'rgba(13, 255, 126, 0.3)',
                    width: 13,
                    value: inModalTime,
                    label: {
                        useHTML: true,
                        textAlign: 'center',
                        verticalAlign: 'bottom',
                        x: -8,
                        y: -1,
                        formatter: function () {
                            return `<span class="plot_lines"></span>`;
                        },
                    },
                },
            ],
        },
        yAxis: [
            {
                title: {
                    enabled: false,
                },
                opposite: false,
                plotLines: [
                    {
                        color: 'rgba(248, 156, 50, 0.6)',
                        width: 2,
                        value: 75,
                    },
                    {
                        color: 'rgba(83, 149, 120, 0.6)',
                        width: 2,
                        value: 50,
                    },
                    {
                        color: 'rgba(199, 68, 68, 0.6)',
                        width: 2,
                        value: 25,
                    },
                ],
                tickInterval: cValue,
                tickPositions: [min, 25, 50, 75, max],
                gridLineWidth: 1,
                lineColor: '#8388A5',
                lineWidth: 2,
            },
            {
                title: {
                    enabled: false,
                },
                labels: {
                    enabled: false,
                },
                opposite: true,
                tickInterval: cValue,
                tickPositions: [min, 25, 50, 75, max],
                plotBands: [
                    {
                        id: 'verybad',
                        from: 0,
                        to: 25,
                        color: 'rgba(255,255,255,0.1)',
                        label: {
                            useHTML: true,
                            text: t('summoner.aiscore.bad'),
                            align: 'right',
                            rotation: 90,
                            x: 15,
                            style: {
                                fontFamily: 'Noto Sans KR',
                                fontSize: '12px',
                                color: '#313131',
                            },
                        },
                    },
                    {
                        id: 'bad',
                        from: 25,
                        to: 50,
                        color: 'rgba(255,255,255,0.1)',
                        label: {
                            useHTML: true,
                            text: t('summoner.aiscore.fair'),
                            align: 'right',
                            rotation: 90,
                            x: 15,
                            style: {
                                fontFamily: 'Noto Sans KR',
                                fontSize: '12px',
                                color: '#313131',
                            },
                        },
                    },
                    {
                        id: 'good',
                        from: 50,
                        to: 75,
                        color: 'rgba(255,255,255,0.1)',
                        label: {
                            useHTML: true,
                            text: t('summoner.aiscore.good'),
                            align: 'right',
                            rotation: 90,
                            x: 15,
                            style: {
                                fontFamily: 'Noto Sans KR',
                                fontSize: '12px',
                                color: '#313131',
                            },
                        },
                    },
                    {
                        id: 'verygood',
                        from: 75,
                        to: 100,
                        color: 'rgba(255,255,255,0.1)',
                        label: {
                            useHTML: true,
                            text: t('summoner.aiscore.carry'),
                            align: 'right',
                            rotation: 90,
                            x: 15,
                            style: {
                                fontFamily: 'Noto Sans KR',
                                fontSize: '12px',
                                color: '#313131',
                            },
                        },
                    },
                ],
            },
        ],
        plotOptions: {
            series: {
                animation: true,
                showInLegend: false,
                lineWidth: 4,
                marker: {
                    enabled: true,
                    radius: 6,
                    width: 6,
                    fillColor: null,
                    lineWidth: 2,
                    lineColor: '#ffffff',
                    enabledThreshold: 0,
                },
                dataLabels: {
                    enabled: false,
                    useHTML: true,
                    formatter: function () {

                        return `<span class="marker_score ${this.y.toFixed(0) > 99 && 'man'}">${this.y.toFixed(
                            0
                        )}</span>`;
                    },
                },
                states: {
                    inactive: {
                        enabled: false,
                    },
                },
                shadow: true,
            },
        },
        series: [
            {
                type: 'line',
                name: 'detail chart',
                data: dataObj,
                zones: [
                    {
                        value: 24,
                        color: '#69788d',
                    },
                    {
                        value: 49,
                        color: '#de5353',
                    },
                    {
                        value: 75,
                        color: '#259a7e',
                    },
                    {
                        color: '#f19000',
                    },
                ],
                shadow: true,
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (el) {
                            const modalIndex = el.point.index;
                            if(modalIndex !== 0) {
                                handleModalFunction({modalIndex, eventArr, detailEvent, concatEvent});
                                setModalData({modalIndex, eventArr, detailEvent, concatEvent});
                            }
                            if (modalIndex !== 0 && !inModal) {
                                setModalOpen(true);
                            }
                        },
                    },
                },
                zIndex: 10,
            },
            // 팀 평균 그래프
            {
                type: 'line',
                name: 'detail team chart',
                data: teamObj,
                shadow: false,
                animation: false,
                showInLegend: false,
                lineWidth: 2,
                lineColor: '#A878D8',
                dashStyle: 'Dash',
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                zIndex: 5,
            },
        ],
        tooltip: {
            enabled: true,
            useHTML: true,
            animation: false,
            backgroundColor: null,
            borderWidth: 0,
            formatter: function () {
                let result = '';
                if (this.series.name === 'detail chart') {
                    let thisValue = this.y;
                    let prevValue = this.series.yData[this.point.index - 1];
                    let diffValue = 0;
                    if (prevValue) {
                        if (thisValue < prevValue) {
                            diffValue = prevValue - thisValue;
                            if (this.y >= 50) {
                                result = `<div class="chart_dot good">
                <span class="score">${thisValue.toFixed(1)}</span>
                <span class="diff">(<i class="negative">${diffValue.toFixed(1)}</i>)</span>
              </div>`;
                            } else {
                                result = `<div class="chart_dot bad">
                <span class="score">${thisValue.toFixed(1)}</span>
                <span class="diff">(<i class="negative">${diffValue.toFixed(1)}</i>)</span>
              </div>`;
                            }
                        } else {
                            diffValue = thisValue - prevValue;
                            if (this.y >= 50) {
                                result = `<div class="chart_dot good">
                <span class="score">${thisValue.toFixed(1)}</span>
                <span class="diff">(<i class="positive">${diffValue.toFixed(1)}</i>)</span>
              </div>`;
                            } else {
                                result = `<div class="chart_dot bad">
                <span class="score">${thisValue.toFixed(1)}</span>
                <span class="diff">(<i class="positive">${diffValue.toFixed(1)}</i>)</span>
              </div>`;
                            }
                        }
                    } else {
                        if (this.y >= 50) {
                            result = `<div class="chart_dot good one_line">
              <span class="score">${thisValue.toFixed(1)}</span>
            </div>`;
                        } else {
                            result = `<div class="chart_dot bad one_line">
              <span class="score">${thisValue.toFixed(1)}</span>
            </div>`;
                        }
                    }
                }
                return result;
            },
        },
    };

    useEffect(() => {
        if (modalOpen) {
            document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;
      `;
            document.body.getElementsByClassName('h')[0].style.cssText = `
        z-index: 99;
      `;
            return () => {
                const scrollY = document.body.style.top;
                document.body.style.cssText = '';
                document.body.getElementsByClassName('h')[0].style.cssText = '';
                window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
            };
        }
    }, [modalOpen]);

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={options}/>
            <div className="timeline">
                <div className={`team ${originArr[0].win ? 'win' : 'lose'}`}>
                    <div className="cade">
                        <div className="deca">
                            {blueMonsters.length !== 0 &&
                            blueMonsters.map((monster, index) => (
                                <span
                                    key={index}
                                    className="monster"
                                    style={{left: `${(monster.x / monster.gameTime) * 100}%`}}
                                >
                    <ImageMonster obj={monster.name}/>
                  </span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={`team ${originArr[5].win ? 'win' : 'lose'}`}>
                    <div className="cade">
                        <div className="deca">
                            {redMonsters.length !== 0 &&
                            redMonsters.map((monster, index) => (
                                <span
                                    key={index}
                                    className="monster"
                                    style={{left: `${(monster.x / monster.gameTime) * 100}%`}}
                                >
                    <ImageMonster obj={monster.name}/>
                  </span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {!inModal &&
                <div style={{position:"absolute", top:'0.55rem',left:'3rem'}}>
                    <span style={{fontSize:14, fontWeight:'bold'}}>* {t('tabs.aiGraphExplain')}</span>
                </div>
            }
            <div className="legend">
                {t('summoner.team')} {t('summoner.avg')} <i></i>
            </div>
            {modalOpen && (
                <ChartModal
                    modalData={modalData}
                    modalOpen={modalOpen}
                    handleModalClose={handleModalClose}
                    handleModalIndex={handleModalIndex}
                    info={info}
                    originArr={originArr}
                    myMatch={myMatch}
                    inferenceData={inferenceData}
                />
            )}
        </>
    );
};

export default ChartAnalysis;
