import React, {useCallback, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import {Home, Multi, Summoner, ToMobile} from './components';
import NotFound from './components/NotFound';
import GlobalStyles from './styled/GlobalStyles';
import i18nInit from "./i18n";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {changeVersion} from "./store/modules/gameVersion";
import axios from "axios";
import {imageStore} from "./store/modules/imageStore";


// 스토어생성

i18nInit(window.navigator.language === "ko" || window.navigator.language === "ko-KR" ? 'ko' : "en-US");

const App = () => {
    const dispatch = useDispatch();
    const currentLang = useSelector((state) => state.translation.lang);
    const {t, i18n} = useTranslation();


    const handleDescription = () => {
        const description = document.querySelector('meta[name=description]');
        const ogDescription = document.querySelector('meta[property="og:description"]');
        const snsDescription = document.querySelector('meta[property="twitter:description"]');
        const enDescription = 'DEEPLOL provides new strategies such as AI-score and Tier Prediction with AI technology. Check out AI-score, like Pro LoL teams.';
        const korDescription = '롤전적 검색 딥롤은 인공지능(AI) 기술로 AI Score, 티어 예측과 같은 새로운 게임 데이터를 제공합니다. 롤 프로팀도 사용하는 AI-score를 지금 확인해보세요!';
        const thisDes = currentLang === 'ko' ? korDescription : enDescription;
        description.setAttribute('content',thisDes);
        ogDescription.setAttribute('content',thisDes);
        snsDescription.setAttribute('content',thisDes);
    }

    const changeKeywords = () => {
        const keywords = document.querySelector('meta[name=keywords]');
        keywords.setAttribute('content', t('header.keywords'));
    };

    const getGameVersion = useCallback(async () => {
        const res = await axios.get('https://ddragon.leagueoflegends.com/api/versions.json');
        dispatch(changeVersion(res.data[0]));
        return res.data[0]
        // 이미지 정보 함수호출
    }, []);

    // 챔피언, 룬, 스펠, 아이템 스토어 등록 (이미지용)
    const getImageInfo = useCallback(async () => {


            const gameVersion = await getGameVersion();
            let _thisGameVersionArr = gameVersion.split('.');
            let _thisGameVersion = _thisGameVersionArr[0] + '.' + _thisGameVersionArr[1];

            const resChampions = await axios.get(`${process.env.REACT_APP_CHAMP_INFO}`);
            const resRunes = await axios.get(
                `${process.env.REACT_APP_RUNE}?version=${_thisGameVersion}&lang=${
                    currentLang === 'KOR' ? 'ko_KR' : 'en_US'
                }`
            );
            const resSpells = await axios.get(
                `${process.env.REACT_APP_SPELL}?version=${_thisGameVersion}&lang=${
                    currentLang === 'KOR' ? 'ko_KR' : 'en_US'
                }`
            );
            const resItems = await axios.get(
                `${process.env.REACT_APP_ITEM}?version=${_thisGameVersion}&lang=${
                    currentLang === 'KOR' ? 'ko_KR' : 'en_US'
                }`
            );

            const tooltipReady = true;

            dispatch(
                imageStore(
                    resChampions.data.champions,
                    resRunes.data,
                    resSpells.data.data,
                    resItems.data.data,
                    tooltipReady
                )
            );
        },
        [currentLang]);

    useEffect(() => {
        if(!isMobile) {
            getImageInfo();
            handleDescription();
        }
    }, [getImageInfo]);


    i18next.on("languageChanged", changeKeywords);


    return (
        <>
            <GlobalStyles/>
            <Router>
                <Switch>
                    {isMobile && <Route path="*" component={ToMobile}></Route>}
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route exact path="/summoner">
                        <Home/>
                    </Route>
                    <Route exact path="/summoner/:userName/:region">
                        <Summoner/>
                    </Route>
                    <Route exact path="/summoner/:userName">
                        <Summoner/>
                    </Route>
                    <Route path="/multi/:name/:region">
                        <Multi/>
                    </Route>
                    <Route path="/multi/:name">
                        <Multi/>
                    </Route>
                    <Route path="/multi">
                        <Multi/>
                    </Route>
                    <Route>
                        <NotFound/>
                    </Route>
                </Switch>
            </Router>
        </>
    );

};

export default App;
