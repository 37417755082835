import React, {useCallback, useEffect, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

import {translation} from '../../store/modules/translation';
import Search from './Search';
import {HeaderContainer} from '../../styled/CommonStyle';
import {noticeChange} from "../../store/modules/notice";

const Header = ({initLoadingNotFound}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [view, setView] = useState(false);
    const region = useSelector((state) => state.region.result);
    const [openLang, setOpenLang] = useState(false);
    const notice = useSelector((state) => state.notice.notice);
    const currentLang = useSelector((state) => state.translation.lang);
    const [temp, setTemp] = useState(false);


    useEffect(() => {
        setTemp(false);
        const fetchData = () => {
            axios.get(`${process.env.REACT_APP_API}riot_check?region=${region}`).then((res) => {
                if (res.data.msg !== 'server ok') {
                    setTemp(true);
                    noticeChange('alertError');
                } else {
                    setTemp(false);
                }
            });
        };
        fetchData();
    }, [region]);

    const onChangeLang = (lang) => {
        if (lang === 'KOR') {
            i18n.changeLanguage('ko-KR');
        } else if (lang === 'ENG') {
            i18n.changeLanguage('en-US');
        }
        dispatch(translation(lang));
        setOpenLang(false);
    };

    // 언어설정 토글
    const toggleLang = () => {
        setOpenLang((prev) => !prev);
    };

    // 문의하기 열기
    const handleContactOpen = () => {
        setView(true);
    };
    // 문의하기 닫기
    const handleContactClose = () => {
        setView(false);
    };

    // 언어설정 닫기
    const handleClickOutside = useCallback(
        (e) => {
            if (openLang && (!e.current || !e.current.contains(e.target))) setOpenLang(false);
        },
        [openLang]
    );

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <>
            <HeaderContainer className="h">
                {notice === 'alertError' || temp && (
                    <div className="temp">
                        <p>
                            {t('notice.alertError')}
                        </p>
                        <button onClick={() => setTemp(false)}></button>
                    </div>
                )}
                <div className="h__menu">
                    <div className="logo">
                        <h1>
                            <Link to="/">DEEPLOL</Link>
                        </h1>
                        <span className="beta">BETA</span>
                    </div>
                    <nav>
                        <NavLink to="/summoner">{t('header.navSummoner')}</NavLink>
                        <NavLink to="/multi">{t('header.navMulti')}</NavLink>
                    </nav>
                    <div className="hide">
                        <h2>{t('header.navSummoner')}</h2>
                        <h2>{t('header.navMulti')}</h2>
                    </div>
                </div>
                <Search initLoadingNotFound={initLoadingNotFound}/>
                <div className="h__util">
                    <div className="lang">
                        <button onClick={toggleLang}>{currentLang}</button>
                        {openLang && (
                            <ul>
                                <li
                                    className={`${currentLang === 'KOR' && 'selected'}`}
                                    onClick={() => onChangeLang('KOR')}
                                >
                                    KOR
                                </li>
                                <li
                                    className={`${currentLang === 'ENG' && 'selected'}`}
                                    onClick={() => onChangeLang('ENG')}
                                >
                                    ENG
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
                <div className="h__contactus">
                    {!view && (
                        <img
                            src="/images/ico_write_qa_w.svg"
                            alt="contact us open"
                            className="open"
                            onClick={handleContactOpen}
                        />
                    )}
                    {view && (
                        <img
                            src="/images/ico_search_tag_del.svg"
                            alt="contact us close"
                            className="close"
                            onClick={handleContactClose}
                        />
                    )}
                    {view && (
                        <iframe
                            title="contact-us"
                            src="https://2021.deeplol.gg/test__2.html"
                            frameBorder="0"
                            width={350}
                            height={510}
                        ></iframe>
                    )}
                </div>
            </HeaderContainer>
        </>
    );
};

export default Header;
