import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {ImageChamp, ImageLane} from '../../components';
import {getSortedObject, kdaColor, scoreColor, sortChampData} from '../../function';
import TooltipWrapper from "../common/TooltipWrapper";
import ChampArrow from "./Champ/ChampArrow";

const ChampTable = ({data, handleSort, currentOrder,openId,setOpenId,sortOrder,initialSort}) => {
    const {t} = useTranslation();
    const champNames = useSelector((state) => state.imageStore.champions);
    const currentLang = useSelector((state) => state.translation.lang);

    const [moreId, setMoreId] = useState(null);



    // 상세보기
    const handleToggleDetail = (cid) => {
        const currentId = openId;
        if (currentId === cid) {
            setOpenId(null);
            setMoreId(null);
        } else {
            setOpenId(cid);
        }
    };

    // 상세보기의 더보기
    const handleMoreDetail = (cid) => {
        const currentId = moreId;
        if (currentId === cid) {
            setMoreId(null);
        } else {
            setMoreId(cid);
        }
    };

    return (
        <>
            {data.length !== 0 ? (
                <table className="table">

                    <colgroup>
                        <col width="40px"/>
                        <col width="*"/>
                        {data[0].lane !== undefined && <col width="56px"/>}
                        <col width="70px"/>
                        <col width="190px"/>
                        <col width="95px"/>
                        <col width="56px"/>
                        <col width="56px"/>
                        <col width="56px"/>
                        <col width="70px"/>
                        <col width="83px"/>
                        <col width="66px"/>
                        <col width="70px"/>
                        <col width="36px"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th rowSpan="2">
                            <div className="t-cell">
                                <span className="name">#</span>
                            </div>
                        </th>
                        <th rowSpan="2" className={`sort ${currentOrder}`}
                            onClick={() => handleSort('champions')}>
                            <div className="t-cell">
                                <ChampArrow
                                    title={t('tabs.champions')}
                                    arrow={sortOrder.champions.sortType}
                                />
                                {/*<span className="name">{t('tabs.champions')}</span>*/}
                                {/*<span className="arrow"></span>*/}
                            </div>
                        </th>
                        {data[0].lane !== undefined && (
                            <th rowSpan="2">
                                <div className="t-cell">
                                    <span className="name">{t('tabs.position')}</span>
                                </div>
                            </th>
                        )}
                        <th
                            rowSpan="2"
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort('games')}
                        >
                            <div className="t-cell">
                                <ChampArrow
                                    title={t('tabs.games')}
                                    arrow={sortOrder.games.sortType}
                                />
                            </div>
                        </th>
                        <th
                            rowSpan="2"
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort('winrate')}
                        >
                            <div className="t-cell">
                                <ChampArrow
                                    extra={t('tabs.winrate')}
                                    title={t('tabs.winlose')}
                                    arrow={sortOrder.winrate.sortType}
                                />
                            </div>
                        </th>
                        <th rowSpan="2" className={`sort ${currentOrder}`} onClick={() => handleSort('kda')}>
                            <div className="t-cell">
                                <ChampArrow
                                    title={'KDA'}
                                    arrow={sortOrder.kda.sortType}
                                />
                            </div>
                        </th>
                        <th colSpan="3">
                            <div className="t-cell">
                                <span className="name">AI-Score</span>
                            </div>
                        </th>
                        <th
                            rowSpan="2"
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort('cs_per_m')}
                        >
                            <TooltipWrapper
                                tooltipText={t('tabs.csMExplain')}
                            >
                                <div className="t-cell">
                                    <ChampArrow
                                        title={t('tabs.csm')}
                                        arrow={sortOrder.cs_per_m.sortType}
                                    />
                                </div>
                            </TooltipWrapper>
                        </th>
                        <th
                            rowSpan="2"
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort('gold_diff_15_per_g')}
                        >
                            <TooltipWrapper
                                tooltipText={t('tabs.gd@15Explain')}
                            >
                                <div className="t-cell">
                                    <ChampArrow
                                        title={'GD@15'}
                                        arrow={sortOrder.gold_diff_15_per_g.sortType}
                                    />
                                </div>
                            </TooltipWrapper>
                        </th>
                        <th rowSpan="2" className={`sort ${currentOrder}`} onClick={() => handleSort('dpm')}>
                            <div className="t-cell">
                                <ChampArrow
                                    title={'DPM'}
                                    arrow={sortOrder.dpm.sortType}
                                />
                            </div>
                        </th>
                        <th
                            rowSpan="2"
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort('kill_point_per_g')}
                        >
                            <div className="t-cell">
                                <ChampArrow
                                    title={t('tabs.kp')}
                                    arrow={sortOrder.kill_point_per_g.sortType}
                                />
                            </div>
                        </th>
                        <th rowSpan="2">
                            <div className="t-cell">
                                <span className="name">&nbsp;</span>
                            </div>
                        </th>
                    </tr>
                    <tr className="bottom">
                        <th
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort('d_score_win_per_g')}
                        >
                            <div className="t-cell">
                                <ChampArrow
                                    title={t('champions.filter.win')}
                                    arrow={sortOrder.d_score_win_per_g.sortType}
                                />
                            </div>
                        </th>
                        <th
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort('d_score_loss_per_g')}
                        >
                            <div className="t-cell">
                                <ChampArrow
                                    title={t('champions.filter.lose')}
                                    arrow={sortOrder.d_score_loss_per_g.sortType}
                                />
                            </div>
                        </th>
                        <th className={`sort ${currentOrder}`} onClick={() => handleSort('d_score_per_g')}>
                            <div className="t-cell">
                                <ChampArrow
                                    title={t('champions.filter.avg')}
                                    arrow={sortOrder.d_score_per_g.sortType}
                                />
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((row, index) => {
                        return (
                            <React.Fragment key={index}>
                                <tr className={openId === row.cid ? 'open' : 'close'}
                                    onClick={() => handleToggleDetail(row.cid)}>
                                    <td>
                                        <span className="normal">{index + 1}</span>
                                    </td>
                                    <td>
                                        <div className="champ">
                                            <ImageChamp champId={row.championId}/>
                                            {champNames.map(
                                                (champ, idx) =>
                                                    champ.championId === row.championId && (
                                                        <p key={idx}>
                                                            {currentLang === 'KOR'
                                                                ? champ.champion_name_kr
                                                                : champ.champion_name_en}
                                                        </p>
                                                    )
                                            )}
                                        </div>
                                    </td>
                                    {data[0].lane !== undefined && (
                                        <td>
                                            <ImageLane lane={row.lane}/>
                                        </td>
                                    )}
                                    <td>
                                        <span className="bold">{row.games}</span>
                                    </td>
                                    <td>
                                        <div className="winlose">
                                            <div className="graph">
                          <span className="win" style={{width: `${row.winrate}%`}}>
                            <i>{row.wins}</i>
                          </span>
                                                <span className="lose" style={{width: `${100 - row.winrate}%`}}>
                            <i>{row.losses}</i>
                          </span>
                                            </div>
                                            <div className="winrate">
                                                <span className="bold">{scoreColor(row.winrate)}</span>%
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="kda">
                                            <span className="bold">{kdaColor(row.kda)}</span>
                                            <p>
                                                ({row.kills_per_g} / {row.deaths_per_g} / {row.assists_per_g})
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="normal">{scoreColor(row.d_score_win_per_g ?? 0)}</span>
                                    </td>
                                    <td>
                                        <span className="normal">{scoreColor(row.d_score_loss_per_g ?? 0)}</span>
                                    </td>
                                    <td>
                                        <span className="bold">{scoreColor(row.d_score_per_g ?? 0)}</span>
                                    </td>
                                    <td>
                                        <span className="normal">{row.cs_per_m}</span>
                                    </td>
                                    <td>
                                        <span className="normal">{row.gold_diff_15_per_g.toFixed(0)}</span>
                                    </td>
                                    <td>
                                        <span className="normal">{row.dpm.toFixed(0)}</span>
                                    </td>
                                    <td>
                                        <span className="normal">{row.kill_point_per_g}%</span>
                                    </td>
                                    <td>
                                        <button className="arrow" onClick={() => handleToggleDetail(row.cid)}>
                                            <img src="/images/icon_arrow_b.svg" alt={t('summoner.more')}/>
                                        </button>
                                    </td>
                                </tr>
                                {openId === row.cid && (
                                    <tr className="detail">
                                        <td colSpan="20">
                                            <div className="vs_champ">
                                                <div className={`list_wrap ${moreId === row.cid ? 'open' : 'close'}`}>
                                                    <ul className="list">
                                                        {sortChampData(row.detail, initialSort).map(
                                                            (item, idx) => {

                                                                return (
                                                                    <li key={idx}>
                                                                        <div className="td" style={{width: '40px'}}>
                                                                            <div className="vs">VS</div>
                                                                        </div>
                                                                        <div className="td" style={{width: '204px'}}>
                                                                            <div className="champ">
                                                                                <ImageChamp champId={item.enemyId}/>
                                                                                {champNames.map(
                                                                                    (champ, idx) =>
                                                                                        champ.championId === item.enemyId && (
                                                                                            <p key={idx}>
                                                                                                {currentLang === 'KOR'
                                                                                                    ? champ.champion_name_kr
                                                                                                    : champ.champion_name_en}
                                                                                            </p>
                                                                                        )
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="td" style={{width: '70px'}}>
                                                                            <span className="bold">{item.games}</span>
                                                                        </div>
                                                                        <div className="td" style={{width: '190px'}}>
                                                                            <div className="winlose">
                                                                                <div className="graph">
                                            <span
                                                className="win"
                                                style={{width: `${item.winrate}%`}}
                                            >
                                              <i>{item.wins}</i>
                                            </span>
                                                                                    <span
                                                                                        className="lose"
                                                                                        style={{width: `${100 - item.winrate}%`}}
                                                                                    >
                                              <i>{item.losses}</i>
                                            </span>
                                                                                </div>
                                                                                <div className="winrate">
                                                                                    <span
                                                                                        className="bold">{scoreColor(item.winrate)}</span>
                                                                                    %
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="td" style={{width: '95px'}}>
                                                                            <div className="kda">
                                                                                <span
                                                                                    className="bold">{kdaColor(item.kda)}</span>
                                                                                <p>
                                                                                    ({item.kills_per_g} / {item.deaths_per_g} /{' '}
                                                                                    {item.assists_per_g})
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="td" style={{width: '56px'}}>
                                        <span className="normal">
                                          {scoreColor(item.d_score_win_per_g ?? 0)}
                                        </span>
                                                                        </div>
                                                                        <div className="td" style={{width: '56px'}}>
                                        <span className="normal">
                                          {scoreColor(item.d_score_loss_per_g ?? 0)}
                                        </span>
                                                                        </div>
                                                                        <div className="td" style={{width: '56px'}}>
                                        <span className="bold">
                                          {scoreColor(item.d_score_per_g ?? 0)}
                                        </span>
                                                                        </div>
                                                                        <div className="td" style={{width: '70px'}}>
                                                                            <span
                                                                                className="normal">{item.cs_per_m}</span>
                                                                        </div>
                                                                        <div className="td" style={{width: '83px'}}>
                                        <span className="normal">
                                          {item.gold_diff_15_per_g.toFixed(0)}
                                        </span>
                                                                        </div>
                                                                        <div className="td" style={{width: '66px'}}>
                                                                            <span
                                                                                className="normal">{item.dpm.toFixed(0)}</span>
                                                                        </div>
                                                                        <div className="td" style={{width: '70px'}}>
                                                                            <span
                                                                                className="normal">{item.kill_point_per_g}%</span>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                </div>
                                                {row.detail.length > 10 ? (
                                                    <button className="list_more"
                                                            onClick={() => handleMoreDetail(row.cid)}>
                                                        {moreId === row.cid ? t('summoner.close') : t('summoner.more')}
                                                    </button>
                                                ) : null}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })}
                    </tbody>
                </table>
            ) : (
                <table className="table">
                    <tbody>
                    <tr>
                        <td className="no_data">{t('notice.noData')}</td>
                    </tr>
                    </tbody>
                </table>
            )}
        </>
    );
};

export default ChampTable;
