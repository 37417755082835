// 액션 생성
const MODAL = 'modal/MODAL';

// 액션 함수 내보내기
export const openModal = (open, modalData, match) => ({
  type: MODAL,
  open,
  modalData,
  match,
});

// 초기 상태 값
const initialState = {
  open: false,
  modalData: {},
  match: {},
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL:
      return {
        open: action.open,
        modalData: action.modalData,
        match: action.match,
      };
    default:
      return state;
  }
};
export default reducer;
