// 액션 생성
import {
    GET_CHAMP_INFO_FAILURE,
    GET_CHAMP_INFO_REQUEST,
    GET_CHAMP_INFO_SUCCESS,
    GET_MATCH_BY_QUEUE_FAILURE,
    GET_MATCH_BY_QUEUE_REQUEST,
    GET_MATCH_BY_QUEUE_SUCCESS,
    GET_MORE_MATCHES_FAILURE,
    GET_MORE_MATCHES_REQUEST,
    GET_MORE_MATCHES_SUCCESS,
    GET_SUMMONER_FAILURE,
    GET_SUMMONER_INFO_FAILURE,
    GET_SUMMONER_INFO_REQUEST,
    GET_SUMMONER_INFO_SUCCESS,
    GET_SUMMONER_REQUEST,
    GET_SUMMONER_SUCCESS,
    UPDATE_SUMMONER_INFO_FAILURE,
    UPDATE_SUMMONER_INFO_REQUEST,
    UPDATE_SUMMONER_INFO_SUCCESS,
} from "../reducer/summoner";

const SUMMONER = 'summoner/SUMMONER';

// 액션 함수 내보내기
export const changeSummoner = (info) => ({type: SUMMONER, info});

// 초기 상태 값
const initialState = {
    info: {},
    summoner: {},
    summonerStatus: {},
    ingameStatus: false,
    updateStatus: {},
    tierChart: {},
    match: [],
    withOrigin: {},
    withOutOrigin: {},


    getSummonerLoading: false,
    getSummonerDone: false,
    getSummonerError: null,

    getSummonerInfoLoading: false,
    getSummonerInfoDone: false,
    getSummonerInfoError: null,

    getMoreMatchesLoading: false,
    getMoreMatchesDone: false,
    getMoreMatchesError: null,

    getUpdateStatusLoading: false,
    getUpdateStatusDone: false,
    getUpdateStatusError: null,

    getChampInfoLoading: false,
    getChampInfoDone: false,
    getChampInfoError: null,

    getMatchByQueueLoading:false,
    getMatchByQueueDone: false,
    getMatchByQueueError: null,

    updateSummonerInfoLoading: false,
    updateSummonerInfoDone: false,
    updateSummonerInfoError: null,
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MATCH_BY_QUEUE_REQUEST: {
            return {
                ...state,
                getMatchByQueueLoading:true,
                getMatchByQueueDone: false,
                getMatchByQueueError: null,
            }
        }
        case GET_MATCH_BY_QUEUE_SUCCESS: {

            return {
                ...state,
                match: [...action.data.match],
                getMatchByQueueLoading:false,
                getMatchByQueueDone: true,
            }
        }
        case GET_MATCH_BY_QUEUE_FAILURE: {
            return {
                ...state,
                getMatchByQueueLoading:false,
                getMatchByQueueError: action.error,
            }
        }
        case UPDATE_SUMMONER_INFO_REQUEST: {
            return {
                ...state,
                updateSummonerInfoLoading: true,
                updateSummonerInfoDone: false,
                updateSummonerInfoError: null,
            }
        }
        case UPDATE_SUMMONER_INFO_SUCCESS: {

            return {
                ...state,
                updateStatus: {...action.data.updateStatus},
                updateSummonerInfoLoading: false,
                updateSummonerInfoDone: true,
            }
        }
        case UPDATE_SUMMONER_INFO_FAILURE: {
            return {
                ...state,
                updateSummonerInfoLoading: false,
                updateSummonerInfoError: action.error,
            }
        }
        case GET_SUMMONER_INFO_REQUEST: {

            return {
                ...state,
                getSummonerInfoLoading: true,
                getSummonerInfoDone: false,
                getSummonerInfoError: null,
            }
        }
        case GET_SUMMONER_INFO_SUCCESS: {

            return {
                ...state,
                updateStatus: {...action.data.updateStatus},
                summonerStatus: {...action.data.summonerStatus},
                ingameStatus: action.data.ingameStatus,
                tierChart: {...action.data.tierChart},
                match: [...action.data.match],
                getSummonerInfoLoading: false,
                getSummonerInfoDone: true,
            }
        }
        case GET_SUMMONER_INFO_FAILURE: {
            return {
                ...state,
                getSummonerInfoLoading: false,
                getSummonerInfoError: action.error,
            }
        }
        case GET_SUMMONER_REQUEST: {
            return {
                ...initialState,
                getSummonerLoading: true,
                getSummonerDone: false,
                getSummonerError: null,
            }
        }

        case GET_SUMMONER_SUCCESS: {
            return {
                ...state,
                summoner: {...action.data.summoner},

                getSummonerLoading: false,
                getSummonerDone: true,
            }
        }
        case GET_SUMMONER_FAILURE: {
            return {
                ...state,
                getSummonerLoading: false,
                getSummonerError: action.error,
            }
        }
        case GET_CHAMP_INFO_REQUEST: {
            return {
                ...state,
                getChampInfoLoading: true,
                getChampInfoDone: false,
                getChampInfoError: null,
            }
        }
        case GET_CHAMP_INFO_SUCCESS: {
            return {
                ...state,
                withOrigin: {...action.data.withOrigin},
                withOutOrigin: {...action.data.withOutOrigin},
                getChampInfoLoading: false,
                getChampInfoDone: true,
            }
        }
        case GET_CHAMP_INFO_FAILURE: {
            return {
                ...state,
                getChampInfoLoading: false,
                getChampInfoError: action.error,
            }
        }
        case GET_MORE_MATCHES_REQUEST: {
            return {
                ...state,
                getMoreMatchesLoading: true,
                getMoreMatchesDone: false,
                getMoreMatchesError: null,
            }
        }
            ;
        case GET_MORE_MATCHES_SUCCESS: {
            return {
                ...state,
                match : [...state.match,...action.data.match],
                getMoreMatchesLoading: false,
                getMoreMatchesDone: true,
            }
        }
        case GET_MORE_MATCHES_FAILURE: {
            return {
                ...state,
                getMoreMatchesError: action.error,
            }
        }
        case SUMMONER:
            return {
                info: action.info,
            };
        default:
            return state;
    }
};
export default reducer;
