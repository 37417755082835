import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {searchList} from '../../store/modules/searchList';
import {bookmarkList} from '../../store/modules/bookmarkList';
import {StorageContainer} from '../../styled/CommonStyle';

const SearchList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const bookmarks = useSelector((state) => state.bookmarkList.bookmarks);
    const region = useSelector((state) => state.region.result);
    const searchs = useSelector((state) => state.searchList.searchs);
    const viewSearchs = [...searchs].reverse();
    const {t} = useTranslation();


    // 북마크 추가/제거
    const toggleBookmark = (item) => {
        let arr = bookmarks.filter((itm) => itm.summoner_id === item?.summoner_id);
        arr.length !== 0 ? dispatch(bookmarkList(item, 'remove')) : dispatch(bookmarkList({
            ...item,
            region: item.region ? item.region : region
        }, 'add'));
    };

    // 최근검색 항목 제거
    const removeSearch = (item) => {
        dispatch(searchList(item, 'remove'));
    };


    return (
        <StorageContainer>
            {searchs.length !== 0 ? (
                <ul className="search_list">
                    {viewSearchs.map((item, index) => {
                        const bookmarkArr = bookmarks.filter((itm) => item.summoner_name === itm.summoner_name);
                        return (
                            <li key={index}>
                                <p className="txt">
                                    <Link
                                        to={`/summoner/${item.summoner_name}/${item.region ? item.region : region}`}
                                        className="common_search">
                                        {item.summoner_name}
                                    </Link>
                                </p>
                                <button
                                    className={`bookmark ${bookmarkArr.length !== 0 ? 'on' : 'off'}`}
                                    onClick={() => toggleBookmark(item)}
                                >
                                    {t('buttons.favorite')}
                                </button>
                                <button className="remove" onClick={() => removeSearch(item)}>
                                    {t('buttons.delete')}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <p className="no_list">{t('persist.noRecentSearch')}</p>
            )}
        </StorageContainer>
    );
};

export default SearchList;
