import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {bookmarkList} from '../../store/modules/bookmarkList';
import {getGameTime} from '../../function';
import {ProfileContainer} from '../../styled/SummonerStyle';
import SplashImage from './SplashImage';
import styled from "styled-components";
import InfluInfo from "./Profile/InfluInfo";

const Wrapper = styled.div`
  opacity: 1;
  display: flex;
  flex: 1;
`;

const ExtraWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ImageWrapper = styled.button`
  display: flex;
  align-items: center;
  opacity: 1;
  z-index: 999;
`;

const ChampionBannerImage = styled.img`
  border-radius: 6px;
`;

const InfluWrapper = styled.div`
  margin-top: 6px;
`;

const Profile = ({region, updatePer, handleUpdate, handleTab}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {summoner, updateStatus, summonerStatus} = useSelector((state) => state?.summoner);
    const bookmarks = useSelector((state) => {
        let arr = state?.bookmarkList.bookmarks.filter((item) => item?.summoner_id === summoner?.summoner_id);
        return arr.length > 0
    });
    const currentLang = useSelector((state) => state?.translation?.lang);

    // 즐겨찾기 버튼
    const toggleBookmark = () => {
        if (bookmarks) {
            dispatch(bookmarkList(summoner, 'remove'));
        } else {
            dispatch(bookmarkList({...summoner, region: region}, 'add'));
        }
    };

    const onClickChampionBanner = useCallback(() => {
        handleTab(1);
    }, [])

    return (
        <ProfileContainer>
            {Object.keys(summoner).length > 0 &&
                <>
                    <div className="p__bg">
                        <SplashImage summonerStat={summonerStatus}/>
                    </div>
                    <div className="p__tier">
                        {summoner && Object.entries(summoner?.season_tier_dict).map((item, index) => {
                            const [key, value] = item;
                            return (
                                <div key={index + key} className="item">
                                    <i>2021</i>
                                    <span
                                        className={`tier_color ${value.toLowerCase()}`}>{value.toLowerCase()}</span>
                                </div>
                            );
                        })}
                    </div>
                    <Wrapper>
                        <div className="p__info">
                            <div className="icon">
                                <img
                                    src={`${process.env.REACT_APP_IMG}${summoner?.profile_icon.version}/img/profileicon/${summoner?.profile_icon.id}.png`}
                                    alt={summoner && summoner?.profile_icon.id}
                                />
                                <p>{summoner?.level}</p>
                            </div>
                            <div className="text">
                                {/*{summoner?.pro_info.team !== '-' && (*/}
                                {/*    <span className="pro">{summoner?.pro_info.team} {summoner?.pro_info?.name}</span>*/}
                                {/*)}*/}
                                <div className="name">
                                    <p>{summoner?.summoner_name}</p>
                                    <div className="favorite">
                                        <button className={`${bookmarks ? 'on' : 'off'}`} onClick={toggleBookmark}>
                                            {t('buttons.favorite')}
                                        </button>
                                    </div>
                                </div>
                                {summoner?.pro_streamer_info?.status !== '-' &&
                                <InfluWrapper>
                                    <InfluInfo
                                        influData={summoner?.pro_streamer_info}
                                    />
                                </InfluWrapper>}
                                <div className="update">
                                    <button onClick={() => handleUpdate(summoner, 'btnClick')}>
                                        {updatePer !== 100 && <i className="fill" style={{width: updatePer + '%'}}></i>}
                                        <span>{`${updatePer !== 100 ? updatePer + '%' : t('buttons.updated')}`}</span>
                                    </button>
                                    <p>
                                        {t('summoner.lastUpdate')}:{' '}
                                        <span>{updateStatus ? getGameTime(updateStatus.update_time, currentLang) : '-'}</span>
                                    </p>
                                </div>
                            </div>
                            <ExtraWrapper>
                                <ImageWrapper
                                    onClick={onClickChampionBanner}
                                >
                                    <ChampionBannerImage
                                        width={390}
                                        height={84}
                                        src={
                                            currentLang === 'KOR' ?
                                                './images/img_banner_top01_kr.jpg'
                                                :
                                                './images/img_banner_top01_en.jpg'}
                                    />
                                </ImageWrapper>
                            </ExtraWrapper>
                        </div>
                    </Wrapper>
                </>
            }
        </ProfileContainer>
    );
};

export default React.memo(Profile);
