import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {NoticeContainer} from '../../styled/CommonStyle';
import {useSelector} from "react-redux";
import styled from "styled-components";

const ErrorText = styled.p`
    color: white;
`;

const Notice = ({fixed}) => {
    const notice = useSelector((state) => state.notice.notice);
    const {t} = useTranslation();


    if (notice === 'alertError') {
        return (
            <NoticeContainer fixed={fixed} style={{backgroundColor: '#FF6C81'}}>
                <ErrorText>{t('notice.alertError')}</ErrorText>
            </NoticeContainer>
        )
    } else {
        return (
            <NoticeContainer fixed={fixed}>
                <p className="link">
                    <Link to={{pathname: 'https://ws.pocketsurvey.co.kr/survey1/hAR47k4'}} target="_blank">
                        📢 {t('notice.text')}
                    </Link>
                </p>
            </NoticeContainer>
        );
    }
};

export default Notice;
