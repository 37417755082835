import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {getSortedObject} from '../../function';

const SplashImage = ({summonerStat}) => {
    const dataSelector = useSelector((state) => state.imageStore.champions);
    const [loading, setLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState('');


    // 대표 챔피언 이미지
    const getMainChamp = useCallback(
        (champId) => {
            if (dataSelector.length !== 0) {
                let champArr = dataSelector.filter((item) => item.championId === `${champId}`);
                if (champArr.length !== 0) {
                    let split = champArr[0].image_name.split('.');
                    setLoading(true);
                    setImgSrc(split[0]);
                } else {
                    setLoading(true);
                    setImgSrc('');
                }
            }
        },
        [dataSelector]
    );

    useEffect(() => {
        if (summonerStat.hasOwnProperty('total')) {
            const result = getSortedObject(Object.values(summonerStat)[0], 'games', 'descending');
            if (result.length !== 0) {
                getMainChamp(result[0].cid);
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }, [getMainChamp, summonerStat]);

    return (
        <>
            {loading ? (
                imgSrc.length !== 0 ? (
                    <span
                        className="img"
                        style={{
                            backgroundImage: `url(${process.env.REACT_APP_IMG}img/champion/splash/${imgSrc}_0.jpg)`,
                        }}
                    ></span>
                ) : (
                    <span className="img" style={{backgroundColor: '#555'}}></span>
                )
            ) : (
                <span className="img" style={{backgroundColor: '#555'}}></span>
            )}
        </>
    );
};

export default SplashImage;
