export const GET_MORE_MATCHES_REQUEST = 'summoner/GET_MORE_MATCHES_REQUEST';
export const GET_MORE_MATCHES_SUCCESS = 'summoner/GET_MORE_MATCHES_SUCCESS';
export const GET_MORE_MATCHES_FAILURE = 'summoner/GET_MORE_MATCHES_FAILURE';

export const GET_SUMMONER_REQUEST = 'summoner/GET_SUMMONER_REQUEST';
export const GET_SUMMONER_SUCCESS = 'summoner/GET_SUMMONER_SUCCESS';
export const GET_SUMMONER_FAILURE = 'summoner/GET_SUMMONER_FAILURE';

export const GET_SUMMONER_INFO_REQUEST = 'summoner/GET_SUMMONER_INFO_REQUEST';
export const GET_SUMMONER_INFO_SUCCESS = 'summoner/GET_SUMMONER_INFO_SUCCESS';
export const GET_SUMMONER_INFO_FAILURE = 'summoner/GET_SUMMONER_INFO_FAILURE';

export const UPDATE_SUMMONER_INFO_REQUEST = 'summoner/UPDATE_SUMMONER_INFO_REQUEST'
export const UPDATE_SUMMONER_INFO_SUCCESS = 'summoner/UPDATE_SUMMONER_INFO_SUCCESS'
export const UPDATE_SUMMONER_INFO_FAILURE = 'summoner/UPDATE_SUMMONER_INFO_FAILURE'

export const GET_UPDATE_SATUS_REQUEST = 'summoner/GET_UPDATE_SATUS_REQUEST';
export const GET_UPDATE_SATUS_SUCCESS = 'summoner/GET_UPDATE_SATUS_SUCCESS';
export const GET_UPDATE_SATUS_FAILURE = 'summoner/GET_UPDATE_SATUS_FAILURE';

export const GET_MATCH_BY_QUEUE_SUCCESS = 'summoner/GET_MATCH_BY_QUEUE_SUCCESS';
export const GET_MATCH_BY_QUEUE_REQUEST = 'summoner/GET_MATCH_BY_QUEUE_REQUEST';
export const GET_MATCH_BY_QUEUE_FAILURE = 'summoner/GET_MATCH_BY_QUEUE_FAILURE';

export const GET_CHAMP_INFO_REQUEST = 'summoner/GET_CHAMP_INFO_REQUEST';
export const GET_CHAMP_INFO_SUCCESS = 'summoner/GET_CHAMP_INFO_SUCCESS';
export const GET_CHAMP_INFO_FAILURE = 'summoner/GET_CHAMP_INFO_FAILURE';