import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {openModal} from '../../store/modules/modal';
import {
    ChartScore,
    ImageChamp,
    ImageItem,
    ImageLane,
    ImageMonster,
    ImageRune,
    ImageSpell,
    Loading,
} from '../../components';
import {
    csPerMinute,
    getDamagePercent,
    getKda,
    getSortedArray,
    getSortedObject,
    getTierScore,
    getTimeHMS,
    kdaColor,
    scoreColor,
    tierBadge,
} from '../../function';

const DetailTab1 = ({match, myMatch}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const currentLang = useSelector((state) => state.translation.lang);
    const [originBlue, setOriginBlue] = useState([]);
    const [originRed, setOriginRed] = useState([]);
    const [ratingAI, setRatingAI] = useState([]);
    const [ratingDPM, setRatingDPM] = useState([]);
    const [ratingDTPM, setRatingDTPM] = useState([]);
    const [isToggled, setIsToggled] = useState(false);
    const [menu, setMenu] = useState(t('summoner.selector.aiScore'));
    const [menuList, setMenuList] = useState([
        {id: 0, text: t('summoner.selector.aiScore'), selected: true},
        {id: 1, text: t('summoner.selector.dmg'), selected: false},
        {id: 2, text: t('summoner.selector.taken'), selected: false},
    ]);
    const [totalBlue, setTotalBlue] = useState({});
    const [totalRed, setTotalRed] = useState({});
    const [tab, setTab] = useState(t('summoner.selectorBtn.total'));
    const [tabList, setTabList] = useState([
        {id: 0, text: t('summoner.selectorBtn.total'), selected: true},
        // { id: 1, text: t('summoner.selectorBtn.m15'), selected: false },
        {id: 2, text: t('summoner.selectorBtn.dmg'), selected: false},
    ]);

    const handleMenuSelect = (item) => {
        setMenu(item.text);
        setIsToggled(false);
        setMenuList(
            menuList.map((itm) => {
                return item.text === itm.text ? {...itm, selected: true} : {...itm, selected: false};
            })
        );
    };

    const handleTabSelect = (item) => {
        setTab(item.text);
        setTabList(
            tabList.map((itm) => {
                return item.text === itm.text ? {...itm, selected: true} : {...itm, selected: false};
            })
        );
    };

    useEffect(() => {
        setTab(t('summoner.selectorBtn.total'));
        setTabList([
            {id: 0, text: currentLang === 'KOR' ? '종합' : 'Total', selected: true},
            // { id: 1, text: currentLang === 'KOR' ? '15분' : '15m', selected: false },
            {id: 2, text: currentLang === 'KOR' ? '피해량' : 'DMG', selected: false},
        ]);
    }, [currentLang, t]);

    const initialFunc = useCallback(() => {
        // 원래 match_json
        const frameBlue = getSortedObject(
            getSortedArray(match.match_json),
            'frameId',
            'ascending'
        ).slice(0, 5);
        setOriginBlue(frameBlue);
        const frameRed = getSortedObject(
            getSortedArray(match.match_json),
            'frameId',
            'ascending'
        ).slice(5, 10);
        setOriginRed(frameRed);

        // AI스코어순 정렬
        setRatingAI(getSortedObject(getSortedArray(match.match_json), 'minmax_z', 'descending'));

        // 피해량순 정렬
        setRatingDPM(
            getSortedObject(getSortedArray(match.match_json), 'totalDamageDealtToChampions', 'descending')
        );

        // 받은피해량순 정렬
        setRatingDTPM(
            getSortedObject(getSortedArray(match.match_json), 'totalDamageTaken', 'descending')
        );

        let teamBlue = {
            win: '',
            kills: 0,
            golds: 0,
            objs: [],
        };
        let teamRed = {
            win: '',
            kills: 0,
            golds: 0,
            objs: [],
        };
        frameBlue.forEach((item) => {
            teamBlue.win = item.win;
            teamBlue.kills += item.kills;
            teamBlue.golds += item.goldEarned;
        });
        frameRed.forEach((item) => {
            teamRed.win = item.win;
            teamRed.kills += item.kills;
            teamRed.golds += item.goldEarned;
        });
        Object.values(match.inference_json.detail_event).forEach((item) => {
            if (item.event.type === 'Monster') {
                if (item.event.side === 'B') {
                    teamBlue.objs.push(item.event.target);
                } else if (item.event.side === 'R') {
                    teamRed.objs.push(item.event.target);
                }
            }
        });
        setTotalBlue(teamBlue);
        setTotalRed(teamRed);
    }, [match]);

    useEffect(() => {
        initialFunc();
    }, [initialFunc]);

    return (
        <>
            {ratingAI.length !== 0 ? (
                <div className="analysis_ai">
                    <div className="total">
                        <div className="title">
                            <h5>{t('summoner.rank')}</h5>
                            <div className="toggle">
                <span className="toggle__btn" onClick={() => setIsToggled(!isToggled)}>
                  {menu}
                </span>
                                {isToggled && (
                                    <ul className="toggle__list">
                                        {menuList.map((item) => (
                                            <li
                                                key={item.id}
                                                className={`toggle__list-item ${item.selected && 'selected'}`}
                                                onClick={() => handleMenuSelect(item)}
                                            >
                                                {item.text}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                        {menuList.map((item) => (
                            <ul className={`list ${item.selected && 'selected'}`} key={item.id}>
                                {item.id === 0 &&
                                ratingAI.map((item, index) => (
                                    <li className={`list__item ${item.win ? 'win' : 'lose'}`} key={item.frameId}>
                                        <div className="rating">{index + 1}</div>
                                        <div
                                            className={`champ ${
                                                item.summonerName === myMatch.summonerName ? 'my' : 'player'
                                            }`}
                                        >
                                            {item.mvp && <span className="crown mvp"></span>}
                                            {item.ace && <span className="crown ace"></span>}
                                            <span className="box">
                          <ImageChamp champId={item.championId}/>
                        </span>
                                        </div>
                                        <div className="score">{scoreColor(item.ai_score)}</div>
                                        <div className="chart">
                                            <ChartScore
                                                data={match.inference_json}
                                                myMatch={item}
                                                width={90}
                                                height={40}
                                                label={false}
                                            />
                                        </div>
                                    </li>
                                ))}
                                {item.id === 1 &&
                                ratingDPM.map((item, index) => (
                                    <li className={`list__item ${item.win ? 'win' : 'lose'}`} key={item.frameId}>
                                        <div className="rating">{index + 1}</div>
                                        <div
                                            className={`champ ${
                                                item.summonerName === myMatch.summonerName ? 'my' : 'player'
                                            }`}
                                        >
                                            {item.mvp && <span className="crown mvp"></span>}
                                            {item.ace && <span className="crown ace"></span>}
                                            <span className="box">
                          <ImageChamp champId={item.championId}/>
                        </span>
                                        </div>
                                        <div className="score">{scoreColor(item.ai_score)}</div>
                                        <div className="chart">
                                            <p className="num">{item.totalDamageDealtToChampions}</p>
                                            <div className="bar blue">
                          <span
                              className="line"
                              style={{
                                  width: `${getDamagePercent(
                                      item.totalDamageDealtToChampions,
                                      match.match_json,
                                      'totalDamageDealtToChampions'
                                  )}%`,
                              }}
                          ></span>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                {item.id === 2 &&
                                ratingDTPM.map((item, index) => (
                                    <li className={`list__item ${item.win ? 'win' : 'lose'}`} key={item.frameId}>
                                        <div className="rating">{index + 1}</div>
                                        <div
                                            className={`champ ${
                                                item.summonerName === myMatch.summonerName ? 'my' : 'player'
                                            }`}
                                        >
                                            {item.mvp && <span className="crown mvp"></span>}
                                            {item.ace && <span className="crown ace"></span>}
                                            <span className="box">
                          <ImageChamp champId={item.championId}/>
                        </span>
                                        </div>
                                        <div className="score">{scoreColor(item.ai_score)}</div>
                                        <div className="chart">
                                            <p className="num">{item.totalDamageTaken}</p>
                                            <div className="bar red">
                          <span
                              className="line"
                              style={{
                                  width: `${getDamagePercent(
                                      item.totalDamageTaken,
                                      match.match_json,
                                      'totalDamageTaken'
                                  )}%`,
                              }}
                          ></span>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ))}
                    </div>
                    <div className="result">
                        <div className="title">
                            <div className="team blue">
                                <strong className={`${totalBlue.win ? 'win' : 'lose'}`}>
                                    {totalBlue.win ? t('summoner.win') : t('summoner.lose')}
                                </strong>
                                <span>
                  ({t('summoner.side.blue')} {t('summoner.side.text')})
                </span>
                            </div>
                            <div className="center">
                                <div className="main">
                                    <div className={`blue ${totalBlue.win ? 'win' : 'lose'}`}>
                                        <div className="golds">
                                            <strong>{(totalBlue.golds / 1000).toFixed(1)}k</strong>
                                            <img src="/images/icon_gold.svg" alt="gold"/>
                                        </div>
                                        <div className="kills">{totalBlue.kills}</div>
                                    </div>
                                    <div className="vs">
                                        <p>VS</p>
                                    </div>
                                    <div className={`red ${totalRed.win ? 'win' : 'lose'}`}>
                                        <div className="golds">
                                            <strong>{(totalRed.golds / 1000).toFixed(1)}k</strong>
                                            <img src="/images/icon_gold.svg" alt="gold"/>
                                        </div>
                                        <div className="kills">{totalRed.kills}</div>
                                    </div>
                                </div>
                                <div className="sub">
                                    <div className="objs blue">
                                        {totalBlue.objs.map((item, index) => (
                                            <ImageMonster key={index} obj={item} only="dragon"/>
                                        ))}
                                    </div>
                                    <div className="time">{getTimeHMS(match.duration, 'num', currentLang)}</div>
                                    <div className="objs red">
                                        {totalRed.objs.map((item, index) => (
                                            <ImageMonster key={index} obj={item} only="dragon"/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="team red">
                                <strong className={`${totalRed.win ? 'win' : 'lose'}`}>
                                    {totalRed.win ? t('summoner.win') : t('summoner.lose')}
                                </strong>
                                <span>
                  ({t('summoner.side.red')} {t('summoner.side.text')})
                </span>
                            </div>
                        </div>
                        <div className="content">
                            <div className="t_head">
                                <div className="team blue">
                  <span className="team_column1">
                    {t('tabs.item')}/{t('tabs.ward')}
                  </span>
                                    <span className="team_column2">KDA / CS</span>
                                    <span className="team_column3">{t('tabs.champions')}</span>
                                    <span className="team_column4">AI-Score</span>
                                </div>
                                <div className="buttons">
                                    {tabList.map((item) => (
                                        <button
                                            key={item.id}
                                            className={`${item.selected && 'selected'}`}
                                            onClick={() => handleTabSelect(item)}
                                        >
                                            {item.text}
                                        </button>
                                    ))}
                                </div>
                                <div className="team red">
                  <span className="team_column1">
                    {t('tabs.item')}/{t('tabs.ward')}
                  </span>
                                    <span className="team_column2">KDA / CS</span>
                                    <span className="team_column3">{t('tabs.champions')}</span>
                                    <span className="team_column4">AI-Score</span>
                                </div>
                            </div>
                            <div className="t_body">
                                <ul className={`${totalBlue.win ? 'win' : 'lose'}`}>
                                    {originBlue.map((item) => (
                                        <li className="team blue" key={item.frameId}>
                                            <div className="team_column1">
                                                <div className="cbox">
                                                    <ImageItem itemId={item.item_detail.item0}/>
                                                    <ImageItem itemId={item.item_detail.item1}/>
                                                    <ImageItem itemId={item.item_detail.item2}/>
                                                    <ImageItem itemId={item.item_detail.item6}/>
                                                    <ImageItem itemId={item.item_detail.item3}/>
                                                    <ImageItem itemId={item.item_detail.item4}/>
                                                    <ImageItem itemId={item.item_detail.item5}/>
                                                    <img
                                                        src="/images/btn_item_build_search_w.svg"
                                                        className="build_detail"
                                                        onClick={() => dispatch(openModal(true, item))}
                                                        alt={t('buttons.detail')}
                                                    />
                                                </div>
                                                <div className="vision">
                          <span>
                            <img src="/images/ico_ward01.svg" alt="구매한 와드 수"/>
                              {item.visionWardsBoughtInGame}
                          </span>
                                                    <span>
                            <img src="/images/ico_ward02.svg" alt="설치한 와드 수"/>
                                                        {item.wardsPlaced}
                          </span>
                                                    <span>
                            <img src="/images/ico_ward03.svg" alt="제거한 와드 수"/>
                                                        {item.wardsKilled}
                          </span>
                                                </div>
                                            </div>
                                            <div className="team_column2">
                                                <p className="type1">
                                                    {item.deaths === 0 ? (
                                                        <span className="kda_color perfect">Perfect</span>
                                                    ) : (
                                                        kdaColor(getKda(item.kills, item.deaths, item.assists).toFixed(2))
                                                    )}
                                                </p>
                                                <p className="type2">
                                                    <span>{item.kills}</span>/<span
                                                    className="death">{item.deaths}</span>/
                                                    <span>{item.assists}</span>
                                                </p>
                                                <p className="type3">
                                                    <span>{item.cs}</span>({csPerMinute(item.cs, match.duration)}/
                                                    {t('summoner.minute')})
                                                </p>
                                                <p className="type4">
                                                    <span>{item.kill_point.toFixed(0)}%</span> {t('summoner.kp')}
                                                </p>
                                            </div>
                                            <div className="team_column3">
                                                <Link
                                                    replace
                                                    to={`/summoner/${item.summonerName}`}
                                                    target="_blank"
                                                    className="common_search"
                                                >
                                                    <dl>
                                                        <dt
                                                            className={`${item.check_away ? 'away' : 'home'} ${
                                                                item.summonerName === myMatch.summonerName ? 'my' : 'player'
                                                            }`}
                                                        >
                                                            <ImageChamp champId={item.championId}/>
                                                            <span>{item.champLevel}</span>
                                                        </dt>
                                                        <dd>
                                                            <ImageSpell spellId={item.summonerSpellDId}
                                                                        smite={item.smite}/>
                                                            <ImageSpell spellId={item.summonerSpellFId}
                                                                        smite={item.smite}/>
                                                        </dd>
                                                        <dd>
                                                            <ImageRune runeId={item.rune_detail.perk0}/>
                                                            <ImageRune runeId={item.rune_detail.perkSubStyle}/>
                                                        </dd>
                                                    </dl>
                                                    <p
                                                        className={`${item.check_away ? 'away' : 'home'} ${
                                                            item.summonerName === myMatch.summonerName ? 'my' : 'player'
                                                        }`}
                                                    >
                                                        {item.summonerName}
                                                    </p>
                                                </Link>
                                            </div>
                                            <div className="team_column4">
                                                <h4 onClick={() => dispatch(openModal(true, item, match))}>
                                                    {scoreColor(item.ai_score)}
                                                </h4>
                                                {item.check_away ? (
                                                    <p className="away">{t('summoner.escape')}</p>
                                                ) : (
                                                    <p>
                                                        {item.rating === 1 ? (
                                                            <>
                                                                <i>{`1${t('summoner.firstGrade')}`}</i>
                                                            </>
                                                        ) : item.rating === 2 ? (
                                                            <>
                                                                <i>{`2${t('summoner.secondGrade')}`}</i>
                                                            </>
                                                        ) : item.rating === 3 ? (
                                                            <>
                                                                <i>{`3${t('summoner.thirdGrade')}`}</i>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i>{item.rating + t('summoner.grade')}</i>
                                                            </>
                                                        )}
                                                    </p>
                                                )}
                                                {tierBadge(getTierScore(item.tier).txt)}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <ul className={`${totalRed.win ? 'win' : 'lose'}`}>
                                    {originRed.map((item) => (
                                        <li className="team red" key={item.frameId}>
                                            <div className="team_column1">
                                                <div className="cbox">
                                                    <ImageItem itemId={item.item_detail.item0}/>
                                                    <ImageItem itemId={item.item_detail.item1}/>
                                                    <ImageItem itemId={item.item_detail.item2}/>
                                                    <ImageItem itemId={item.item_detail.item6}/>
                                                    <ImageItem itemId={item.item_detail.item3}/>
                                                    <ImageItem itemId={item.item_detail.item4}/>
                                                    <ImageItem itemId={item.item_detail.item5}/>
                                                    <img
                                                        src="/images/btn_item_build_search_w.svg"
                                                        className="build_detail"
                                                        onClick={() => dispatch(openModal(true, item))}
                                                        alt={t('buttons.detail')}
                                                    />
                                                </div>
                                                <div className="vision">
                          <span>
                            <img src="/images/ico_ward01.svg" alt="구매한 와드 수"/>
                              {item.visionWardsBoughtInGame}
                          </span>
                                                    <span>
                            <img src="/images/ico_ward02.svg" alt="설치한 와드 수"/>
                                                        {item.wardsPlaced}
                          </span>
                                                    <span>
                            <img src="/images/ico_ward03.svg" alt="제거한 와드 수"/>
                                                        {item.wardsKilled}
                          </span>
                                                </div>
                                            </div>
                                            <div className="team_column2">
                                                <p className="type1">
                                                    {item.deaths === 0 ? (
                                                        <span className="kda_color perfect">Perfect</span>
                                                    ) : (
                                                        kdaColor(getKda(item.kills, item.deaths, item.assists).toFixed(2))
                                                    )}
                                                </p>
                                                <p className="type2">
                                                    <span>{item.kills}</span>/<span
                                                    className="death">{item.deaths}</span>/
                                                    <span>{item.assists}</span>
                                                </p>
                                                <p className="type3">
                                                    <span>{item.cs}</span>({csPerMinute(item.cs, match.duration)}/
                                                    {t('summoner.minute')})
                                                </p>
                                                <p className="type4">
                                                    <span>{item.kill_point.toFixed(0)}%</span> {t('summoner.kp')}
                                                </p>
                                            </div>
                                            <div className="team_column3">
                                                <Link
                                                    replace
                                                    to={`/summoner/${item.summonerName}`}
                                                    target="_blank"
                                                    className="common_search"
                                                >
                                                    <dl>
                                                        <dt
                                                            className={`${item.check_away ? 'away' : 'home'} ${
                                                                item.summonerName === myMatch.summonerName ? 'my' : 'player'
                                                            }`}
                                                        >
                                                            <ImageChamp champId={item.championId}/>
                                                            <span>{item.champLevel}</span>
                                                        </dt>
                                                        <dd>
                                                            <ImageSpell spellId={item.summonerSpellDId}
                                                                        smite={item.smite}/>
                                                            <ImageSpell spellId={item.summonerSpellFId}
                                                                        smite={item.smite}/>
                                                        </dd>
                                                        <dd>
                                                            <ImageRune runeId={item.rune_detail.perk0}/>
                                                            <ImageRune runeId={item.rune_detail.perkSubStyle}/>
                                                        </dd>
                                                    </dl>
                                                    <p
                                                        className={`${item.check_away ? 'away' : 'home'} ${
                                                            item.summonerName === myMatch.summonerName ? 'my' : 'player'
                                                        }`}
                                                    >
                                                        {item.summonerName}
                                                    </p>
                                                </Link>
                                            </div>
                                            <div className="team_column4">
                                                <h4 onClick={() => dispatch(openModal(true, item, match))}>
                                                    {scoreColor(item.ai_score)}
                                                </h4>
                                                {item.check_away ? (
                                                    <p className="away">{t('summoner.escape')}</p>
                                                ) : (
                                                    <p>
                                                        {item.rating === 1 ? (
                                                            <>
                                                                <i>{`1${t('summoner.firstGrade')}`}</i>
                                                            </>
                                                        ) : item.rating === 2 ? (
                                                            <>
                                                                <i>{`2${t('summoner.secondGrade')}`}</i>
                                                            </>
                                                        ) : item.rating === 3 ? (
                                                            <>
                                                                <i>{`3${t('summoner.thirdGrade')}`}</i>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i>{item.rating + t('summoner.firstGrade')}</i>
                                                            </>
                                                        )}
                                                    </p>
                                                )}
                                                {tierBadge(getTierScore(item.tier).txt)}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div className="compare">
                                    {tab === t('summoner.selectorBtn.total') &&
                                    originRed.map((item, index) => {
                                        let total = item.ai_score + originBlue[index].ai_score;
                                        return (
                                            <div className="box" key={index}>
                                                <div className="lane">
                                                    <ImageLane lane={item.lane}/>
                                                </div>
                                                <div className="influence">
                            <span
                                className={`bar blue ${originBlue[index].win ? 'win' : 'lose'}`}
                                style={{
                                    width: `${((originBlue[index].ai_score / total) * 100).toFixed(
                                        0
                                    )}%`,
                                }}
                            ></span>
                                                    <span className="txt blue">
                              {((originBlue[index].ai_score / total) * 100).toFixed(0)}%
                            </span>
                                                    <span
                                                        className={`bar red ${item.win ? 'win' : 'lose'}`}
                                                        style={{width: `${((item.ai_score / total) * 100).toFixed(0)}%`}}
                                                    ></span>
                                                    <span className="txt red">
                              {((item.ai_score / total) * 100).toFixed(0)}%
                            </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {tab === t('summoner.selectorBtn.m15') &&
                                    originRed.map((item, index) => {
                                        let total = item.lane_score + originBlue[index].lane_score;
                                        return (
                                            <div className="box" key={index}>
                                                <div className="lane">
                                                    <ImageLane lane={item.lane}/>
                                                </div>
                                                <div className="influence">
                            <span
                                className={`bar blue ${originBlue[index].win ? 'win' : 'lose'}`}
                                style={{
                                    width: `${((originBlue[index].lane_score / total) * 100).toFixed(
                                        0
                                    )}%`,
                                }}
                            ></span>
                                                    <span className="txt blue">
                              {((originBlue[index].lane_score / total) * 100).toFixed(0)}%
                            </span>
                                                    <span
                                                        className={`bar red ${item.win ? 'win' : 'lose'}`}
                                                        style={{width: `${((item.lane_score / total) * 100).toFixed(0)}%`}}
                                                    ></span>
                                                    <span className="txt red">
                              {((item.lane_score / total) * 100).toFixed(0)}%
                            </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {tab === t('summoner.selectorBtn.dmg') &&
                                    originRed.map((item, index) => {
                                        let total = ratingDPM[0].totalDamageDealtToChampions;
                                        return (
                                            <div className="box damage_box" key={index}>
                                                <div className="lane">
                                                    <ImageLane lane={item.lane}/>
                                                </div>
                                                <div className="damage">
                            <span className={`bar blue ${originBlue[index].win ? 'win' : 'lose'}`}>
                              <i
                                  style={{
                                      width: `${(
                                          (originBlue[index].totalDamageDealtToChampions / total) *
                                          100
                                      ).toFixed(0)}%`,
                                  }}
                              ></i>
                            </span>
                                                    <span className="txt blue">
                              {(originBlue[index].totalDamageDealtToChampions / 1000).toFixed(1)}K
                            </span>
                                                    <span className={`bar red ${item.win ? 'win' : 'lose'}`}>
                              <i
                                  style={{
                                      width: `${(
                                          (item.totalDamageDealtToChampions / total) *
                                          100
                                      ).toFixed(0)}%`,
                                  }}
                              ></i>
                            </span>
                                                    <span className="txt red">
                              {(item.totalDamageDealtToChampions / 1000).toFixed(1)}K
                            </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Loading/>
            )}
        </>
    );
};

export default React.memo(DetailTab1);
