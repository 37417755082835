import React from 'react';
import styled from "styled-components";

const SideAndPercent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${props => {
    let direction = 'row';
    if (props.rowReverse) direction = 'row-reverse';
    return direction
  }};
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
`;

const PercentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

const TeamTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const TeamText = styled.span`

  font-size: 28px;
  font-weight: bold;
  color: ${props => props.color};
`;

const PercetnText = styled.span`
  font-size: 52px;
  font-weight: bold;
  color: ${props => props.color}
`;

const ExtraWrapper = styled.div`
  margin-bottom: 5px;
`
const PercentTextExtra = styled.span`
  font-size: 24px;
  color: #313131;
  opacity: 0.6;
`;

const IngameTeamInfo = ({sideName, teamName, winRate, rowReverse = false, color}) => {
    return (
        <>
            <SideAndPercent
                rowReverse={rowReverse}
            >
                <TeamTextWrapper>
                    <TeamText color={color}>
                        {sideName} {teamName}
                    </TeamText>
                </TeamTextWrapper>
                <PercentWrapper>
                    <PercetnText
                        color={color}
                    >{winRate} </PercetnText>
                    <ExtraWrapper>
                        <PercentTextExtra>%</PercentTextExtra>
                    </ExtraWrapper>
                </PercentWrapper>
            </SideAndPercent>
        </>
    );
};

export default IngameTeamInfo;