import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useTranslation} from 'react-i18next';
import dompurify from 'dompurify';

import {changeVersion} from '../../store/modules/gameVersion';
import {imageStore} from '../../store/modules/imageStore';
import {Header} from '../../components';
import {useTitle} from '../../function';
import MultiSearch from './MultiSearch';
import MultiList from './MultiList';
import {MultiContainer} from '../../styled/MultiStyle';
import styled from "styled-components";
import {GoogleAdvertise} from "../common";


const AdWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const AdSection = styled.div`
`;


const AdBox = styled.div`
  min-width: 300px;
  min-height: 600px;
  float: ${props => props.float};
  margin-left: ${props => props.leftMargin}px;
  margin-right: ${props => props.rightMargin}px;
`;

const Multi = () => {
    const {t} = useTranslation();
    const sanitizer = dompurify.sanitize;

    const history = useHistory();
    const location = useLocation();
    // const [loading,setLoading] = useState(false);
    const dispatch = useDispatch();
    const [isSearched, setIsSearched] = useState(false);
    const [multiList, setMultiList] = useState([]);
    const [inpText, setInpText] = useState('');
    const [copy, setCopy] = useState({
        copyUrl: window.location.href,
        copied: false,
    });
    const [avg, setAvg] = useState(0);
    const region = useSelector((state) => state.region.result);
    const currentLang = useSelector((state) => state.translation.lang);

    const titleUpdate = useTitle('Loading...');
    setTimeout(() => titleUpdate(t('header.titleMulti')), 500);


    // ai score 평균
    const getAvg = (num) => {
        setAvg(num);
    };

    // 공유하기
    const handleCopy = () => {
        setCopy({copied: true});
        closeCopied();
    };

    // 공유하기 툴팁
    const closeCopied = () => {
        setTimeout(() => {
            setCopy({copied: false});
        }, 2000);
    };

    // 소환사 하나씩 데이터 받아오기
    const getSummonerData = useCallback((summonerName) => {
        let name = summonerName;
        // 두글자면 띄어쓰기 삽입
        if (summonerName.length === 2) {
            const result = summonerName.split('').join('%20');
            name = result;
        }
        // 공백제거
        if (summonerName.indexOf(' ') !== -1) {
            const result = summonerName.replace(' ', '%20');
            name = result;
        }


        //예외 처리
        if (typeof name !== 'string' || name.trim().length < 1) {
            return;
        }


        axios.get(
            `${process.env.REACT_APP_API}get_multisearch?summonerName=${encodeURI(
                name
            )}&season=13&region=${region}`
        )
            .then((res) => {
                const result = res.data.result.a;


                if (result === undefined) return;

                setMultiList((multiList) => [...multiList, result]);
                setIsSearched(true);
            })
            .catch((err) => {
                setIsSearched(false);
                console.error(err);
            })

    }, [region]);

    // 소환사명 받아서 하나씩 배열로 변경
    const getSummonersName = (text) => {
        if (typeof text === 'string') {
            // (joined the room.)| (님이|로비에|참가하셨습니다.)|(,\s)|,|\n|\r
            let arr_str = text.replace(/(joined the room.)| (님이|로비에|참가하셨습니다.)|(,\s)|,|\n|\r/gi, '/');
            arr_str = arr_str.replace(/(\s\/)+|(\s\/)/gi, '/');
            arr_str = arr_str.replace(/(\/\s)|(\/)+/gi, '/');
            arr_str = arr_str.replace(/\/$/gi, '');
            arr_str = arr_str.split('/');

            // 중복, 빈값 제거
            const set = new Set(arr_str);
            const uniqueArr = [...set];
            const modyArr = uniqueArr.filter((item) => item !== '').slice(0, 5);

            setMultiList([]);


            history.replace({
                pathname: `/multi/${modyArr}`,
                state: {name: modyArr},
            });
        }
    };

    // 챔피언, 룬, 스펠, 아이템 스토어 등록 (이미지용)
    const getImageInfo = useCallback(
        async (version) => {
            let _thisGameVersionArr = version.split('.');
            let _thisGameVersion = _thisGameVersionArr[0] + '.' + _thisGameVersionArr[1];

            const resChampions = await axios.get(`${process.env.REACT_APP_CHAMP_INFO}`);
            const resRunes = await axios.get(
                `${process.env.REACT_APP_RUNE}?version=${_thisGameVersion}&lang=${
                    currentLang === 'KOR' ? 'ko_KR' : 'en_US'
                }`
            );
            const resSpells = await axios.get(
                `${process.env.REACT_APP_SPELL}?version=${_thisGameVersion}&lang=${
                    currentLang === 'KOR' ? 'ko_KR' : 'en_US'
                }`
            );
            const resItems = await axios.get(
                `${process.env.REACT_APP_ITEM}?version=${_thisGameVersion}&lang=${
                    currentLang === 'KOR' ? 'ko_KR' : 'en_US'
                }`
            );
            dispatch(
                imageStore(
                    resChampions.data.champions,
                    resRunes.data,
                    resSpells.data.data,
                    resItems.data.data
                )
            );
        },
        [currentLang, dispatch]
    );

    // 게임 버전 스토어 등록
    const getGameVersion = useCallback(async () => {
        const res = await axios.get('https://ddragon.leagueoflegends.com/api/versions.json');
        dispatch(changeVersion(res.data[0]));

        // 이미지 정보 함수호출
        getImageInfo(res.data[0]);
    }, [dispatch, getImageInfo]);

    // getSummonerData 순서대로 호출
    const orderCall = useCallback(
        async (name) => {
            if (Array.isArray(name)) {
                for (const item of name) {
                    await getSummonerData(item);
                }
            }
        },
        [getSummonerData]
    );

    useEffect(() => {
        // 게임버전 가져오기
        getGameVersion();

        setMultiList([]);
        setIsSearched(false);
        setAvg(0);

        let text = decodeURI(location.pathname).slice(7);
        let name = text.split(',');

        orderCall(name);

        setInpText(text);
        setCopy({copyUrl: window.location.href});
    }, [getGameVersion, orderCall, location]);

    const onClickIngameButton = () => {
        if (multiList.length > 0) {
            let summonerName = multiList[0]?.summoner_info?.summoner_name;
            if (summonerName) {
                const origin = window.location.origin;
                window.open(`${origin}/summoner/${summonerName}/${region}?tabs=3`, '_blank',);
                // history.push({
                //     target:'_blank',
                //     pathname: `/summoner/${summonerName}/${region}`,
                //     state: { name: summonerName,
                //         tabs: 2,
                //     },
                // });
            }

        }

    }

    return (
        <>
            <Header/>

            <MultiContainer>
                <div className="multi_top">
                    <div className="center">
                        <MultiSearch getSummonersName={getSummonersName} inpText={inpText}/>
                        <div className="info">
                            <div className="box">
                                <h4>{t('multi.teamAvg')} AI-Score</h4>
                                <div className="bg">
                                    <p>{isNaN(avg) ? '0' : avg}</p>
                                </div>
                            </div>
                        </div>
                        <div className="buttons">
                            <button className="ingame" onClick={onClickIngameButton}>
                                {t('buttons.ingame')}
                            </button>
                            <CopyToClipboard onCopy={handleCopy} text={copy.copyUrl}>
                                <button className="share">{t('buttons.share')}</button>
                            </CopyToClipboard>
                            {copy.copied && <span className="share_tooltip">{t('multi.clipboard')}</span>}
                        </div>
                    </div>
                </div>

                <div className="multi_bottom">
                    <AdBox float={'left'} rightMargin={50}>
                        <GoogleAdvertise
                            style={{
                                display: 'block',
                                minWidth:300,
                                minHeight:600,
                            }}
                            slot={"1162410370"}
                            client={"ca-pub-1406283594335387"}
                            format={"auto"}
                            responsive={"true"}
                        />
                    </AdBox>
                    <div className="center">
                        {!isSearched ? (
                            <div className="how_to_use">
                                <h3>{t('multi.howtouse')}</h3>
                                <div className="box">
                                    <p dangerouslySetInnerHTML={{__html: sanitizer(t('multi.text'))}}></p>
                                    <div className="imgs">
                                        <img src="/images/img_picks.png" alt="picks" className="picks"/>
                                        <img src="/images/icon_s_expand_arrow.svg" alt="arrow"/>
                                        <img src="/images/img_browser.png" alt="browser" className="browser"/>
                                    </div>
                                </div>
                            </div>

                        ) : (
                            <MultiList multiList={multiList} getAvg={getAvg}/>
                        )}
                    </div>
                    <AdBox float={'right'} leftMargin={50}>
                        <GoogleAdvertise
                            style={{
                                display: 'block',
                                minWidth:300,
                                minHeight:600,
                            }}
                            slot={"3597002025"}
                            client={"ca-pub-1406283594335387"}
                            format={"auto"}
                            responsive={"true"}
                        />
                    </AdBox>
                </div>
                <AdWrapper>
                    <AdSection>
                        <GoogleAdvertise
                            style={{
                                display: 'block',
                                width: 900,
                                height: 900,
                            }}
                            format={"autorelaxed"}
                            client={"ca-pub-1406283594335387"}
                            slot={"3966617824"}
                        />
                    </AdSection>
                </AdWrapper>
            </MultiContainer>
        </>
    );
};

export default Multi;
