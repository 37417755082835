// 액션 생성
const GAME_VERSION = 'gameVersion/GAME_VERSION';

// 액션 함수 내보내기
export const changeVersion = (version) => ({ type: GAME_VERSION, version });

// 초기 상태 값
const initialState = {
  version: '',
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GAME_VERSION:
      return {
        version: action.version,
      };
    default:
      return state;
  }
};
export default reducer;
