import React, {useEffect} from 'react';


const GoogleAdvertise = ({
                             className = 'adsbygoogle',
                             client = '',
                             slot = '',
                             format = '',
                             responsive = 'false',
                             style,
                         }) => {


    useEffect(() => {
        if (navigator.userAgent !== 'ReactSnap') {
            const getScript = document.querySelector("script[src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js']");

            if (getScript === null) {
                let myScript = document.createElement("script");
                myScript.setAttribute("src", "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js");
                myScript.setAttribute("data-ad-client", "ca-pub-1406283594335387");
                myScript.setAttribute("async", "true");
                document.body.appendChild(myScript);
            }

        }

        //production인 경우만 광고 요청 //어차피 로컬에서는 광고가 표시되지 않는다
        if (process.env.NODE_ENV === 'production')
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
                console.log('Advertise is pushed');
            } catch (e) {
                console.error('AdvertiseError', e);
            }
    }, []);

    // production이 아닌 경우 대체 컴포넌트 표시
    if (process.env.NODE_ENV !== 'production')
        return (
            <div
                style={{
                    background: '#e9e9e9',
                    color: 'black',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    padding: '16px',
                }}
            >
                광고 표시 영역
            </div>
        );

    return (
        <ins
            className={className}
            style={style}
            data-ad-client={client}
            data-ad-slot={slot}
            data-ad-format={format}
            data-full-width-responsive={responsive}
        />
    );
};

export default GoogleAdvertise;
