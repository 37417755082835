import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {openModal} from '../../store/modules/modal';
import MatchDetail from './MatchDetail';
import {ImageChamp, ImageItem, ImageLane, ImageRune, ImageSpell,} from '../../components';
import {
    calcDuration,
    csPerMinute,
    cutLaneName,
    getGameTime,
    getKda,
    getTimeHMS,
    kdaColor,
    scoreColor,
    tierAvgText,
} from '../../function';
import {MatchItemContainer} from '../../styled/SummonerStyle';
import {ChartScore} from "../charts";
import styled from "styled-components";
import MatchChampImg from "./MathItem/ChampImg";


const ChartScoreDiv = styled.div`
  cursor: pointer;
`;

const ChampImageWrapper = styled.div`
  display: flex;
`;

const ChampImageAbsoluteLevel = styled.div`
  padding: 2px;
  position: absolute;
  display: flex;
  min-width: 2.8ch;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.7);
`;

const LevelText = styled.span`
  font-size: 11px;
  opacity: 1;
  color: #fff;
`;

const MatchItem = ({match, openId, handleMoreInfo, region, currentLang, summoner}) => {
    const {t} = useTranslation();
    const [myMatch, setMyMatch] = useState({});
    const dispatch = useDispatch();
    const [openIndex, setOpenIndex] = useState(0);


    useEffect(() => {
        // 내 경기 정보
        match.match_json.map(
            (item) => Object.keys(item)[0] === summoner.summoner_id && setMyMatch(Object.values(item)[0])
        );
    }, [match, summoner]);


    const onClickGraph = useCallback(() => {
        setOpenIndex(1);
        handleMoreInfo(match.gameId)
    }, [match, handleMoreInfo]);

    const onClickOpen = useCallback(() => {
        setOpenIndex(0);
        handleMoreInfo(match.gameId)
    }, [match, handleMoreInfo])


    return (
        <MatchItemContainer>
            {Object.keys(myMatch).length !== 0 && (
                <div
                    className={`rowContainer ${match.remake ? 'remake' : myMatch.win ? 'win' : 'lose'} ${
                        openId === match.gameId ? 'open' : 'close'
                    }`}
                >
                    <div className="row">
                        <div className="col flex_column0"></div>
                        <div className="col flex_column1">
                            <h5>
                                {match.queue === 'RANKED_SOLO_5x5' && t('summoner.rankedSolo')}
                                {match.queue === 'RANKED_FLEX_SR' && t('summoner.rankedFlex')}
                                {match.queue === 'NORMAL_5V5_BLIND_PICK' && t('summoner.blind')}
                                {match.queue === 'CUSTOM_GAME' && t('summoner.custom')}
                                {match.queue === 'CLASH' && t('summoner.clash')}
                            </h5>
                            <span></span>
                            <p>
                                {getGameTime(
                                    Number(match.date_timestamp) + calcDuration(match.duration),
                                    currentLang
                                )}
                            </p>
                            <dl>
                                <dt>
                  <span>
                    {match.remake
                        ? t('summoner.remake')
                        : myMatch.win
                            ? t('summoner.win')
                            : t('summoner.lose')}
                  </span>
                                    {getTimeHMS(match.duration, 'no', currentLang)}
                                </dt>
                                <dd>
                                    {t('summoner.avg')}
                                    <div>{tierAvgText(match.tier_avg)}</div>
                                </dd>
                            </dl>
                        </div>
                        <div className="col flex_column2">
                            <dl>
                                <dt className={`${myMatch.check_away ? 'away' : 'home'}`}>
                                    <ChampImageWrapper>
                                        <ChampImageAbsoluteLevel>
                                            <LevelText>{myMatch.champLevel}</LevelText></ChampImageAbsoluteLevel>
                                        <MatchChampImg champId={myMatch.championId}/>
                                    </ChampImageWrapper>
                                    <p>
                                        <ImageLane lane={myMatch.lane}/>
                                        <span>{cutLaneName(myMatch.lane)}</span>
                                    </p>
                                </dt>
                                <dd>
                                    <ImageSpell spellId={myMatch.summonerSpellDId} smite={myMatch.smite}/>
                                    <ImageSpell spellId={myMatch.summonerSpellFId} smite={myMatch.smite}/>
                                </dd>
                                <dd>
                                    <ImageRune runeId={myMatch.rune_detail.perk0}/>
                                    <ImageRune runeId={myMatch.rune_detail.perkSubStyle}/>
                                </dd>
                            </dl>
                        </div>
                        <div className="col flex_column3">
                            <div className="cbox">
                                <h5>AI-Score</h5>
                                <h4 onClick={() => dispatch(openModal(true, myMatch, match))}>
                                    {scoreColor(myMatch.ai_score)}
                                </h4>
                                <p>
                                    {myMatch.mvp && <span className="crown mvp"></span>}
                                    {myMatch.ace && <span className="crown ace"></span>}
                                    {match.remake ? (
                                        <>
                                            <i>-</i>
                                        </>
                                    ) : (
                                        <>
                                            {myMatch.rating === 1 ? (
                                                <>
                                                    <i>{`1`}</i>{t('summoner.firstGrade')}
                                                </>
                                            ) : myMatch.rating === 2 ? (
                                                <>
                                                    <i>{`2`}</i>{t('summoner.secondGrade')}
                                                </>
                                            ) : myMatch.rating === 3 ? (
                                                <>
                                                    <i>{`3`}</i>{t('summoner.thirdGrade')}
                                                </>
                                            ) : (
                                                <>
                                                    <i>{myMatch.rating}</i>{t('summoner.grade')}
                                                </>
                                            )}
                                        </>
                                    )}
                                </p>
                            </div>
                            <ChartScoreDiv onClick={onClickGraph}>
                                <ChartScore
                                    data={match.inference_json}
                                    myMatch={myMatch}
                                    width={175}
                                    height={90}
                                    label={true}
                                />
                            </ChartScoreDiv>
                        </div>
                        <div className="col flex_column4">
                            {/*<p className="type1">*/}
                            {/*    Level<span>{myMatch.champLevel}</span>*/}
                            {/*</p>*/}
                            <p className="type2">
                                <span>{myMatch.kills}</span>/<span className="death">{myMatch.deaths}</span>/
                                <span>{myMatch.assists}</span>
                            </p>
                            <p className="type3">
                                {myMatch.deaths === 0 ? (
                                    <span className="kda_color perfect">Perfect</span>
                                ) : (
                                    <>
                                        {kdaColor(getKda(myMatch.kills, myMatch.deaths, myMatch.assists).toFixed(2))}
                                        <span>KDA</span>
                                    </>
                                )}
                            </p>
                            <p className="type4">
                                <span>{myMatch.cs}</span>CS (<i>{csPerMinute(myMatch.cs, match.duration)}</i>/
                                {t('summoner.minute')})
                            </p>
                            <p className="type5">
                                <span>{myMatch.kill_point.toFixed(1)}</span>% {t('summoner.kp')}
                            </p>
                        </div>
                        <div className="col flex_column5">
                            <div className="cbox">
                                <ImageItem itemId={myMatch.item_detail.item0}/>
                                <ImageItem itemId={myMatch.item_detail.item1}/>
                                <ImageItem itemId={myMatch.item_detail.item2}/>
                                <ImageItem itemId={myMatch.item_detail.item6}/>
                                <ImageItem itemId={myMatch.item_detail.item3}/>
                                <ImageItem itemId={myMatch.item_detail.item4}/>
                                <ImageItem itemId={myMatch.item_detail.item5}/>

                                <img
                                    src="/images/btn_item_build_search_w.svg"
                                    className="build_detail"
                                    onClick={() => dispatch(openModal(true, myMatch))}
                                    alt={t('buttons.detail')}
                                />
                            </div>
                            <div className="vision">
                <span>
                  <img src="/images/ico_ward01.svg" alt="구매한 와드 수"/>
                    {myMatch.visionWardsBoughtInGame}
                </span>
                                <span>
                  <img src="/images/ico_ward02.svg" alt="설치한 와드 수"/>
                                    {myMatch.wardsPlaced}
                </span>
                                <span>
                  <img src="/images/ico_ward03.svg" alt="제거한 와드 수"/>
                                    {myMatch.wardsKilled}
                </span>
                            </div>
                        </div>
                        <div className="col flex_column6">
                            <div className="team">
                                {match.match_json.map((item, index) => {
                                    const player = Object.values(item)[0];
                                    return (
                                        index < 5 && (
                                            <div
                                                key={player.frameId}
                                                className={`${
                                                    player.summonerName === myMatch.summonerName ? 'my' : 'player'
                                                }`}
                                            >
                                                <ImageChamp champId={player.championId}/>
                                                <p>
                                                    <Link
                                                        replace
                                                        to={`/summoner/${player.summonerName}/${region}`}
                                                        target="_blank"
                                                        className="common_search"
                                                    >
                                                        {player.summonerName}
                                                    </Link>
                                                </p>
                                            </div>
                                        )
                                    );
                                })}
                            </div>
                            <div className="team">
                                {match.match_json.map((item, index) => {
                                    const player = Object.values(item)[0];
                                    return (
                                        index >= 5 && (
                                            <div
                                                key={player.frameId}
                                                className={`${
                                                    player.summonerName === myMatch.summonerName ? 'my' : 'player'
                                                }`}
                                            >
                                                <ImageChamp champId={player.championId}/>
                                                <p>
                                                    <Link
                                                        replace
                                                        to={`/summoner/${player.summonerName}/${region}`}
                                                        target="_blank"
                                                        className="common_search"
                                                    >

                                                        {player.summonerName}
                                                    </Link>
                                                </p>
                                            </div>
                                        )
                                    );
                                })}
                            </div>
                        </div>
                        <div className="col flex_column7">
                            <button onClick={onClickOpen}>
                                <img src="/images/icon_arrow_b.svg" alt={t('buttons.detail')}/>
                            </button>
                        </div>
                    </div>
                    {openId === match.gameId && <MatchDetail match={match} myMatch={myMatch}
                                                             openIndex={openIndex}
                    />}
                </div>
            )}
        </MatchItemContainer>
    );
};

export default MatchItem;
