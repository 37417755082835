import React from 'react';
import styled from "styled-components";

const ChampionWrapper = styled.div`
  font-weight: 500;
  display: flex;
  flex-direction: ${props => {
    let direction = 'row';
    if (props.reverse) direction = 'row-reverse';
    return direction;
  }};
  justify-content: flex-end;
  margin-bottom: 5px;
`;
const NameText = styled.span`
  font-size: 12px;
`;

const SummonerChampion = ({championName, reverse}) => {
    return (
        <>
            <ChampionWrapper reverse={reverse}>
                <NameText>{championName}</NameText>
                {/*<p className="name" key={idx}>*/}
                {/*<strong>{currentLang === 'KOR' ? champ.champion_name_kr : champ.champion_name_en}</strong>*/}
                {/*</p>*/}
            </ChampionWrapper>
        </>
    );
};

export default SummonerChampion;