import React, {useEffect, useMemo, useRef, useState} from 'react';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import BookmarkList from '../common/BookmarkList';
import SearchList from '../common/SearchList';
import {FloatContainer} from '../../styled/SummonerStyle';
import {GoogleAdvertise} from '../common';
import styled from "styled-components";

const SmallAdWrapper = styled.div`
`;


const Float = () => {
    const {t} = useTranslation();
    const tabSelectorRef = useRef(null);
    const [isFloatOn, setIsFloatOn] = useState(0);
    const [isFixed, setIsFixed] = useState(false);
    const [changeBookmark, setChangeBookmark] = useState([]);
    const currentLang = useSelector((state) => state.translation.lang);

    // 북마크 변경 이벤트
    const handleChangeBookmark = () => {
        setChangeBookmark(!changeBookmark);
    };

    // 스크롤 이벤트
    const throttledScroll = useMemo(
        () =>
            _.throttle(() => {
                window.scrollY >= 170 ? setIsFixed(true) : setIsFixed(false);
                if (!tabSelectorRef.current) return;
                const nextTabnavOn = window.scrollY > tabSelectorRef.current.offsetTop;
                if (nextTabnavOn !== isFloatOn) setIsFloatOn(nextTabnavOn);
            }, 0),
        [isFloatOn]
    );

    useEffect(() => {
        window.addEventListener('scroll', throttledScroll);
        return () => {
            window.removeEventListener('scroll', throttledScroll);
        };
    }, [throttledScroll]);

    return (
        <FloatContainer className={`${isFixed ? 'fixed' : 'not_fixed'}`}>
            <div className="float left">
                <div className="advertising">
                    <GoogleAdvertise
                        style={{
                            display: 'block',
                        }}
                        client="ca-pub-1406283594335387"
                        slot="5807382026"
                        format="auto"
                        responsive="true"
                    />
                </div>
            </div>
            <div className="float right">
                <div className="history">
                    <div className="box">
                        <h4>{t('buttons.recentSearch')}</h4>
                        <SearchList handleChangeBookmark={handleChangeBookmark}/>
                    </div>
                    <div className="box">
                        <h4>{t('buttons.favorite')}</h4>
                        <BookmarkList changeBookmark={changeBookmark}/>

                    </div>
                </div>
                <div className="ai_score">
                    <h3>{t('persist.aiscoreTitle')}</h3>
                    <div className="box">
                        <p>{t('persist.aiscoreText')}</p>
                        {currentLang === 'KOR' ? (
                            <img src="/images/img_info_aiscore_kr.png" alt="AI Score"/>
                        ) : (
                            <img src="/images/img_info_aiscore_en.png" alt="AI Score"/>
                        )}
                    </div>
                </div>
                <SmallAdWrapper>
                    <GoogleAdvertise
                        style={{
                            display: 'inline-block',
                            width: 300,
                            height: 250,
                        }}
                        client="ca-pub-1406283594335387"
                        slot="7574430279"
                    />
                </SmallAdWrapper>
            </div>
        </FloatContainer>
    );
};

export default Float;
