import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {getAIScore, getHighRankChampion, getKda, getLineRank, kdaColor, scoreColor,} from '../../function';
import {SummaryContainer} from '../../styled/SummonerStyle';
import InfoAIScore from "./Info/AIScore";
import InfoAiPrediction from "./Info/AiPrediction";
import MostPickChamp from "./Info/MostPickChamp";
import MostLane from "./Info/MostLane";

// Most 생성자 함수
class Most {
    constructor(games = 0, win = 0, lose = 0, kills = 0, deaths = 0, assists = 0, ai_score = 0) {
        this.games = games;
        this.win = win;
        this.lose = lose;
        this.kills = kills;
        this.deaths = deaths;
        this.assists = assists;
        this.ai_score = ai_score;

        return this;
    }
}


const getdata = (gameArr) => {
    let gameData = {
        win: 0, // 이긴게임
        lose: 0, // 진게임
        rate: 0, // 승률
        kills: 0, // 킬
        deaths: 0, // 데스
        assists: 0, // 어시스트
        kda: 0, // KDA
        lane_per_score: 0, // 라인파워
        ai_score: 0, // AI Score
        ai_score_win: 0, // 이겼을때 AI Score
        ai_score_lose: 0, // 졌을때 AI Score
        rating: 0, // 순위
        champ: [], // 모스트 챔피언
        lane: [], // 모스트 라인
        mostChamp1: new Most(),
        mostChamp2: new Most(),
        mostLane1: new Most(),
        mostLane2: new Most(),
    };

    if (gameArr !== undefined) {
        // 최근 10경기만
        let last10Arr = gameArr.length > 10 ? gameArr.slice(-10) : gameArr;
        for (const match of last10Arr) {
            // 게임전적 합계
            match.win ? gameData.win++ : gameData.lose++;
            match.win
                ? (gameData.ai_score_win += Number(getAIScore(match.minmax_z)))
                : (gameData.ai_score_lose += Number(getAIScore(match.minmax_z)));
            gameData.kills += match.kills;
            gameData.deaths += match.deaths;
            gameData.assists += match.assists;
            gameData.ai_score += Number(getAIScore(match.minmax_z));
            gameData.rating += match.rating;
            gameData.lane_per_score += match.lane_per_score;

            // 모스트 챔피언/라인
            gameData.champ.push(match.championId);
            gameData.lane.push(match.lane);
        }

        // 게임전적 평균
        gameData.rate = parseFloat(((gameData.win / last10Arr.length) * 100).toFixed(0));
        gameData.kills = parseFloat((gameData.kills / last10Arr.length).toFixed(1));
        gameData.deaths = parseFloat((gameData.deaths / last10Arr.length).toFixed(1));
        gameData.assists = parseFloat((gameData.assists / last10Arr.length).toFixed(1));
        gameData.kda = getKda(gameData.kills, gameData.deaths, gameData.assists);
        gameData.ai_score = parseFloat((gameData.ai_score / last10Arr.length).toFixed(0));
        gameData.ai_score_win = parseFloat((gameData.ai_score_win / gameData.win).toFixed(0));
        gameData.ai_score_lose = parseFloat((gameData.ai_score_lose / gameData.lose).toFixed(0));
        gameData.rating = parseFloat((gameData.rating / last10Arr.length).toFixed(1));
        gameData.lane_per_score = parseFloat((gameData.lane_per_score / last10Arr.length).toFixed(1));

        // 모스트 챔피언/라인 평균
        gameData.champ = getHighRankChampion(last10Arr);
        gameData.lane = getLineRank(last10Arr);


        for (const match of last10Arr) {
            // 모스트 챔피언 1
            if (match.championId === Number(gameData.champ[0])) {
                gameData.mostChamp1.championId = match.championId;
                // 게임수
                gameData.mostChamp1.games++;
                // 승패
                match.win ? gameData.mostChamp1.win++ : gameData.mostChamp1.lose++;
                // KDA
                gameData.mostChamp1.kills += match.kills;
                gameData.mostChamp1.deaths += match.deaths;
                gameData.mostChamp1.assists += match.assists;
                // AI Score
                gameData.mostChamp1.ai_score += Number(getAIScore(match.minmax_z));
            }
            // 모스트 챔피언 2

            if (match.championId === Number(gameData.champ[1])) {
                gameData.mostChamp2.championId = match.championId;
                // 게임수
                gameData.mostChamp2.games++;
                // 승패
                match.win ? gameData.mostChamp2.win++ : gameData.mostChamp2.lose++;
                // KDA
                gameData.mostChamp2.kills += match.kills;
                gameData.mostChamp2.deaths += match.deaths;
                gameData.mostChamp2.assists += match.assists;
                // AI Score
                gameData.mostChamp2.ai_score += Number(getAIScore(match.minmax_z));
            }
            // 모스트 라인 1
            if (match.lane === gameData.lane[0]) {
                gameData.mostLane1.lane = match.lane;
                // 게임수
                gameData.mostLane1.games++;
                // 승패
                match.win ? gameData.mostLane1.win++ : gameData.mostLane1.lose++;
                // KDA
                gameData.mostLane1.kills += match.kills;
                gameData.mostLane1.deaths += match.deaths;
                gameData.mostLane1.assists += match.assists;
                // AI Score
                gameData.mostLane1.ai_score += Number(getAIScore(match.minmax_z));
            }
            // 모스트 라인 2
            if (match.lane === gameData.lane[1]) {
                gameData.mostLane2.lane = match.lane;
                // 게임수
                gameData.mostLane2.games++;
                // 승패
                match.win ? gameData.mostLane2.win++ : gameData.mostLane2.lose++;
                // KDA
                gameData.mostLane2.kills += match.kills;
                gameData.mostLane2.deaths += match.deaths;
                gameData.mostLane2.assists += match.assists;
                // AI Score
                gameData.mostLane2.ai_score += Number(getAIScore(match.minmax_z));
            }
        }
        gameData.mostChamp1.rate = parseFloat(
            ((gameData.mostChamp1.win / gameData.mostChamp1.games) * 100).toFixed(0)
        );
        gameData.mostChamp2.rate = parseFloat(
            ((gameData.mostChamp2.win / gameData.mostChamp2.games) * 100).toFixed(0)
        );
        gameData.mostLane1.rate = parseFloat(
            ((gameData.mostLane1.win / gameData.mostLane1.games) * 100).toFixed(0)
        );
        gameData.mostLane2.rate = parseFloat(
            ((gameData.mostLane2.win / gameData.mostLane2.games) * 100).toFixed(0)
        );
        gameData.mostChamp1.ai_score = parseFloat(
            (gameData.mostChamp1.ai_score / gameData.mostChamp1.games).toFixed(0)
        );
        gameData.mostChamp2.ai_score = parseFloat(
            (gameData.mostChamp2.ai_score / gameData.mostChamp2.games).toFixed(0)
        );
        gameData.mostLane1.ai_score = parseFloat(
            (gameData.mostLane1.ai_score / gameData.mostLane1.games).toFixed(0)
        );
        gameData.mostLane2.ai_score = parseFloat(
            (gameData.mostLane2.ai_score / gameData.mostLane2.games).toFixed(0)
        );
        gameData.mostChamp1.kda = parseFloat(
            getKda(gameData.mostChamp1.kills, gameData.mostChamp1.deaths, gameData.mostChamp1.assists)
        );
        gameData.mostChamp2.kda = parseFloat(
            getKda(gameData.mostChamp2.kills, gameData.mostChamp2.deaths, gameData.mostChamp2.assists)
        );
        gameData.mostLane1.kda = parseFloat(
            getKda(gameData.mostLane1.kills, gameData.mostLane1.deaths, gameData.mostLane1.assists)
        );
        gameData.mostLane2.kda = parseFloat(
            getKda(gameData.mostLane2.kills, gameData.mostLane2.deaths, gameData.mostLane2.assists)
        );
    }

    if (gameData.mostChamp1.games === gameData.mostChamp2.games) {
        if (gameData.mostChamp1.ai_score < gameData.mostChamp2.ai_score) {
            const copyData = {...gameData.mostChamp1};
            gameData.mostChamp1 = {...gameData.mostChamp2};
            gameData.mostChamp2 = {...copyData};
        }
    }

    return gameData;
};





const Summary = ({data, queueNum, summoner, matchInfo}) => {
    const {t} = useTranslation();
    const {gameArr} = data;
    const [summary, setSummary] = useState(gameArr.length > 0 ? getdata(gameArr) : {});


    useEffect(() => {
        if(data.gameArr.length > 0) {
            setSummary(getdata(gameArr));
        }
    },[data])


    return (
        <SummaryContainer>
            {summary.ai_score && !isNaN(summary.ai_score) ? (
                <>
                    <div className="row">
                        <div className="col c1">
                            <h5>{t('summoner.winrate')}</h5>
                            <div className="mi">
                                {scoreColor(summary.rate)}
                                <span className="small"> %</span>
                            </div>
                            <div className="sb">
                                <p>
                                    {summary.win} <span className="small">{t('summoner.contractionWin')}</span>
                                </p>
                                <p>
                                    {summary.lose} <span className="small">{t('summoner.contractionLose')}</span>
                                </p>
                            </div>
                        </div>
                        <div className="col c2">
                            <h5>KDA</h5>
                            <div className="mi">{kdaColor(summary.kda.toFixed(2))}</div>
                            <div className="sb">
                                <p>
                                    {summary.kills.toFixed(1)} <span className="small">/</span>
                                </p>
                                <p className="death">
                                    {summary.deaths.toFixed(1)} <span className="small">/</span>
                                </p>
                                <p>{summary.assists.toFixed(1)}</p>
                            </div>
                        </div>
                        <div className="col c3">
                            <InfoAiPrediction
                                matchInfo={matchInfo}
                                queueNum={queueNum}
                                summoner={summoner}
                                summary={summary}
                            />
                        </div>
                        <div className="col c4">
                            <InfoAIScore
                            />
                            <div className="aiscore">
                                <div>
                                    <p>

                                        {summary.win}
                                        {t('summoner.contractionWin')}
                                        {isNaN(summary.ai_score_win) ? scoreColor(0) : scoreColor(summary.ai_score_win)}
                                    </p>
                                    <p>
                                        {summary.lose}
                                        {t('summoner.contractionLose')}
                                        {isNaN(summary.ai_score_lose)
                                            ? scoreColor(0)
                                            : scoreColor(summary.ai_score_lose)}
                                    </p>
                                    <p className="rating">
                                        {t('summoner.avgRank')} #{summary.rating}
                                    </p>
                                </div>
                                <strong>{scoreColor(summary.ai_score)}</strong>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className={"col c5"}>
                            <MostPickChamp
                                queueNum={queueNum}
                                summoner={summoner}
                                summary={summary}
                            />
                        </div>
                        <div className="col c6">
                            <MostLane
                                queueNum={queueNum}
                                summoner={summoner}
                                summary={summary}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="no_data">{t('notice.noGame')}</div>
                </>
            )}
        </SummaryContainer>
    );
};

export default React.memo(Summary);
