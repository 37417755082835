import React from 'react';
import {kdaColor, scoreColor} from "../../../../function";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: ${props => {
    let direction = 'row';
    if (props.reverse) direction = 'row-reverse';
    return direction;
  }};
`;

const KdaText = styled.span`
  font-size: 12px;
`;

const AiText = styled.span`
  font-size: 12px;
  font-weight: bold;
`;

const MarginbBox = styled.div`
  margin-right: ${props => {
    let right = 0;
    if (props.reverse) right = '6px';
    return right
  }};
  margin-left: ${props => {
    let left = '6px';
    if (props.reverse) left = 0;
    return left
  }};;
`;
const SeasonScore = ({kda, seasonAiScore, reverse}) => {


    return (
        <>
            <Wrapper
                reverse={reverse}
            >
                <MarginbBox
                    reverse={reverse}
                >
                    <KdaText>{kdaColor(kda)}</KdaText>
                </MarginbBox>
                <MarginbBox
                    reverse={reverse}
                >
                    <AiText>{scoreColor(seasonAiScore)}</AiText>
                </MarginbBox>
            </Wrapper>
        </>
    );
};

export default SeasonScore;