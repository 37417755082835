import React from 'react';
import styled from "styled-components";
import {getAiPrediction, scoreColor, tierBadge} from "../../../../function";

const MostContentWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ContentImage = styled.div`
  margin-right: 0.4rem;
`;

const BadgeAndWinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.4rem;
  min-width: 3rem;
`;

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const AiScoreText = styled.span`
  font-size: 20px;
  font-weight: bold;
  font-family: Noto Sans KR;
`;

const MarginDiv = styled.div`
  display: inline-flex;
  margin-right: 3px;
`;

const WinAndLostDiv = styled.div`
  display: flex;
  flex: 1 0 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0.1rem 0.1rem 0;
`;

const RateText = styled.span`
  font-size: 12px;
  color: #313131;
`;

const TransText = styled.span`
  color: #313131;
  opacity: 0.6;
`;


const MostContent = ({ImageComponent, queueNum, summoner, aiScore, win, lost, t}) => {

    return (
        <>
            <MostContentWrapper>
                <ContentImage>
                    {ImageComponent && <ImageComponent/>}
                </ContentImage>
                <BadgeAndWinWrapper>
                    <ScoreWrapper>
                        {Object.keys(summoner.rank_json).length > 0 &&
                        (
                            <MarginDiv>
                                {((queueNum === 0 || queueNum === 420) && summoner.rank_json.RANKED_SOLO_5x5 !== undefined) && tierBadge(getAiPrediction(summoner.rank_json.RANKED_SOLO_5x5, aiScore).txt)}
                                {queueNum === 440 && summoner.rank_json.RANKED_FLEX_SR !== undefined && tierBadge(getAiPrediction(summoner.rank_json.RANKED_FLEX_SR, aiScore).txt)}
                            </MarginDiv>
                        )
                        }
                        <AiScoreText>{scoreColor(aiScore)}</AiScoreText>

                    </ScoreWrapper>
                    <WinAndLostDiv>
                        <RateText>{win} <TransText>{t('summoner.contractionWin')}</TransText></RateText>
                        <RateText>{lost} <TransText>{t('summoner.contractionLose')}</TransText></RateText>
                    </WinAndLostDiv>
                </BadgeAndWinWrapper>
            </MostContentWrapper>
        </>
    )
        ;
};

export default MostContent;