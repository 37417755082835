import React from 'react';
import {ImageLane} from "../../../common";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import SimpleTooltip from "../../../common/SimpleTooltip";
import MostContent from "../MostContent";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
`;

const HeaderText = styled.h5`

`;

const TooltipWrapper = styled.div`
  margin-left: 5px;
`;


const InfoMostLane = ({summary, queueNum, summoner}) => {
    const {t} = useTranslation();
    return (<>
        <Wrapper>
            <HeaderText>{t('summoner.positionTierEvalution')}</HeaderText>
            <TooltipWrapper>
                <SimpleTooltip
                    imgSrc={'/images/list_btn_tip_s_w.svg'}
                    tooltipText={t('summoner.positionTierEvalutionExplain')}
                />
            </TooltipWrapper>
        </Wrapper>
        <ContentWrapper>
            {summary.mostLane1.games !== 0 && (
                <MostContent
                    t={t}
                    lost={summary.mostLane1.lose}
                    win={summary.mostLane1.win}
                    aiScore={summary.mostLane1.ai_score}
                    queueNum={queueNum}
                    summoner={summoner}
                    ImageComponent={() => <ImageLane
                        lane={summary.mostLane1.lane}
                    />}
                />
            )}
            {summary.mostLane2.games !== 0 && (<MostContent
                t={t}
                lost={summary.mostLane2.lose}
                win={summary.mostLane2.win}
                aiScore={summary.mostLane2.ai_score}
                queueNum={queueNum}
                summoner={summoner}
                ImageComponent={() => <ImageLane
                    lane={summary.mostLane2.lane}
                />}
            />)}
        </ContentWrapper>
    </>);
};

export default InfoMostLane;