import React from 'react';
import SummonerChampion from "../SummonerChampion";
import SummonerGameCount from "../SummonerGameCount";
import SeasonScore from "../SeasonScore";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const ProfileWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
`;

const SummonerProfile = ({state, gameInfo, reverse}) => {
    const {t} = useTranslation();
    const champNames = useSelector((state) => state.imageStore.champions);
    const currentLang = useSelector((state) => state.translation.lang);


    return (
        <ProfileWrapper>
            {champNames.map(
                (champ, idx) =>
                    Number(champ.championId) === gameInfo.championId && (
                        <SummonerChampion
                            reverse={reverse}
                            championName={currentLang === 'KOR' ? champ.champion_name_kr : champ.champion_name_en}
                        />
                    )
            )}
            <SummonerGameCount
                reverse={reverse}
                gamesTranslation={t('summoner.games')}
                scorePercent={state?.this_champion_stat.season_winrate.toFixed(1)}
                seasonGames={state?.this_champion_stat.season_games}
            />
            <SeasonScore
                reverse={reverse}
                kda={state?.this_champion_stat.season_kda.toFixed(2)}
                seasonAiScore={state?.this_champion_stat.season_ai_score}
            />
        </ProfileWrapper>
    );
};

export default SummonerProfile;