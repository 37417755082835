import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import {LANGUAGE_FOR_OS} from './types';
import translationEn from './locales/translation.en';
import translationKo from './locales/translation.ko';

const resources = {
    [LANGUAGE_FOR_OS.KO]: {
        translation: translationKo,
    }, [LANGUAGE_FOR_OS.KOKR]: {
        translation: translationKo,
    }, [LANGUAGE_FOR_OS.EN]: {
        translation: translationEn,
    },

};

const init = (lang) => {

    if(lang !== undefined) {
        i18n
            .use(initReactI18next)
            .init({
                lng: lang,
                initImmediate:false,
                resources: resources,
                fallbackLng: 'en-US',//
                debug: false,
                defaultNS: 'translation',
                ns: 'translation', // keySeparator: '.', // we do not use keys in form messages.welcome
                keySeparator: '.',
                react: {
                    useSuspense: false,
                },
                interpolation: {
                    escapeValue: false, // not needed for react as it escapes by default
                },
                detection: {order: ['path', 'navigator']},
            });
    }

};

export default init;
