import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {ChartAi} from '../../components';
import Summary from './Summary';
import {getSortedArray, getSortedObject} from '../../function';
import {OverviewContainer} from '../../styled/SummonerStyle';
import SearchChampions from "./Overview/SearchChampion";

const getData = (matchInfo,summoner) => {
    let xAxisArr = [];
    let scoreArr = [];
    let gameArr = [];
    let winloseArr = [];
    let ratingArr = [];

    for (const match of matchInfo) {
        // AI스코어순 rating 추가
        let aiArr = getSortedObject(getSortedArray(match.match_json), 'minmax_z', 'descending'); // AI스코어순 정렬
        let winArr = [];
        let loseArr = [];
        for (let i = 0; i < aiArr.length; i++) {
            aiArr[i].rating = i + 1;
            if (aiArr[i].win) winArr.push(aiArr[i].minmax_z);
            else loseArr.push(aiArr[i].minmax_z);
        }

        // 경기 찾기
        for (const item of match.match_json) {
            for (const key in item) {
                // mvp, ace, ai_score
                if (!match.remake) {
                    if (item[key].minmax_z === winArr[0] && item[key].win) item[key].mvp = true;
                    if (item[key].minmax_z === loseArr[0] && !item[key].win) item[key].ace = true;
                    item[key].ai_score = Math.round(item[key].minmax_z * 100);
                }

                // 내 경기
                if (key === summoner.summoner_id) {
                    // winloseArr배열 만들기
                    winloseArr.push(item[key].win);

                    // ratingArr배열 만들기
                    ratingArr.push(item[key].rating);

                    // scoreArr배열 만들기
                    if (item[key].minmax_z === undefined) scoreArr.push(0);
                    else scoreArr.push(item[key].minmax_z * 100);

                    // gameArr배열 만들기
                    if (!match.remake) {
                        gameArr.push(item[key]);
                    }
                }
            }
        }

        // xAxisArr배열 만들기
        xAxisArr.push(new Date(match.creation_date));
    }
    scoreArr.reverse();
    xAxisArr.reverse();
    gameArr.reverse();
    winloseArr.reverse();
    ratingArr.reverse();

    return {xAxisArr, scoreArr, gameArr, winloseArr, ratingArr};
};





const Overview = ({matchInfo, handleQueueFilter, handleChampFilter, queueNum}) => {
    const {t} = useTranslation();
    const summoner = useSelector((state) => state.summoner.summoner);
    const [data, setData] = useState(getData(matchInfo,summoner));
    const [isOpend, setIsOpend] = useState(false);


    // 자동완성 닫기
    const handleClickOutside = useCallback(
        (e) => {
            if (isOpend && (!e.current || !e.current.contains(e.target))) setIsOpend(false);
        },
        [isOpend]
    );

    useEffect(() => {
        setData(getData(matchInfo,summoner))
    },[matchInfo,summoner])


    useEffect(() => {

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [ handleClickOutside, matchInfo]);

    return (
        <OverviewContainer>
            <div className="o__menu">
                <nav>
                    <button
                        type="button"
                        className={`${queueNum === 0 ? 'selected' : 'btn'}`}
                        onClick={() => handleQueueFilter(0)}
                    >
                        {t('tabs.total')}
                    </button>
                    <button
                        type="button"
                        className={`${queueNum === 420 ? 'selected' : 'btn'}`}
                        onClick={() => handleQueueFilter(420)}
                    >
                        {t('tabs.rankedSolo')}
                    </button>
                    <button
                        type="button"
                        className={`${queueNum === 440 ? 'selected' : 'btn'}`}
                        onClick={() => handleQueueFilter(440)}
                    >
                        {t('tabs.rankedFlex')}
                    </button>
                    <button
                        type="button"
                        className={`${queueNum === 430 ? 'selected' : 'btn'}`}
                        onClick={() => handleQueueFilter(430)}
                    >
                        {t('tabs.normal')}
                    </button>
                    <button
                        type="button"
                        className={`${queueNum === 100 ? 'selected' : 'btn'}`}
                        onClick={() => handleQueueFilter(100)}
                    >
                        {t('tabs.custom')}
                    </button>
                </nav>
                <div className="champ">
                    <SearchChampions
                        handleChampFilter={handleChampFilter}
                        t={t}
                    />
                </div>
            </div>
            <div className="o__info">
                {matchInfo.length !== 0 ? (
                    <>
                        <Summary data={data} queueNum={queueNum} summoner={summoner} matchInfo={matchInfo}/>
                        <ChartAi data={data}/>
                    </>
                ) : (
                    <div className="no_data">{t('notice.noGame')}</div>
                )}
            </div>
        </OverviewContainer>
    );
};

export default React.memo(Overview);
