import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {FooterContainer} from '../../styled/CommonStyle';

const Footer = () => {
    const {t} = useTranslation();
    const [isToggled, setIsToggled] = useState(false);

    // 자동완성 닫기
    const handleClickOutside = useCallback(
        (e) => {
            if (isToggled && (!e.current || !e.current.contains(e.target))) setIsToggled(false);
        },
        [isToggled]
    );

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <FooterContainer>
            <div className="f__copy">
                <h2>
                    <img src="/images/icon_gameeye_logo_w.svg" alt="GAME EYE"/>
                </h2>
                <dl>
                    <dt>Copyright © GameEye Corp. All rights reserved.</dt>
                    <dd>
                        deeplol.gg isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of
                        Riot Games or anyone officially involved in producing or managing League of Legends.
                        <br/>
                        League of Legends and Riot Games are trademarks or registered trademarks of Riot Games,
                        Inc. League of Legends © Riot Games, Inc.
                        <br/>
                        <br/>
                        (주)게임아이 대표: 윤덕진 사업자등록번호: 715-81-01319
                    </dd>
                </dl>
            </div>
            <div className="f__menu">
                <nav>
                    <a href="https://blog.deeplol.gg/" target="_blank" rel="noreferrer">
                        ABOUT
                    </a>
                    <Link to="/">{t('footer.navInq')}</Link>
                </nav>
                <div className="toggle">
          <span className="toggle__btn" onClick={() => setIsToggled(!isToggled)}>
            Family Site
          </span>
                    {isToggled && (
                        <ul className="toggle__list">
                            <li className="toggle__list-item">
                                <a href="https://pro.deeplol.gg/" target="_blank" rel="noreferrer">
                                    {t('footer.familyPro')}
                                </a>
                            </li>
                        </ul>
                    )}
                </div>
            </div>
        </FooterContainer>
    );
};

export default Footer;
