import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import TooltipWrapper from "../../../common/TooltipWrapper";
import styled from "styled-components";

const CampImg = styled.img`
  margin: -3px;
  max-width: 60px;
`;

const MatchChampImg = ({champId}) => {
    const version = useSelector((state) => state.gameVersion.version);
    const dataSelector = useSelector((state) => state.imageStore.champions);
    const [imgObj, setImgObj] = useState({});
    const currentLang = useSelector((state) => state.translation.lang);

    useEffect(() => {
        if (dataSelector.length !== 0) {
            if (dataSelector.filter((item) => item.championId === `${champId}`).length !== 0) {
                setImgObj(dataSelector.find((item) => item.championId === `${champId}`));
            } else {
                setImgObj('');
            }
        }
        return () => {
            setImgObj('');
        };
    }, [champId, dataSelector]);

    return (
        <>
            {Object.keys(imgObj).length > 0 ? (
                <TooltipWrapper
                    fontweight={'bold'}
                    textColor={'#17c791'}
                    tooltipText={currentLang === 'KOR' ? imgObj.champion_name_kr : imgObj.champion_name_en}
                >
                    <div style={{overflow: 'hidden',}}>
                        <CampImg
                            src={`${process.env.REACT_APP_IMG}${version}/img/champion/${imgObj.image_name}`}
                            alt={imgObj.image_name}
                        />
                    </div>
                </TooltipWrapper>
            ) : (
                <div className="non_image">
                    <img src="/images/icon_non_item.svg" className="imgChamp" alt={imgObj}/>
                </div>
            )}
        </>
    );
}

export default MatchChampImg;