import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import ChampTable from './ChampTable';
import {Loading} from '../../components';
import {getSortedObject, sortChampData} from '../../function';
import {ChampContainer} from '../../styled/SummonerStyle';
import {GET_CHAMP_INFO_REQUEST} from "../../store/reducer/summoner";
import Cateogry from "../common/Category";
import styled from "styled-components";
import ChampInfo from "./Champ/ChampInfo";


const CategoryWrapper = styled.div`
  padding: 12.5px 20px 7.5px;
  border-bottom-width: 1px;
  border-bottom-color: #A5BED2;
  border-style: solid;
`;

const InfoWrapper = styled.div`
  margin: 10px 20px 0px;
`;

const Champ = ({region}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [position, setPosition] = useState('without_position');
    const [gameType, setGameType] = useState({value: 'total'});
    const [currentOrder, setCurrentOrder] = useState('descending');
    const [positionLane, setPositionLane] = useState('All');
    const [openId, setOpenId] = useState(0);
    const champions = useSelector((state) => state.imageStore.champions);
    const [categorySelected, setCategorySelected] = useState(0);
    const currentLang = useSelector((state) => state.translation.lang);
    const initialSort = [
        {
            value: 'games',
            sortType: false,
        },
        {
            value: 'winrate',
            sortType: false,
        },
        {
            value: currentLang === 'KOR' ? 'champion_name_kr' : 'champion_name_en',
            sortType: true,
        },
        {
            value: currentLang === 'KOR' ? 'enemy_name_kr' : 'enemy_name_en',
            sortType: true,
        }
    ]
    const [sortOrder, setSortOrder] = useState({
        games: {
            value: 'games',
            sortType: false,
        },
        winrate: {
            value: 'winrate',
            sortType: false,
        },
        champions: {
            value: currentLang === 'KOR' ? 'champion_name_kr' : 'champion_name_en',
            sortType: true,
        },
        kda: {
            value: 'kda',
            sortType: true,
        },
        cs_per_m: {
            value: 'cs_per_m',
            sortType: true,
        },
        gold_diff_15_per_g: {
            value: 'gold_diff_15_per_g',
            sortType: true,
        },
        dpm: {
            value: 'dpm',
            sortType: true,
        },
        kill_point_per_g: {
            value: 'kill_point_per_g',
            sortType: true,
        },
        d_score_win_per_g: {
            value: 'd_score_win_per_g',
            sortType: true,
        },
        d_score_loss_per_g: {
            value: 'd_score_loss_per_g',
            sortType: true,
        },
        d_score_per_g: {
            value: 'd_score_per_g',
            sortType: true,
        }
    })
    const [champName, setChampName] = useState('');
    const {
        withOrigin,
        withOutOrigin,
        getChampInfoDone,
    } = useSelector((state) => state.summoner);


    const initialSortOrder = useCallback(() => {
        setSortOrder(sortOrder)
    },[])

    const initialStates = useCallback(() => {
        setData([]);
        setChampName('');
        setOpenId(0);
        setPositionLane('All');
        setGameType({value: 'total'});
        setPosition('without_position');
        setSortOrder(sortOrder);
    }, [])

    const champCategory = [{
        title: t('champions.category.myChamp'),
        key: 'myChamp',
        info: t('champions.info.myChamp'),
        extra: t('champions.info.myChampExtra')
    }, {
        title: t('champions.category.enemyChamp'),
        key: 'enemyChamp',
        info: t('champions.info.enemyChamp'),
        extra: t('champions.info.enemyChampExtra')
    }]


    const onClickCategory = useCallback((index) => {
        setCategorySelected(index);
        initialStates()
        dispatch({
            type: GET_CHAMP_INFO_REQUEST,
            data: {
                region: region,
                category: index,
            },
        })

    }, [])


    const setOpenIdAndData = useCallback((value) => {
        setData(value);
        if (value.length > 0) {
            setOpenId(value[0].cid);
        } else {
            setOpenId(0)
        }
    }, [])

    useEffect(() => {
        if (getChampInfoDone) {
            setOpenIdAndData(sortChampData(withOutOrigin[gameType.value], initialSort));
        }
    }, [getChampInfoDone])


    //??????????????????????????????????
    // 챔피언 검색
    const handleChampName = async (e) => {
        const {value} = e.target;
        setChampName(value);


        if (value.length !== 0) {
            if (champions.length > 0) {
                const regExp = new RegExp(`${value}`);
                const filtered = champions.filter((data, index) => {
                    return regExp.test(data.champion_name_kr) || regExp.test(data.champion_name_en);
                });

                if (filtered.length > 0) {
                    const mappedArray = filtered.map((data, index) => data.championId);
                    const searchData = position === 'without_position'
                        ? withOutOrigin[gameType.value].filter((e) => mappedArray.includes(e.championId))
                        : data.filter((e) => mappedArray.includes(e.championId));

                    setOpenIdAndData(searchData);
                }
            }

        } else {
            let filtered = position === 'without_position' ? withOutOrigin[gameType.value] : withOrigin[gameType.value];
            setOpenIdAndData(filtered);
            setPositionLane('All');
        }
    };


    // 포지션 구분
    const handlePosition = (flag) => {
        if (position !== flag) {
            if (flag === 'without_position') {
                setPosition('without_position');
                setPositionLane('All');
                setOpenIdAndData(sortChampData(withOutOrigin[gameType.value], initialSort));
            } else {
                setPosition('with_position');
                setOpenIdAndData(sortChampData(withOrigin[gameType.value], initialSort));
            }
            initialSortOrder()
        }

    };

    // 포지션 선택
    const handlePositionDetail = (params) => {
        if (params !== 'All') {
            const arrFilter = withOrigin[gameType.value].filter((item) => {
                return (item.lane).toLowerCase() === params.toLowerCase();
            });
            setOpenIdAndData(sortChampData(arrFilter, initialSort));
        } else {
            setOpenIdAndData(sortChampData(withOrigin[gameType.value], initialSort));
        }
        initialSortOrder()
        setPositionLane(params);
    };

    // 랭크타입 구분
    const handleGameType = (e) => {
        const {value} = e.target;
        setGameType({value});
        initialSortOrder()
        if (position !== "without_position") {
            if (positionLane !== 'All') {
                const arrFilter = withOrigin[value].filter((item) => {
                    return (item.lane).toLowerCase() === positionLane.toLowerCase();
                });
                setOpenIdAndData(sortChampData(arrFilter, initialSort));
            } else {

                setOpenIdAndData(sortChampData(withOrigin[value], initialSort));
            }
        } else {
            setOpenIdAndData(sortChampData(withOutOrigin[value], initialSort))
        }
    };


    // 정렬
    const handleSort = (params) => {
        // const filterCondition = ['games','winrate',currentLang === 'KOR' ? 'champion_name_kr' : 'champion_name_en'].filter((data) => data !== params);
        const copyOrder = {...sortOrder};
        copyOrder[params] = {
            value: copyOrder[params].value,
            sortType: !copyOrder[params].sortType,
        }
        const sortedOrder = Object.values(sortOrder).filter((data) => data.value !== sortOrder[params].value);
        const sliced = sortedOrder.splice(0, 3);
        sliced.unshift(copyOrder[params]);
        const result = sortChampData(data, sliced);
        setOpenIdAndData(result)
        setSortOrder(copyOrder);
    };

    // 리셋
    const handleReset = () => {
        setPosition('without_position');
        setGameType({value: 'total'});
        initialSortOrder()
        setOpenIdAndData(sortChampData(data, initialSort));
        setPositionLane('All');
    };


    useEffect(() => {
        dispatch({
            type: GET_CHAMP_INFO_REQUEST,
            data: {
                region: region,
                category: 0,
            }
        })
    }, [region])


    return (
        <ChampContainer>
            <CategoryWrapper>
                <Cateogry
                    onClickCategory={onClickCategory}
                    selected={categorySelected}
                    list={champCategory}
                />
            </CategoryWrapper>
            <InfoWrapper>
                <ChampInfo
                    extra={champCategory[categorySelected].extra}
                    infoText={champCategory[categorySelected].info}
                />
            </InfoWrapper>
            {getChampInfoDone ? (
                <>
                    <div className="filter">
                        <div className="rank_type">
                            <select value={gameType.value} onChange={handleGameType}>
                                <option value="total">{t('champions.filter.total')}</option>
                                <option value="r_solo">{t('champions.filter.r_solo')}</option>
                                <option value="r_flex">{t('champions.filter.r_flex')}</option>
                            </select>
                        </div>
                        <div className="position_type">
                            <div
                                className={`btn ${position === 'without_position' && 'selected'}`}
                                onClick={() => handlePosition('without_position')}
                            >
                                <p>{t('champions.filter.all')}</p>
                            </div>
                            <div
                                className={`btn ${position === 'with_position' && 'selected'}`}
                                onClick={() => handlePosition('with_position')}
                            >
                                <p>{t('champions.filter.each')}</p>
                                <div className="selector">
                  <span
                      className={`${positionLane === 'All' && 'selected'}`}
                      onClick={() => handlePositionDetail('All')}
                  >
                    ALL
                  </span>
                                    <span
                                        className={`${positionLane === 'Top' && 'selected'}`}
                                        onClick={() => handlePositionDetail('Top')}
                                    >
                    <img src="/images/icon_s_position_top_w.svg" alt="top"/>
                  </span>
                                    <span
                                        className={`${positionLane === 'Jungle' && 'selected'}`}
                                        onClick={() => handlePositionDetail('Jungle')}
                                    >
                    <img src="/images/icon_s_position_jungle_w.svg" alt="jug"/>
                  </span>
                                    <span
                                        className={`${positionLane === 'Middle' && 'selected'}`}
                                        onClick={() => handlePositionDetail('Middle')}
                                    >
                    <img src="/images/icon_s_position_middle_w.svg" alt="mid"/>
                  </span>
                                    <span
                                        className={`${positionLane === 'Bot' && 'selected'}`}
                                        onClick={() => handlePositionDetail('Bot')}
                                    >
                    <img src="/images/icon_s_position_bot_w.svg" alt="bot"/>
                  </span>
                                    <span
                                        className={`${positionLane === 'Supporter' && 'selected'}`}
                                        onClick={() => handlePositionDetail('Supporter')}
                                    >
                    <img src="/images/icon_s_position_supporter_w.svg" alt="sup"/>
                  </span>
                                </div>
                            </div>
                        </div>
                        <div className="champ_search">
                            <input
                                type="text"
                                value={champName}
                                onChange={handleChampName}
                                placeholder={t('champions.searchChampion')}
                            />
                        </div>
                        {/* <div className="season_type">
              <select name="" id="">
                <option value="">시즌2021</option>
              </select>
            </div> */}
                        <div className="reset">
                            <button onClick={handleReset}>{t('champions.reset')}</button>
                        </div>
                    </div>
                    {data.length > 0 && <ChampTable
                        initialSort={initialSort}
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}
                        setOpenId={setOpenId}
                        openId={openId}
                        data={data} handleSort={handleSort} currentOrder={currentOrder}/>}
                </>
            ) : (
                <Loading/>
            )}
        </ChampContainer>
    );
};

export default Champ;
