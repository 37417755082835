
//MUST BE SINGLETON PATTERN

import socketio from "socket.io-client";


const socketNamespace = `${process.env.REACT_APP_API}update_match`

class SocketClass {
    currentSocket;

    connectTo() {
        return this.currentSocket = socketio.connect(
            socketNamespace,
            {
                transports: ['websocket'],
                upgrade: false,
                secure: true,
            },
            {forceNew: true}
        );
    }
    disconnect() {
        return this.currentSocket().disconnect();
    }


    socketOn(summonerRegion,summoner_id,offset,socket,queue) {
        return socket.on('connect', () => {
            socket.emit('my event', {
                data: 'User Connected',
                summoner_id: summoner_id,
                region: summonerRegion,
                season: 13,
                count: 0,
                offset: 0,
            });
        })
    }

    socketMyrespons(socket,callback,) {
        socket.on('my response', (msg) => {
            // 받은 데이터에 summoner_id 값이 있으면, 다시 서버에 진행정도 확인 요청
            if (msg.hasOwnProperty('summoner_id')) {
                socket.emit('my event', {
                    summoner_id: msg.summoner_id,
                    summoner_key: msg.summoner_key,
                    region: msg.region,
                    match_len: msg.match_len,
                    finish_check: 100,
                    finish_num: 0,
                    count: msg.count,
                });
            }
            callback(msg);
        })
    }
}

const useSocket = new SocketClass();
export default useSocket;
