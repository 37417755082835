import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// import './index.css';
import App from './App';
import TagManager from 'react-gtm-module';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./store/reducer/store";

// GTM
const tagManagerArgs = {
    gtmId: 'GTM-MNLW34G',
};
TagManager.initialize(tagManagerArgs);


// react-snap (pre-rendering)


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {/*<I18nextProvider i18n={window.navigator.language && i18n}>*/}
                <App/>
                {/*</I18nextProvider>*/}
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);