import React, {useCallback} from 'react';
import styled from "styled-components";
import {Link, useHistory} from "react-router-dom";

const InfluWrapper = styled.div`
  display: flex;
  flex:1;
  overflow: hidden;
  flex-direction: ${props => props.direction};
  align-items: center;
  line-height: 12px;
`;

const Protag = styled.div`
  display: flex;
  border-radius: 4px;
  background: transparent linear-gradient(180deg, #5E59F1 0%, #7C41A8 100%);
  padding: 3px 8px;
`;

const StreamerTag = styled.div`
  display: flex;
  border-radius: 4px;
  background: transparent linear-gradient(180deg, #F1597D 0%, #A87141 100%);
  padding: 3px 8px;
`;

const TagText = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
`;

const InfluNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  margin-right: 6px;
  
`;

const InfluName = styled.span`
  display: inline;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: ${props => props.color};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${props => props.maxWidthText}px;
`;

const ImgBox = styled.button`
  margin-right: 6px;
`;

const SnsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > ImgBox {
    margin-right: 6px;
  }
`;


const InfluInfo = ({influData,textColor = '#ffffff',showLinks = true,direction = 'row',maxWidthText}) => {
    console.log(influData);
    const snsSrc = [{
        title: 'twitch',
        src: '/images/ico_sns_twi.png',
        url: influData?.twitch,
    }, {
        title: 'africa',
        src: '/images/ico_sns_afri.png',
        url: influData?.afreecatv,
    }, {
        title: 'youtube',
        src: '/images/ico_sns_yout.png',
        url: influData?.youtube,
    }];

    const renderSns = useCallback(() => {
        return snsSrc.map((data, index) => {
            if(data.url !== '-' && data.url) {
                const onClickImgBox = () => {
                    window.open(data.url,"_blank",);
                }
                return (
                    <ImgBox key={'indlue' + index} onClick={onClickImgBox}>
                        <img
                            src={data.src}
                            alt={data.title}
                        />
                    </ImgBox>
                )
            } else {
                return <React.Fragment key={index + 'unknown'}></React.Fragment>
            }

        }, [])
    }, [])



    if(influData.status === '-') return <></>
    return (
        <InfluWrapper direction={direction}>
            {influData.status === "pro" && <Protag><TagText>Pro</TagText></Protag>}
            {influData.status === "Streamer" && <StreamerTag><TagText>Streamer</TagText></StreamerTag>}
            <InfluNameWrapper>
                <InfluName maxWidthText={maxWidthText} color={textColor}>{influData.name}</InfluName>
            </InfluNameWrapper>
            {showLinks &&
            <SnsWrapper>
                {renderSns()}
            </SnsWrapper>}
        </InfluWrapper>
    );
};

export default InfluInfo;