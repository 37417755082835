/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {useTranslation} from 'react-i18next';
import {Header, Loading, Modal, Notice} from '../../components';
import {useTitle} from '../../function';
import Profile from './Profile';
import Champ from './Champ';
import Ingame from './Ingame';
import {SummonerContainer} from '../../styled/SummonerStyle';
import {GET_SUMMONER_REQUEST, UPDATE_SUMMONER_INFO_REQUEST} from "../../store/reducer/summoner";
import SummonerNotFound from "./SummonerNotFound";
import Info from "./Info";
import MatchList from "./MatchList";
import useSocket from "../../common/hooks/useSocket";
import Float from "./Float";
import {searchList} from "../../store/modules/searchList";
import {noticeChange} from "../../store/modules/notice";
import useQuery from "../../common/hooks/useQuery";


const Summoner = () => {
    const {userName, region} = useParams();
    const {t} = useTranslation();
    const history = useHistory();
    const query = useQuery();
    const [updatePer, setUpdatePer] = useState(100);
    const page = useRouteMatch();
    const dispatch = useDispatch();
    const modalOpen = useSelector((state) => state?.modal.open);
    const modalData = useSelector((state) => state?.modal.modalData);
    const currentLang = useSelector((state) => state?.translation.lang);
    const match = useSelector((state) => state?.modal.match);
    const [tabIndex, setTabIndex] = useState(query.get('tabs') > 0 ? query.get('tabs') - 1 : 0);
    const {
        summoner,
        ingameStatus,
        getSummonerDone,
        getSummonerInfoDone,
        getSummonerError,
        updateSummonerInfoDone
    } = useSelector((state) => state?.summoner);
    const [mounted, setMounted] = useState(false);


    useEffect(() => {
        if (getSummonerError) {
            if (getSummonerError !== 'No source') {
                noticeChange('alertError');
            }
        }
    }, [getSummonerError])

    useEffect(() => {
        if (updateSummonerInfoDone && getSummonerInfoDone) {
            setUpdatePer(100);
        }
    }, [getSummonerInfoDone, updateSummonerInfoDone]);


    useEffect(() => {
        if (getSummonerDone) {
            dispatch(searchList({...summoner, region: region}, 'add'))
        }
    }, [getSummonerDone])


    // 검색된 소환사명 수정
    //region 렌더링 문제
    //주소 예외처리
    useEffect(() => {
        let summonerName = userName.replace(/\s/gi, '');
        if (summonerName.length === 2) {
            summonerName = userName.split('').join('%20');

        }

        if (region) {
            if (region === 'KR' || region === 'EUW' || region === 'NA') {
                setMounted(true);
            } else {
                history.replace({
                    pathname: `/summoner/${userName}/KR`,
                    state: {name: summonerName},
                })
            }
        } else {
            history.replace({
                pathname: `/summoner/${userName}/KR`,
                state: {name: summonerName},
            })
        }
    }, [region]);


    const titleUpdate = useTitle('Loading...');
    setTimeout(() => titleUpdate(`${userName} - ${t('header.title')}`), 500);


    // 소환사 api 호출
    const getData = useCallback(() => {

        dispatch({
            type: GET_SUMMONER_REQUEST,
            data: {
                username: userName,
                region: region,
            }
        })
    }, [userName, region]);


    // 업데이트 버튼
    const handleUpdate = () => {
        const currentSocket = useSocket.connectTo();
        setUpdatePer(0);
        useSocket.socketOn(region, summoner.summoner_id, 0, currentSocket);
        useSocket.socketMyrespons(currentSocket, (msg) => {
            if (msg.match_len > 0) {
                const percent = Math.trunc((msg.finish_num / msg.match_len) * 100);
                if(!isNaN(percent) && typeof percent === 'number') {
                    setUpdatePer(percent);
                }

            }

            if (msg.finish === 100) {
                currentSocket.disconnect();
                dispatch({
                    type: UPDATE_SUMMONER_INFO_REQUEST,
                    data: {
                        region: region
                    }
                });
            }
        })
    };

    // 탭버튼 클릭시 ingame 체크
    const handleTab = (index) => {
        setTabIndex(index);
    };

    useEffect(() => {
        if (modalOpen) {
            document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;
      `;
            return () => {
                const scrollY = document.body.style.top;
                document.body.style.cssText = '';
                window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
            };
        }
    }, [modalOpen]);

    useEffect(() => {
        if (mounted) {
            getData();
            if (!query.get('tabs')) {
                setTabIndex(0);
            }
        }
    }, [userName, region, page, mounted]);


    const initLoadingNotFound = useCallback(() => {
        setTabIndex(0);
    }, []);


    return (
        <>
            {mounted
                &&
                <Header
                    initLoadingNotFound={initLoadingNotFound}
                />}
            <SummonerContainer>
                {
                    getSummonerError === 'No source' ? (
                        <SummonerNotFound
                            userName={userName}
                        />
                    ) : getSummonerDone ? (
                        <>
                            <Notice fixed={false}/>
                            <Profile
                                handleTab={handleTab}
                                region={region}
                                updatePer={updatePer}
                                handleUpdate={handleUpdate}
                            />
                            <Tabs
                                className="main_tab"
                                selectedIndex={tabIndex}
                                onSelect={(index) => handleTab(index)}>
                                <TabList className="main_tab_list">
                                    <Tab>{t('tabs.summary')}</Tab>
                                    <Tab>{t('tabs.champions')}</Tab>
                                    <Tab>
                                        <p style={ingameStatus && currentLang !== 'KOR' ? {fontSize: 14} : {}}>
                                            {t('tabs.liveGame')}
                                        </p>
                                        {ingameStatus ? (<span className="live"><img src="/images/ico_live.svg"
                                                                                     alt="LIVE"/></span>) : <></>}
                                    </Tab>
                                </TabList>
                                <TabPanel className="main_tab_panel">
                                    {/*                /!* 종합 Panel *!/*/}
                                    <Info/>
                                    <MatchList
                                        region={region}
                                        updatePer={updatePer}
                                    />
                                </TabPanel>
                                <TabPanel className="main_tab_panel">
                                    {/* 챔피언 Panel */}
                                    <Champ
                                        region={region}
                                    />
                                </TabPanel>
                                <TabPanel className="main_tab_panel">
                                    <Ingame
                                        region={region}
                                    />
                                </TabPanel>
                            </Tabs>
                            <Float/>
                        </>
                    ) : (
                        <Loading/>
                    )}
            </SummonerContainer>
            {modalOpen && <Modal modalData={modalData} open={modalOpen} match={match}/>}
        </>
    );
};

export default React.memo(Summoner);
