import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {useTranslation} from 'react-i18next';

import IngameList from './IngameList';
import {ChartWinrate, ImageChamp, Loading} from '../../components';
import {getIngameTime, getTierScore, scoreColor, tierBadge} from '../../function';
import {IngameContainer} from '../../styled/SummonerStyle';
import styled from "styled-components";
import IngameTeamInfo from "./Ingame/IngameTeamInfo";


const PredictionWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 80px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
`;

const PredictionText = styled.span`
  margin-top: ${props => props.marginTop};
  text-align: center;
  background-color: #fafbfc;
  font-size: ${props => props.fontSize && props.fontSize + 'px'};
  font-weight: 700;
`;

const Ingame = ({region}) => {
    const {t} = useTranslation();
    const summoner = useSelector((state) => state.summoner.summoner);
    const language = useSelector((state) => state.translation.lang);
    const [loading, setLoading] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [supported, setSupported] = useState(false);
    const [ingameBasic, setIngameBasic] = useState({});
    const [ingamePart, setIngamePart] = useState([]);
    const [avg, setAvg] = useState({blueScore: 0, redScore: 0, blueRating: 0, redRating: 0});
    const [avgTier, setAvgTier] = useState({blueTier: '', redTier: ''});
    const [avgWinrate, setAvgWinrate] = useState({blueWinrate: 50, redWinrate: 50});
    const [ingameTime, setIngameTime] = useState(0);
    const [error, setError] = useState(false);

    // 평균 티어 구하기
    const getAvgTier = useCallback((blueTeam, redTeam) => {
        setAvgTier({blueTier: getTierScore(blueTeam).txt, redTier: getTierScore(redTeam).txt});
    }, []);

    // 평균 구하기
    const getAvg = useCallback((blueTeam, redTeam, blueLength, redLength) => {
        setAvg({
            blueScore: blueTeam.score / blueLength,
            redScore: redTeam.score / redLength,
            blueRating: blueTeam.rating / blueLength,
            redRating: redTeam.rating / redLength,
        });
    }, []);

    // 승률 구하기
    const getWinrate = useCallback(async (modelData) => {
        await axios
            .post(`${process.env.REACT_APP_API}ingame_model`, JSON.stringify(modelData), {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
            })
            .then(function (res) {
                const result = res.data.result;
                setAvgWinrate({blueWinrate: result.B_winrate, redWinrate: result.R_winrate});
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    // 데이터 - ingame part
    const getPart = useCallback(
        async (bluePlayer, redPlayer, queue, key_time = 0) => {
            let blueInfo = bluePlayer['0'];
            let redInfo = redPlayer['0'];
            let bluePosition = bluePlayer['1'];
            let redPosition = redPlayer['1'];

            await axios
                .get(
                    `${process.env.REACT_APP_API}ingame_part?summonerId=${blueInfo.summonerId}&championId=${blueInfo.championId}&lane=${bluePosition}&summonerId=${redInfo.summonerId}&championId=${redInfo.championId}&lane=${redPosition}&queue=${queue}&key_time=${key_time}&region=${region}&season=13`
                )
                .then((res) => {
                    const result = res.data.result;
                    setIngamePart((prev) => [...prev, result]);
                });
        },
        [region]
    );

    // getSummonerData 순서대로 호출
    const orderCall = useCallback(
        async (list, queue, key_time) => {
            for (const item of list) {
                await getPart(item[0], item[1], queue, key_time);
            }
        },
        [getPart]
    );

    // 데이터 - ingame basic
    const getData = useCallback(() => {
        axios
            .get(
                `${process.env.REACT_APP_API}ingame_basic?summonerId=${summoner.summoner_id}&region=${region}&season=13`
            )
            .then((res) => {
                const result = res.data.result;

                if (result !== 'Not playing') {
                    if (result !== 'Not supported') {
                        if (result !== 'server error') {
                            setIngameBasic(result);
                            setIngameTime(result.game_timestamp);
                            orderCall(result.summoner_list, result.queue, result.key_time);
                            setPlaying(true);
                            setSupported(true);
                        } else {
                            setError(true);
                        }
                    } else {
                        setPlaying(true);
                        setSupported(false);
                    }
                }

                setIngamePart([]);
                setLoading(true);
            })
            .catch((err) => {
                setError(true);
                console.error(err);
            });
    }, [orderCall, region, summoner]);

    useEffect(() => {
        getData();
    }, [summoner, region]);

    // 게임시간 구하기
    const getTime = useCallback(() => {
        setIngameTime(ingameTime + 1);
    }, [ingameTime]);

    useEffect(() => {
        let interval = setInterval(() => {
            getTime();
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [getTime]);

    return (
        <>
            {error ? (
                <IngameContainer>
                    <div className="not_supported">
                        <div>
                            <p>
                                {t('ingame.alertError')}{' '}
                            </p>
                            <br/>
                            <button
                                onClick={() => {
                                    window.location.replace(`/summoner/${summoner.summoner_name}`);
                                }}>
                                {t('ingame.back')}
                            </button>
                        </div>
                    </div>
                </IngameContainer>
            ) : (
                <IngameContainer className={`${loading ? 'on' : 'off'}`}>
                    {loading ? (
                        playing ? (
                            supported ? (
                                <>
                                    <div className="overview">
                                        <div className="title">
                                            <h3>LIVE</h3>
                                            <p>
                                                {ingameBasic.queue === 420
                                                    ? t('summoner.rankedSolo')
                                                    : ingameBasic.queue === 440
                                                        ? t('summoner.rankedFlex')
                                                        : t('summoner.blind')}
                                            </p>
                                            <dl>
                                                <dt>{t('ingame.mapName')}</dt>
                                                <dd>{getIngameTime(ingameTime)}</dd>
                                            </dl>
                                        </div>
                                        <div className="average">
                                            <div className="team blue">
                                                <div className="cont">
                                                    <div className="avg">
                                                        <p>
                                                            {t('summoner.avg')} <strong>AI-Score</strong> :&nbsp;
                                                            {isNaN(avg.blueScore) ? 0 : scoreColor(avg.blueScore.toFixed(1))}
                                                        </p>
                                                        <p>
                                                            {t('summoner.avgRank')} : #
                                                            {isNaN(avg.blueRating) ? 0 : avg.blueRating.toFixed(1)}
                                                            &nbsp;
                                                            {tierBadge(avgTier.blueTier)}
                                                        </p>
                                                    </div>
                                                    <div className="ban">
                                                        {ingameBasic.bannedChampions.map(
                                                            (item, index) =>
                                                                index < 5 &&
                                                                item.championId !== -1 && (
                                                                    <ImageChamp key={index} champId={item.championId}/>
                                                                )
                                                        )}
                                                    </div>
                                                </div>
                                                <IngameTeamInfo
                                                    color={'#5682DB'}
                                                    sideName={t('summoner.side.blue')}
                                                    teamName={t('summoner.team')}
                                                    winRate={avgWinrate.blueWinrate}
                                                />
                                                {/*<SideAndPercent>*/}
                                                {/*    <TeamText>*/}
                                                {/*        {t('summoner.side.blue')} {t('summoner.team')}*/}
                                                {/*    </TeamText>*/}
                                                {/*    <div style={{ display:'flex',flexDirection: 'row',alignItems:'flex-end'}}>*/}
                                                {/*        <PercetnText>{avgWinrate.blueWinrate} </PercetnText>*/}
                                                {/*        <ExtraWrapper>*/}
                                                {/*            <PercentTextExtra>%</PercentTextExtra>*/}
                                                {/*        </ExtraWrapper>*/}
                                                {/*    </div>*/}
                                                {/*</SideAndPercent>*/}
                                            </div>
                                            {/*벽*/}
                                            <div style={{flex: 0.3}}/>
                                            <div className="team red">
                                                <div className="cont">
                                                    <div className="avg">
                                                        <p>
                                                            {t('summoner.avg')} <strong>AI-Score</strong> :&nbsp;
                                                            {isNaN(avg.redScore) ? 0 : scoreColor(avg.redScore.toFixed(1))}
                                                        </p>
                                                        <p>
                                                            {t('summoner.avgRank')} : #
                                                            {isNaN(avg.redRating) ? 0 : avg.redRating.toFixed(1)}
                                                            &nbsp;
                                                            {tierBadge(avgTier.redTier)}
                                                        </p>
                                                    </div>
                                                    <div className="ban">
                                                        {ingameBasic.bannedChampions.map(
                                                            (item, index) =>
                                                                index >= 5 &&
                                                                item.championId !== -1 && (
                                                                    <ImageChamp key={index} champId={item.championId}/>
                                                                )
                                                        )}
                                                    </div>
                                                </div>
                                                <IngameTeamInfo
                                                    color={'#DE4469'}
                                                    rowReverse
                                                    teamName={t('summoner.team')}
                                                    sideName={t('summoner.side.red')}
                                                    winRate={avgWinrate.redWinrate}
                                                />
                                                {/*<div className="name">*/}
                                                {/*    {t('summoner.side.red')} {t('summoner.team')}*/}
                                                {/*</div>*/}
                                                {/*<div className="winrate">*/}
                                                {/*    <strong> {avgWinrate.redWinrate}</strong>*/}
                                                {/*    <span className={"winrate_percent"}> % </span>*/}
                                                {/*</div>*/}
                                            </div>

                                            <div className="predict">
                                                <PredictionWrapper>
                                                    <PredictionText
                                                        fontSize={20}>{t('ingame.aiPrediction1')}</PredictionText>
                                                    <PredictionText marginTop={'9px'}
                                                                    fontSize={language === 'KOR' ? 20 : 13}>{t('ingame.aiPrediction2')}</PredictionText>
                                                </PredictionWrapper>
                                                {/*<span className={"predictText"}>{t('ingame.aiPrediction1')}{"\n"}{t('ingame.aiPrediction2')}</span>*/}
                                                <ChartWinrate
                                                    blueWinrate={avgWinrate.blueWinrate}
                                                    redWinrate={avgWinrate.redWinrate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="t_head">
                                            <div className="row">
                                                <ul className="team blue">
                                                    <li className="tit item1">{t('ingame.summoner')}</li>
                                                    <li className="tit item2">{t('ingame.champion')}</li>
                                                    <li className="tit item3">
                                                        AI-Score <span>({t('ingame.avgRank')})</span>
                                                    </li>
                                                </ul>
                                                <div className="center">
                                                    <p className="tit">{t('ingame.vs')}</p>
                                                </div>
                                                <ul className="team red">
                                                    <li className="tit item1">{t('ingame.summoner')}</li>
                                                    <li className="tit item2">{t('ingame.champion')}</li>
                                                    <li className="tit item3">
                                                        AI-Score <span>({t('ingame.avgRank')})</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="t_body">
                                            {ingamePart.length !== 0 ? (
                                                <IngameList
                                                    ingameBasic={ingameBasic}
                                                    ingamePart={ingamePart}
                                                    getAvg={getAvg}
                                                    getAvgTier={getAvgTier}
                                                    getWinrate={getWinrate}
                                                />
                                            ) : (
                                                <Loading/>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="not_supported">
                                    <p>{t('notice.notSupport')}</p>
                                </div>
                            )
                        ) : (
                            <div className="not_playing">
                                <div>
                                    <p>
                                        {summoner.summoner_name} {t('ingame.notPlaying1')} <br/>
                                        {t('ingame.notPlaying2')}
                                    </p>
                                </div>
                            </div>
                        )
                    ) : (
                        <Loading/>
                    )}
                </IngameContainer>
            )}
        </>
    );
};

export default React.memo(Ingame);
