import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import socketio from 'socket.io-client';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IngameSummoner from './IngameSummoner';
import {ImageChamp, ImageLane, ImageRune, ImageSpell, Loading} from '../../components';
import {getGameTime, kdaColor, scoreColor} from '../../function';
import SummonerProfile from "./Ingame/SummonerProfile";

const IngameItem = ({
                        part,
                        basick,
                        queue,
                        setUpdatedState,
                        blueDuoList,
                        redDuoList,
                        tierInfo,
                        handleMoreInfo,
                        openLane,
                        ranks,
                    }) => {
    const {t} = useTranslation();
    const champNames = useSelector((state) => state.imageStore.champions);
    const currentLang = useSelector((state) => state.translation.lang);
    const region = useSelector((state) => state.region.result);
    const [state, setState] = useState(part);
    const [updated, setUpdated] = useState(100);
    const blueGameInfo = basick[0][0];
    const redGameInfo = basick[1][0];
    const blueLane = basick[0][1];
    const redLane = basick[1][1];


    const getSummonerMatchAvg = (list) => {
        let winSum = 0;
        let loseSum = 0;
        list.forEach((item) => {
            item.win ? winSum++ : loseSum++;
        });

        return {
            win: winSum,
            lose: loseSum,
        };
    };

    const getSummonerData = useCallback(() => {
        axios
            .get(
                `${process.env.REACT_APP_API}ingame_part?summonerId=${blueGameInfo.summonerId}&championId=${blueGameInfo.championId}&lane=${blueLane}&summonerId=${redGameInfo.summonerId}&championId=${redGameInfo.championId}&lane=${redLane}&queue=${queue}&key_time=0&region=${region}&season=13`
            )
            .then((res) => {
                const result = res.data.result;
                setState(result);
                setUpdatedState(result);
            });
    }, [
        blueGameInfo.summonerId,
        blueGameInfo.championId,
        blueLane,
        redGameInfo.summonerId,
        redGameInfo.championId,
        redLane,
        queue,
        region,
        setUpdatedState,
    ]);

    // 소환사 업데이트
    const getSummonerUpdate = useCallback(() => {
        // 소켓 도메인/네임스페이스
        const namespace = `${process.env.REACT_APP_API}update_match_ingame`;
        // 소켓 연결
        const socket = socketio.connect(
            namespace,
            {
                transports: ['websocket'],
                upgrade: false,
                secure: true,
            },
            {forceNew: true}
        );
        // 업데이트 연결 시 User Connected emit
        socket.on('connect', () => {
            socket.emit('my event ingame', {
                data: 'User Connected',
            });
            socket.emit('my event ingame', {
                summoner_id: `${blueGameInfo.summonerId}/${redGameInfo.summonerId}`,
                region: region,
                season: 13,
                count: 0,
                queue: queue,
            });
        });
        // 서버에서 데이터 받는 경우
        socket.on('my response ingame', (msg) => {
            // 카운트
            if (msg.finish_num !== undefined) {
                let per = Math.floor((msg.finish_num / msg.match_len) * 100);
                setUpdated(per);
            }
            // finish가 100이면(서버에서 종료 요청) 소켓 disconnect
            if (msg.finish === 100) {
                socket.disconnect();
                getSummonerData();
                setUpdated(100);
            }
            // 받은 데이터에 summoner_id 값이 있으면, 다시 서버에 진행정도 확인 요청
            if (msg.hasOwnProperty('summoner_id')) {
                socket.emit('my event ingame', {
                    summoner_id: msg.summoner_id,
                    summoner_key: msg.summoner_key,
                    region: msg.region,
                    match_len: msg.match_len,
                    finish_check: 100,
                    count: msg.count,
                });
            }
        });
    }, [blueGameInfo.summonerId, redGameInfo.summonerId, region, queue, getSummonerData]);

    useEffect(() => {
        getSummonerUpdate();
    }, [getSummonerUpdate]);

    return (
        <li
            className={`ingame_item ${updated === 100 ? 'complete' : 'incomplete'} ${
                openLane === blueLane ? 'open' : 'close'
            }`}
        >
            {updated !== 100 && <Loading/>}
            <div className="row">
                <div className="team blue">
                    <IngameSummoner
                        ranks={ranks.B}
                        gameInfo={state.B}
                        teamInfo={blueGameInfo}
                        lane={blueLane}
                        duoList={blueDuoList}
                        tierInfo={tierInfo}
                        side="blue"
                        feature={state.feature}
                        queue={queue}
                    />
                    <div className="game_info">
                        <div className="champ_data">
                            <ImageChamp champId={blueGameInfo.championId}/>
                            <div className="spell">
                                <ImageSpell spellId={blueGameInfo.spell1Id}/>
                                <ImageSpell spellId={blueGameInfo.spell2Id}/>
                            </div>
                            <SummonerProfile
                                gameInfo={blueGameInfo}
                                state={state.B}
                            />
                        </div>
                        <div className="rune_data">
                            <div className="runes">
                                {blueGameInfo.perks.perkIds.map(
                                    (id, index) => index < 4 && <ImageRune key={index} runeId={id}/>
                                )}
                            </div>
                            <div className="runes">
                                {blueGameInfo.perks.perkIds.map(
                                    (id, index) => 3 < index && index < 6 && <ImageRune key={index} runeId={id}/>
                                )}
                            </div>
                            <div className="runes">
                                {blueGameInfo.perks.perkIds.map((id, index) => {
                                    if (5 < index) {
                                        if (id === 5001) {
                                            return (
                                                <img
                                                    key={index}
                                                    src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsHealthScalingIcon.png"
                                                    alt="HealthScaling"
                                                />
                                            );
                                        } else if (id === 5002) {
                                            return (
                                                <img
                                                    key={index}
                                                    src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsArmorIcon.png"
                                                    alt="Armor"
                                                />
                                            );
                                        } else if (id === 5003) {
                                            return (
                                                <img
                                                    key={index}
                                                    src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsMagicResIcon.png"
                                                    alt="MagicRes"
                                                />
                                            );
                                        } else if (id === 5005) {
                                            return (
                                                <img
                                                    key={index}
                                                    src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsAttackSpeedIcon.png"
                                                    alt="AttackSpeed"
                                                />
                                            );
                                        } else if (id === 5007) {
                                            return (
                                                <img
                                                    key={index}
                                                    src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsCDRScalingIcon.png"
                                                    alt="CDRScaling"
                                                />
                                            );
                                        } else if (id === 5008) {
                                            return (
                                                <img
                                                    key={index}
                                                    src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsAdaptiveForceIcon.png"
                                                    alt="Adaptive"
                                                />
                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="center">
                    <div className="score_info">
                        <div className="score">
                            {scoreColor(state.B.ai_score_total)}
                            <span className="rating">#{state.B.ai_score_rank_total.toFixed(1)}</span>
                        </div>
                        <div className="graph">
                            <div className="number">
                                <p className="blue">
                  <span>
                    {isNaN(
                        state.B.ai_score_total / (state.B.ai_score_total + state.R.ai_score_total)
                    )
                        ? 0
                        : (
                            (state.B.ai_score_total /
                                (state.B.ai_score_total + state.R.ai_score_total)) *
                            100
                        ).toFixed(0)}
                  </span>
                                    %
                                </p>
                                <p className="red">
                  <span>
                    {isNaN(
                        state.R.ai_score_total / (state.B.ai_score_total + state.R.ai_score_total)
                    )
                        ? 0
                        : (
                            (state.R.ai_score_total /
                                (state.B.ai_score_total + state.R.ai_score_total)) *
                            100
                        ).toFixed(0)}
                  </span>
                                    %
                                </p>
                            </div>
                            <div className="bar">
                                <p
                                    className="blue"
                                    style={{
                                        width: `${(
                                            (state.B.ai_score_total / (state.B.ai_score_total + state.R.ai_score_total)) *
                                            100
                                        ).toFixed(0)}%`,
                                    }}
                                ></p>
                                <p
                                    className="red"
                                    style={{
                                        width: `${(
                                            (state.R.ai_score_total / (state.B.ai_score_total + state.R.ai_score_total)) *
                                            100
                                        ).toFixed(0)}%`,
                                    }}
                                ></p>
                            </div>
                        </div>
                        <div className="score">
                            {scoreColor(state.R.ai_score_total)}
                            <span className="rating">#{state.R.ai_score_rank_total.toFixed(1)}</span>
                        </div>
                    </div>
                    <div className="counter_info">
                        <div className="counter blue">
                            <ImageChamp champId={blueGameInfo.championId}/>
                            <p>{scoreColor(state.champion_counter.B_champ_winrate)} %</p>
                        </div>
                        <dl className="vs">
                            <dt>VS</dt>
                            <dd>
                                <span>{state.champion_counter.games.toLocaleString()}</span> games
                            </dd>
                        </dl>
                        <div className="counter red">
                            <ImageChamp champId={redGameInfo.championId}/>
                            <p>{scoreColor(state.champion_counter.R_champ_winrate)} %</p>
                        </div>
                    </div>
                </div>
                <div className="team red">
                    <IngameSummoner
                        ranks={ranks.R}
                        gameInfo={state.R}
                        teamInfo={redGameInfo}
                        lane={redLane}
                        duoList={redDuoList}
                        tierInfo={tierInfo}
                        side="red"
                        feature={state.feature}
                        queue={queue}
                    />
                    <div className="game_info">
                        <div className="champ_data">
                            <ImageChamp champId={redGameInfo.championId}/>
                            <div className="spell">
                                <ImageSpell spellId={redGameInfo.spell1Id}/>
                                <ImageSpell spellId={redGameInfo.spell2Id}/>
                            </div>
                            <SummonerProfile
                                reverse={true}
                                gameInfo={redGameInfo}
                                state={state.R}
                            />
                            {/*<div className="txt">*/}
                            {/*  {champNames.map(*/}
                            {/*    (champ, idx) =>*/}
                            {/*      Number(champ.championId) === redGameInfo.championId && (*/}
                            {/*        <p className="name" key={idx}>*/}
                            {/*          <strong>{currentLang === 'KOR' ? champ.champion_name_kr : champ.champion_name_en}</strong>*/}
                            {/*        </p>*/}
                            {/*      )*/}
                            {/*  )}*/}
                            {/*  <p>*/}
                            {/*    <em>{scoreColor(state.R.this_champion_stat.season_winrate.toFixed(1))}%</em>*/}
                            {/*    <em>*/}
                            {/*      <strong>{state.R.this_champion_stat.season_games}</strong> {t('summoner.games')}*/}
                            {/*    </em>*/}
                            {/*  </p>*/}
                            {/*  <p>*/}
                            {/*    <em>{kdaColor(state.R.this_champion_stat.season_kda.toFixed(2))}</em>*/}
                            {/*    <em>{scoreColor(state.R.this_champion_stat.season_ai_score)}</em>*/}
                            {/*  </p>*/}
                            {/*</div>*/}
                        </div>
                        <div className="rune_data">
                            <div className="runes">
                                {redGameInfo.perks.perkIds.map(
                                    (id, index) => index < 4 && <ImageRune key={index} runeId={id}/>
                                )}
                            </div>
                            <div className="runes">
                                {redGameInfo.perks.perkIds.map(
                                    (id, index) => 3 < index && index < 6 && <ImageRune key={index} runeId={id}/>
                                )}
                            </div>
                            <div className="runes">
                                {redGameInfo.perks.perkIds.map((id, index) => {
                                    if (5 < index) {
                                        if (id === 5001) {
                                            return (
                                                <img
                                                    key={index}
                                                    src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsHealthScalingIcon.png"
                                                    alt="HealthScaling"
                                                />
                                            );
                                        } else if (id === 5002) {
                                            return (
                                                <img
                                                    key={index}
                                                    src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsArmorIcon.png"
                                                    alt="Armor"
                                                />
                                            );
                                        } else if (id === 5003) {
                                            return (
                                                <img
                                                    key={index}
                                                    src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsMagicResIcon.png"
                                                    alt="MagicRes"
                                                />
                                            );
                                        } else if (id === 5005) {
                                            return (
                                                <img
                                                    key={index}
                                                    src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsAttackSpeedIcon.png"
                                                    alt="AttackSpeed"
                                                />
                                            );
                                        } else if (id === 5007) {
                                            return (
                                                <img
                                                    key={index}
                                                    src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsCDRScalingIcon.png"
                                                    alt="CDRScaling"
                                                />
                                            );
                                        } else if (id === 5008) {
                                            return (
                                                <img
                                                    key={index}
                                                    src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsAdaptiveForceIcon.png"
                                                    alt="Adaptive"
                                                />
                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button">
                <button onClick={() => handleMoreInfo(blueLane)}>
                    <img src="/images/icon_arrow_b.svg" alt={t('summoner.more')}/>
                </button>
            </div>
            {openLane === blueLane && (
                <div className="ingame_detail">
                    <h4>
                        {t('ingame.detailTitle')} <span>(AI-Score, KDA, {t('summoner.rank')})</span>
                    </h4>
                    <div className="detail_info">
                        <div className="team blue">
                            <ul className="match_list">
                                {state.B.summoner_match_list.map((item) => (
                                    <li key={item.timestamp} className="match_item">
                                        <div className={`box ${item.win ? 'win' : 'lose'}`}>
                                            <div className="champ">
                                                <ImageChamp champId={item.championId}/>
                                                <ImageLane lane={item.lane} color="white"/>
                                            </div>
                                            <div className="score">

                                                {item.check_away ? scoreColor(0) : scoreColor(item.ai_score.toFixed(0))}
                                            </div>
                                            <div className="kda">
                                                {item.check_away ? (
                                                    '-'
                                                ) : item.death === 0 ? (
                                                    <span className="kda_color perfect">Perfect</span>
                                                ) : (
                                                    kdaColor(item.kda.toFixed(2))
                                                )}
                                            </div>
                                            <div className="rating">
                                                {item.mvp === 1 && <img src="/images/ico_crown_gold_w.svg" alt="mvp"/>}
                                                {item.ace === 1 &&
                                                <img src="/images/ico_crown_silver_w.svg" alt="mvp"/>}
                                                {item.check_away ? (
                                                    <span className="away">{t('summoner.escape')}</span>
                                                ) : item.ai_score_rank === 1 ? (
                                                    item.ai_score_rank + t('summoner.firstGrade')
                                                ) : item.ai_score_rank === 2 ? (
                                                    item.ai_score_rank + t('summoner.secondGrade')
                                                ) : item.ai_score_rank === 3 ? (
                                                    item.ai_score_rank + t('summoner.thirdGrade')
                                                ) : (
                                                    item.ai_score_rank + t('summoner.grade')
                                                )}
                                            </div>
                                        </div>
                                        <div className="time">{getGameTime(item.timestamp, currentLang)}</div>
                                    </li>
                                ))}
                            </ul>
                            <div className="match_num">

                                <h5>{scoreColor(state.B.ai_score_total)}</h5>
                                <div className="winlose">
                                    <dl>
                                        <dt>
                                            {getSummonerMatchAvg(state.B.summoner_match_list).win}
                                            {t('summoner.contractionWin')}
                                        </dt>
                                        <dd>{scoreColor(state.B.ai_score_win)}</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            {getSummonerMatchAvg(state.B.summoner_match_list).lose}
                                            {t('summoner.contractionLose')}
                                        </dt>
                                        <dd>{scoreColor(state.B.ai_score_loss)}</dd>
                                    </dl>
                                </div>
                                <p>#{state.B.ai_score_rank_total.toFixed(1)}</p>
                            </div>
                        </div>
                        <div className="team red">
                            <ul className="match_list">
                                {state.R.summoner_match_list.map((item) => (
                                    <li key={item.timestamp} className="match_item">
                                        <div className={`box ${item.win ? 'win' : 'lose'}`}>
                                            <div className="champ">
                                                <ImageChamp champId={item.championId}/>
                                                <ImageLane lane={item.lane} color="white"/>
                                            </div>
                                            <div className="score">
                                                {item.check_away ? scoreColor(0) : scoreColor(item.ai_score.toFixed(0))}
                                            </div>
                                            <div className="kda">
                                                {item.check_away ? (
                                                    '-'
                                                ) : item.death === 0 ? (
                                                    <span className="kda_color perfect">Perfect</span>
                                                ) : (
                                                    kdaColor(item.kda.toFixed(2))
                                                )}
                                            </div>
                                            <div className="rating">
                                                {item.mvp === 1 && <img src="/images/ico_crown_gold_w.svg" alt="mvp"/>}
                                                {item.ace === 1 &&
                                                <img src="/images/ico_crown_silver_w.svg" alt="mvp"/>}
                                                {item.check_away ? (
                                                    <span className="away">{t('summoner.escape')}</span>
                                                ) : item.ai_score_rank === 1 ? (
                                                    item.ai_score_rank + t('summoner.firstGrade')
                                                ) : item.ai_score_rank === 2 ? (
                                                    item.ai_score_rank + t('summoner.secondGrade')
                                                ) : item.ai_score_rank === 3 ? (
                                                    item.ai_score_rank + t('summoner.thirdGrade')
                                                ) : (
                                                    item.ai_score_rank + t('summoner.grade')
                                                )}
                                            </div>
                                        </div>
                                        <div className="time">{getGameTime(item.timestamp, currentLang)}</div>
                                    </li>
                                ))}
                            </ul>
                            <div className="match_num">
                                <h5>{scoreColor(state.R.ai_score_total)}</h5>
                                <div className="winlose">
                                    <dl>
                                        <dt>
                                            {getSummonerMatchAvg(state.R.summoner_match_list).win}
                                            {t('summoner.contractionWin')}
                                        </dt>
                                        <dd>{scoreColor(state.R.ai_score_win)}</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            {getSummonerMatchAvg(state.R.summoner_match_list).lose}
                                            {t('summoner.contractionLose')}
                                        </dt>
                                        <dd>{scoreColor(state.R.ai_score_loss)}</dd>
                                    </dl>
                                </div>
                                <p>#{state.R.ai_score_rank_total.toFixed(1)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </li>
    );
};

export default React.memo(IngameItem);
