import {all, call, fork, put, select, takeLatest} from "redux-saga/effects";
import {
    GET_CHAMP_INFO_FAILURE,
    GET_CHAMP_INFO_REQUEST,
    GET_CHAMP_INFO_SUCCESS,
    GET_MATCH_BY_QUEUE_FAILURE,
    GET_MATCH_BY_QUEUE_REQUEST,
    GET_MATCH_BY_QUEUE_SUCCESS,
    GET_MORE_MATCHES_FAILURE,
    GET_MORE_MATCHES_REQUEST,
    GET_MORE_MATCHES_SUCCESS,
    GET_SUMMONER_FAILURE,
    GET_SUMMONER_INFO_FAILURE,
    GET_SUMMONER_INFO_REQUEST,
    GET_SUMMONER_INFO_SUCCESS,
    GET_SUMMONER_REQUEST,
    GET_SUMMONER_SUCCESS,
    UPDATE_SUMMONER_INFO_FAILURE,
    UPDATE_SUMMONER_INFO_REQUEST,
    UPDATE_SUMMONER_INFO_SUCCESS
} from "../reducer/summoner";
import axios from "axios";
import {_sorting_objects, setSummonerName} from "../../function";

const getChampInfoAPI = (action) => {
    let url;
    if (action.category === 0) {
        url = `${process.env.REACT_APP_API}summoner_stat_tap?summonerId=${action.summoner_id}&season=13&region=${action.region}`
    } else if (action.category === 1) {
        url = `${process.env.REACT_APP_API}summoner_stat_tap_enemy?summonerId=${action.summoner_id}&season=13&region=${action.region}`
    } else {
        throw 'unexpected category';
    }

    return axios.get(url).then((res) => res.data.result);
}

const getSummonerAPI = (action) => {
    const searchName = setSummonerName(action.username);
    const url = `${process.env.REACT_APP_API}summoner?summonerName=${encodeURI(searchName)}&region=${action.region}`
    return axios.get(url).then((result) => {
        if (!result.data.error) {
            return result.data.result
        } else {
            throw result.data.error
        }
    });
}

const getSummonerStatus = (action) => {

    const url = `${process.env.REACT_APP_API}summoner_stat?summonerId=${action.summoner_id}&region=${action.region}&season=13`
    // 소환사 stat 정보
    return axios.get(url)
        .then((res) => {
            const data = res.data.result;
            return data;
        });
}


const getIngameDataAPI = (action) => {
    const url = `${process.env.REACT_APP_API}ingame_basic?summonerId=${action.summoner_id}&region=${action.region}&season=13`;
    return axios.get(url).then((response) => {
        const result = response.data.result;
        if (result !== 'Not playing') {
            if (result !== 'Not supported') {
                if (result !== 'server error') {
                    return result;
                }
                throw 'server error';
            }
            throw 'Not supported';
        }
        throw "Not playing";
    })
}

const getSummonerIngameStatusAPI = (action) => {
    const url = `${process.env.REACT_APP_API}check_ingame?summonerId=${action.summoner_id}&region=${action.region}`

    return axios
        .get(url)
        .then((res) => {
            const data = res.data.result;
            return data === 'Playing';
        })
        .catch((err) => {
            console.error(err);
        });

}


const getUpdateStatus = (action) => {
    const url = `${process.env.REACT_APP_API}update_time?summonerId=${action.summoner_id}&region=${action.region}&season=13`;
    return axios.get(url)
        .then((res) => res.data.result);
}

const getMatchInfoAPI = (action) => {
    const url = `${process.env.REACT_APP_API}match?summonerId=${action.summoner_id}&season=13&region=${action.region}&offset=${action.offset}&gameNum=${action.matchLength}&queue=${action.queueNum}&championId=${action.champId}`
    return axios.get(url).then((res) => res.data);
}
const getMatchsInfoAPI = (action) => {
    const url = `${process.env.REACT_APP_API}matches?summonerId=${action.summoner_id}&season=13&region=${action.region}&offset=${action.offset}&gameNum=${action.matchLength}&queue=${action.queueNum}&championId=${action.champId}`
    return axios.get(url).then((res) => res.data);
}

const getMatchDataAPI = (action) => {
    const url = `${process.env.REACT_APP_API}matchjson?gameId=${action.matchId}&season=13&region=${action.region}&saveNew=0`
    return axios.get(url).then((res) => res.data);
}

const getTierChartAPI = (action) => {
    return axios.get(`${process.env.REACT_APP_API}tier_chart?summonerId=${action.summoner_id}&region=${action.region}`)
        .then((res) => res.data.result);
}


function* getMoreMatches(action) {
    try {


        const summoner = yield select((state) => state.summoner.summoner);
        const match = yield select((state) => state.summoner.match);


        const matchInfoArgs = {
            summoner_id: summoner.summoner_id,
            region: action.data.region,
            offset: match.length,
            matchLength: 10,
            queueNum: action.data.queueNum,
            champId: action.data.champId,
        }

        const matchArr = yield call(getMatchsInfoAPI, matchInfoArgs)

        yield put({
            type: GET_MORE_MATCHES_SUCCESS,
            data: {
                match: matchArr
            }
        })


    } catch (e) {
        yield put({
            type: GET_MORE_MATCHES_FAILURE,
            error: e,
        })
    }
}


function* getSummoner(action) {
    try {
        const getSummoner = yield call(getSummonerAPI, action.data);



        yield all([put({
            type: GET_SUMMONER_SUCCESS,
            data: {
                summoner: getSummoner,
            }
        }),
            put({
                type: GET_SUMMONER_INFO_REQUEST,
                data: {
                    summoner: getSummoner,
                    ...action.data,
                }
            })])

    } catch (e) {
        yield put({
            type: GET_SUMMONER_FAILURE,
            error: e,
        })
    }

}

function* getChampInfo(action) {
    try {
        const summoner = yield select((state) => state.summoner.summoner);
        const champData = yield select((state) => state.imageStore.champions);
        const lang = yield select((state) => state.translation.lang);
        const champInfo = yield call(getChampInfoAPI, {
            summoner_id: summoner.summoner_id,
            region: action.data.region,
            category: action.data.category,
        });

        if (champInfo) {
            //챔피언 이름 붙이기
            for (let attr in champInfo) {
                for (let position in champInfo[attr]) {
                    if (champInfo[attr].hasOwnProperty(position)) {
                        champInfo[attr][position] = champInfo[attr][position].map((data, index) => {
                            const getChampionData = champData.find((champ, index) => Number(champ.championId) === Number(data.championId));
                            let detail = [];
                            if(data.detail.length > 0) {
                                detail = data.detail.map((detail,index) => {
                                    const detailData = champData.find((champ, index) => Number(champ.championId) === Number(detail.enemyId));
                                    return {
                                        ...detail,
                                        enemy_name_kr: detailData.champion_name_kr,
                                        enemy_name_en: detailData.champion_name_en,
                                    }
                                })
                            }
                            return {
                                ...data,
                                detail: [...detail],
                                champion_name_kr: getChampionData.champion_name_kr,
                                champion_name_en: getChampionData.champion_name_en,
                            }
                        })
                    }
                }
            }


            const sortFilter = ['games','winrate'];
            sortFilter.push(lang === 'KOR' ? 'champion_name_kr' : 'champion_name_en');

            const withOrigin = champInfo['with_position'];
            const withOutOrigin = champInfo['without_position'];

            yield put({
                type: GET_CHAMP_INFO_SUCCESS,
                data: {
                    withOrigin: {...withOrigin},
                    withOutOrigin: {...withOutOrigin},
                }
            })
        }


    } catch (e) {
        console.error(e);
        yield put({
            type: GET_CHAMP_INFO_FAILURE,
            error: e,
        })
    }
}

function* getSummonerInfo(action) {
    try {


        const regionAndSummonerId = {
            summoner_id: action.data.summoner.summoner_id,
            region: action.data.region
        }

        const matchInfoArgs = {
            summoner_id: action.data.summoner.summoner_id,
            region: action.data.region,
            matchLength: 10,
            queueNum: 0,
            offset: 0,
            champId: 0,
        }

        const [
            getUpateStatus,
            getSummonerStat,
            getIngameStatus,
            getTierChart,
            matchArr
        ] = yield all([call(getUpdateStatus, regionAndSummonerId),
            call(getSummonerStatus, regionAndSummonerId),
            call(getSummonerIngameStatusAPI, regionAndSummonerId),
            call(getTierChartAPI, regionAndSummonerId),
            call(getMatchsInfoAPI, matchInfoArgs)]);


        yield put({
            type: GET_SUMMONER_INFO_SUCCESS,
            data: {
                updateStatus: getUpateStatus,
                summonerStatus: getSummonerStat,
                ingameStatus: getIngameStatus,
                tierChart: getTierChart,
                match: matchArr,
            }
        })


    } catch (error) {
        console.error(error);
        yield put({
            type: GET_SUMMONER_INFO_FAILURE,
        })
    }
}

function* updateSummonerInfo(action) {
    try {
        const summoner = yield select((state) => state.summoner.summoner);

        const upateStatus = yield call(getUpdateStatus, {
            summoner_id: summoner.summoner_id,
            region: action.data.region
        });


        yield all([put({
            type: GET_SUMMONER_INFO_REQUEST,
            data: {
                summoner: summoner,
                ...action.data,
            }
        }),
            put({
                type: UPDATE_SUMMONER_INFO_SUCCESS,
                data: {
                    updateStatus: upateStatus
                }
            })

        ])

    } catch (error) {
        console.error(error);
        yield put({
            type: UPDATE_SUMMONER_INFO_FAILURE,
            error: error,
        })
    }
}

function* getMatchByQueue(action) {
    try {
        const summoner = yield select((state) => state.summoner.summoner);


        const matchInfoArgs = {
            summoner_id: summoner.summoner_id,
            region: action.data.region,
            matchLength: 10,
            offset: 0,
            queueNum: action.data.queueNum,
            champId: action.data.champId,
        }
        const getMatchInfo = yield call(getMatchsInfoAPI, matchInfoArgs)


        yield put({
            type: GET_MATCH_BY_QUEUE_SUCCESS,
            data: {
                match: getMatchInfo,
            }
        })

    } catch (e) {
        console.error(e);
        yield put({
            type: GET_MATCH_BY_QUEUE_FAILURE,
            error: e,
        })
    }
}

function* liveSummonerIngame(action) {
    try {
        const liveData = yield call(getIngameDataAPI, action.data);

    } catch (e) {
    }
}

function* watchGetMoreMatches() {
    yield takeLatest(GET_MORE_MATCHES_REQUEST, getMoreMatches)
}

function* watchGetMatches() {
    yield takeLatest(GET_SUMMONER_REQUEST, getSummoner)
}

function* watchGetChampInfo() {
    yield takeLatest(GET_CHAMP_INFO_REQUEST, getChampInfo)
}

function* watchGetSummonerInfo() {
    yield takeLatest(GET_SUMMONER_INFO_REQUEST, getSummonerInfo);
}


function* watchUpdateSummonerInfo() {
    yield takeLatest(UPDATE_SUMMONER_INFO_REQUEST, updateSummonerInfo);
}


function* watchGetMatchByQueue() {
    yield takeLatest(GET_MATCH_BY_QUEUE_REQUEST, getMatchByQueue);
}

function* watchLiveSummonerIngame() {
    yield takeLatest("??????", liveSummonerIngame);
}

export default function* summonerSaga() {

    yield all([
        fork(watchGetMoreMatches),
        fork(watchGetMatches),
        fork(watchGetChampInfo),
        fork(watchGetSummonerInfo),
        fork(watchUpdateSummonerInfo),
        fork(watchGetMatchByQueue),
        fork(watchLiveSummonerIngame),
    ])
}