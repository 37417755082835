import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

import {changeRegion} from '../../store/modules/region';
import {SearchContainer} from '../../styled/CommonStyle';

const Search = ({home, initLoadingNotFound}) => {
    const {t} = useTranslation();
    const inputRef = useRef(null);
    const modalEl = useRef();
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const [summonerName, setSummonerName] = useState('');
    const [isToggled, setIsToggled] = useState(false);
    const [autocomplete, setAutocomplete] = useState([]);
    const [isOpend, setIsOpend] = useState(false);
    const region = useSelector((state) => state.region.result);

    useEffect(() => {
        if (params.region && params.region !== region) {
            dispatch(changeRegion(params.region.toUpperCase()))
        }

        if (params.userName && params.userName) {
            setSummonerName(params.userName);
        }

    }, [params]);

    const [languageList, setLanguageList] = useState([
        {
            lang: 'KR',
            selected: 'KR' === region,
        },
        {
            lang: 'NA',
            selected: 'NA' === region,
        },
        {
            lang: 'EUW',
            selected: 'EUW' === region,
        },
    ]);

    // debounce 된 query로 자동완성
    const sendQuery = async (query) => {
        if (query.length === 0) return;
        try {
            // 추후 수정해야함  ---- parameter에 지역 추가 (지금은 지역상관없이 다나옴)
            // eslint-disable-next-line no-useless-escape
            const regExp = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
            if (!regExp.test(query)) {
                // 특수문자 제거
                const result = query.replace(regExp, '');
                await axios.get(`${process.env.REACT_APP_SEARCH}?term=${result}`).then((res) => {
                    const data = res?.data.hits.hit;
                    if (data.length !== 0) {
                        setAutocomplete(data);
                        setIsOpend(true);
                    } else {
                        setIsOpend(false);
                    }
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    // 자동완성 debounce
    const delayedQueryCall = useRef(_.debounce((q) => sendQuery(q), 500)).current;

    // search input에 값 입력시
    const handleSummonerName =  (e) => {
        const {value} = e.target;
        setSummonerName(value);
        delayedQueryCall(value);

        if (value.length < 1) {
            setAutocomplete([]);
            setIsOpend(false);
        }
    };

    // autocomplete 클릭시
    const handleSummonerSelect = (item) => {
        setSummonerName(item.fields.summonername);
        setIsOpend(false);
        inputRef.current.focus();
    };

    // search button 클릭시
    const handleSummonerSearch = () => {
        setIsOpend(false);

        if (initLoadingNotFound) {
            initLoadingNotFound();
        }

        // eslint-disable-next-line no-useless-escape
        const regExp = /(joined the room)|로비에|,|(,\s),/gi;
        if (!regExp.test(summonerName)) {
            // 전적검색으로
            if (summonerName !== "") {
                history.push({
                    pathname: `/summoner/${summonerName}/${region}`,
                    state: {name: summonerName},
                });
            }
            // history.go(0);
        } else {
            // 멀티서치로
            let arr_str = summonerName.replace(/(joined the room.)| (님이|로비에|참가하셨습니다.)|(,\s)|,|\n|\r/gi, '/');
            arr_str = arr_str.replace(/(\s\/)+|(\s\/)/gi, '/');
            arr_str = arr_str.replace(/(\/\s)|(\/)+/gi, '/');
            arr_str = arr_str.replace(/\/$/gi, '');
            arr_str = arr_str.split('/');

            // 중복, 빈값 제거
            const set = new Set(arr_str);
            const uniqueArr = [...set];
            const modyArr = uniqueArr.filter((item) => item !== '').slice(0, 5);

            history.replace({
                pathname: `/multi/${modyArr}`,
                state: {name: modyArr},
            });

        }
    };

    // toggle language 선택시
    const handleLangSelect = (item) => {
        dispatch(changeRegion(item.lang));
        setIsToggled(false);
        setLanguageList(
            languageList.map((itm) => {
                return item.lang === itm.lang ? {...itm, selected: true} : {...itm, selected: false};
            })
        );
    };

    // enter 입력시
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleSummonerSearch();
    };

    // 자동완성 닫기
    const handleClickOutside = useCallback(
        (e) => {
            if (isOpend && (!e.current || !e.current.contains(e.target))) setIsOpend(false);
            if (isToggled && (!e.current || !e.current.contains(e.target))) setIsToggled(false);
        },
        [isOpend, isToggled]
    );

    useEffect(() => {
        inputRef.current.focus();
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <SearchContainer home={home}>
            <div className="toggle">
        <span className="toggle__btn" onClick={() => setIsToggled(!isToggled)}>
          {region}
        </span>
                {isToggled && (
                    <ul className="toggle__list">
                        {languageList.map((item, index) => (
                            <li
                                key={index}
                                className={`toggle__list-item ${item.selected && 'selected'}`}
                                onClick={() => handleLangSelect(item)}
                            >
                                {item.lang}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            <div className="search">
                <input
                    type="text"
                    className="search__input"
                    value={summonerName}
                    onChange={handleSummonerName}
                    onKeyPress={handleKeyPress}
                    ref={inputRef}
                    placeholder={t('search.placeholder')}
                />
                <button className="search__btn common_search" onClick={handleSummonerSearch}>
                    {t('search.search')}
                </button>
                {isOpend && (
                    <ul className="search__autocomplete" ref={modalEl}>
                        {autocomplete.map((item) => (
                            <li
                                key={item.id}
                                className="search__autocomplete-item"
                                onClick={() => handleSummonerSelect(item)}
                            >
                                {item.fields.summonername}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </SearchContainer>
    );
};

export default Search;
