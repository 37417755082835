import React, {useCallback} from 'react';
import {getAiPrediction, getTierScore, tierBadge} from "../../../../function";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import SimpleTooltip from "../../../common/SimpleTooltip";


const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
`;

const TooltipWrapper = styled.div`
  margin-left: 2px;
`;


const InfoAiPrediction = ({queueNum, summoner, summary, matchInfo}) => {
    const {t} = useTranslation();

    const RenderWarning = useCallback(() => {

        let count;

        if (queueNum === 0 || queueNum === 420 || queueNum === 440) {
            if (queueNum === 0 || queueNum === 420) {
                count = matchInfo.reduce((sum, data, index) => {
                    if (data.queue === 'RANKED_SOLO_5x5') {
                        sum = sum + 1
                    }
                    return sum;
                }, 0);
            }


            if (queueNum === 440) {
                count = matchInfo.reduce((sum, data, index) => {
                    if (data.queue === 'RANKED_FLEX_SR') {
                        sum = sum + 1
                    }
                    return sum;
                }, 0);
            }


            if (count < 8) {
                return (
                    <TooltipWrapper>
                        <SimpleTooltip
                            tooltipText={t('summoner.aiTierPredictionExplain')}
                            imgSrc={"/images/ico_warning.svg"}
                        />
                    </TooltipWrapper>
                )
            }
        }
        return (<></>)
    }, [queueNum, summoner, matchInfo])


    return (
        <>
            <TextWrapper>
                <h5>{t('summoner.aiTierPreciction')}</h5>
                <RenderWarning/>
            </TextWrapper>
            <div className="predict">
                <p>
                    {((queueNum === 0 || queueNum === 420) && summoner.rank_json.RANKED_SOLO_5x5 !== undefined) && tierBadge(getTierScore(`${summoner.rank_json.RANKED_SOLO_5x5.tier} ${summoner.rank_json.RANKED_SOLO_5x5.division}`).txt)}
                    {queueNum === 440 && summoner.rank_json.RANKED_FLEX_SR !== undefined && tierBadge(getTierScore(`${summoner.rank_json.RANKED_FLEX_SR.tier} ${summoner.rank_json.RANKED_FLEX_SR.division}`).txt)}
                    {((queueNum === 0 || queueNum === 420) && summoner.rank_json.RANKED_SOLO_5x5 !== undefined) &&
                    <strong>{t('summoner.current')}</strong>}
                    {queueNum === 440 && summoner.rank_json.RANKED_FLEX_SR !== undefined &&
                    <strong>{t('summoner.current')}</strong>}
                </p>
                {((queueNum === 0 || queueNum === 420) && summoner.rank_json.RANKED_SOLO_5x5 !== undefined) &&
                <span className="arrow"></span>}
                {queueNum === 440 && summoner.rank_json.RANKED_FLEX_SR !== undefined &&
                <span className="arrow"></span>}

                <p>
                    {((queueNum === 0 || queueNum === 420) && summoner.rank_json.RANKED_SOLO_5x5 !== undefined) && tierBadge(getAiPrediction(summoner.rank_json.RANKED_SOLO_5x5, summary.ai_score).txt)}
                    {queueNum === 440 && summoner.rank_json.RANKED_FLEX_SR !== undefined && tierBadge(getAiPrediction(summoner.rank_json.RANKED_FLEX_SR, summary.ai_score).txt)}
                    {((queueNum === 0 || queueNum === 420) && summoner.rank_json.RANKED_SOLO_5x5 !== undefined) &&
                    <strong>{t('summoner.forecast')}</strong>}
                    {queueNum === 440 && summoner.rank_json.RANKED_FLEX_SR !== undefined &&
                    <strong>{t('summoner.forecast')}</strong>}
                </p>
            </div>
        </>
    );
};

export default InfoAiPrediction;