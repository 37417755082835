import React from 'react';
import styled from "styled-components";
import SimpleTooltip from "../../../common/SimpleTooltip";
import {useTranslation} from "react-i18next";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.div`
  padding: 1px;
  display: inline-block;
  margin-left: 8px;
`;

const InfoAIScore = () => {
    const {t} = useTranslation();
    return (
        <>
            <Wrapper>
                <h5>AI-Score</h5>
                <ImageWrapper>
                    <SimpleTooltip
                        imgSrc={'/images/list_btn_tip_s_w.svg'}
                        tooltipText={t('summoner.aiScorePredictionExplain')}
                    />
                </ImageWrapper>
            </Wrapper>
        </>
    );
};

export default InfoAIScore;