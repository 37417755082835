// 액션 생성
import {GET_IMAGE_DATA_REQUEST, GET_IMAGE_DATA_SUCCESS} from "../reducer/imageStore";

const IMAGE_STORE = 'imageStore/IMAGE_STORE';

// 액션 함수 내보내기
export const imageStore = (champions, runes, spells, items, tooltip) => ({
  type: IMAGE_STORE,
  champions,
  runes,
  spells,
  items,
  tooltip,
});

// 초기 상태 값
const initialState = {
  champions: [],
  spells: {},
  runes: [],
  items: {},
  tooltip: false,

  getImageDataLoading : false,
  getImageDataDone :false,
  getImageDataError : null,
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IMAGE_DATA_REQUEST: {
      return {
        getImageDataLoading : false,
        getImageDataDone :false,
        getImageDataError : null,
      }
    }
    case GET_IMAGE_DATA_SUCCESS: {
      return {
        getImageDataLoading : false,
        getImageDataDone :true,
      }
    }
    case GET_IMAGE_DATA_SUCCESS: {
      return {
        getImageDataLoading : false,
        getImageDataDone :false,
        getImageDataError : action.error,
      }
    }

    case IMAGE_STORE:
      return {
        champions: action.champions,
        spells: action.spells,
        runes: action.runes,
        items: action.items,
        tooltip: action.tooltip,
      };
    default:
      return state;
  }
};
export default reducer;
