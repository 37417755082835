import React from 'react';
import {useTranslation} from "react-i18next";

const SummonerNotFound = ({userName}) => {
    const {t} = useTranslation();

    return (
        <>
            <div className="not_found">
                <img src="/images/img_not_found.png" alt="?"/>
                <h5>
                    <span>"{userName}"</span> {t('summoner.notRegister')}
                </h5>
                <p>{t('summoner.notFound')}</p>
            </div>
        </>
    );
};

export default SummonerNotFound;