import React, {useCallback, useEffect, useState} from 'react';
import {kdaColor, scoreColor} from "../../../../function";
import styled from "styled-components";


const AiRenderWrapper = styled.div`
  cursor: pointer;
`;

const AiWrapper = styled.div`
    padding-top: 9px;
`;
const AiText = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 0;
  justify-content: center;
  align-items: center;
`;

const CrownImg = styled.img`
  width: 12px;
  height: 9px;
  margin-right: 3px;
`;

const KDAWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 6px 0 3px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex:1;
`;

const KDATitle = styled.span`
  padding: 1px 5px;
  color: #313131;
  font-size: 12px;
  text-align: center;
`

const KDAValue = styled.span`
  padding: 1px 3px 1px 0;
  color: #313131;
  font-size: 12px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
`;

const DeathValue = styled.span`
  padding: 1px 3px 1px 0;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  color: #DE5353;
  font-size: 12px;
`;

const MultiItemScore = ({item, t, target = 0,typeButton,setTypeButton}) => {

    const [changeRender, onChangeRender] = useState(target);
    useEffect(() => {
        onChangeRender(target)
        setTypeButton(false);
    }, [target,typeButton])

    const onClickRender = useCallback((value) => {
        if (value === 0) {
            onChangeRender(1)
        } else if (value === 1) {
            onChangeRender(0)
        }
    }, [])

    const renderKda = () => {
        return (<>
            <KDAWrapper onClick={() => onClickRender(1)}>
                <FlexWrapper>
                    <KDATitle>
                        K
                    </KDATitle>
                    <KDATitle>
                        D
                    </KDATitle>
                    <KDATitle>
                        A
                    </KDATitle>
                </FlexWrapper>
                <FlexWrapper>
                    <KDAValue>
                        {item.kill}
                    </KDAValue>
                    <DeathValue>
                        {item.death}
                    </DeathValue>
                    <KDAValue>
                        {item.assist}
                    </KDAValue>
                </FlexWrapper>
            </KDAWrapper>
        </>)
    }

    const renderAi = () => {
        return (<>
            <AiRenderWrapper onClick={() => onClickRender(0)}>
                <AiWrapper>
                    <AiText>{item.check_away ? scoreColor(0) : scoreColor(item.ai_score.toFixed(0))}</AiText>
                </AiWrapper>
                {/*<div className="kda">*/}
                {/*    {item.check_away ? (*/}
                {/*        '-'*/}
                {/*    ) : item.death === 0 ? (*/}
                {/*        <span className="kda_color perfect">Perfect</span>*/}
                {/*    ) : (*/}
                {/*        kdaColor(item.kda.toFixed(2))*/}
                {/*    )}*/}
                {/*</div>*/}
                <RatingWrapper>
                    {item.mvp === 1 && <CrownImg src="/images/ico_crown_gold_w.svg" alt="mvp"/>}
                    {item.ace === 1 && <CrownImg src="/images/ico_crown_silver_w.svg" alt="mvp"/>}
                    {item.check_away ? (<span
                        className="away">{t('tags.escape')}</span>) : item.ai_rank === 1 ? (item.ai_rank + t('summoner.firstGrade')) : item.ai_rank === 2 ? (item.ai_rank + t('summoner.secondGrade')) : item.ai_rank === 3 ? (item.ai_rank + t('summoner.thirdGrade')) : (item.ai_rank + t('summoner.grade'))}
                </RatingWrapper>
            </AiRenderWrapper>
        </>)
    }

    if(!item) return <></>
    return (<>
        {changeRender === 0 && renderAi()}
        {changeRender === 1 && renderKda()}

    </>);
};

export default MultiItemScore;