import React from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {bookmarkList} from '../../store/modules/bookmarkList';
import {StorageContainer} from '../../styled/CommonStyle';

const BookmarkList = () => {
    const dispatch = useDispatch();
    const bookmarks = useSelector((state) => state.bookmarkList.bookmarks);
    const region = useSelector((state) => state.region.result);
    const viewBookmarks = [...bookmarks].reverse();
    const {t} = useTranslation();

    // 즐겨찾기 항목 제거
    const removeBookmark = (item) => {
        dispatch(bookmarkList(item, 'remove'));
    };

    return (
        <StorageContainer>
            {bookmarks.length !== 0 ? (
                <ul className="bookmark_list">
                    {viewBookmarks.map((item, index) => (
                        <li key={index}>
                            <p className="txt">
                                <Link
                                    to={`/summoner/${item.summoner_name}/${item.region ? item.region : region}`}
                                    className="common_search">
                                    {item.summoner_name}
                                </Link>
                            </p>
                            <button className="remove" onClick={() => removeBookmark(item)}>
                                {t('buttons.delete')}
                            </button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="no_list">{t('persist.noFavorite')}</p>
            )}
        </StorageContainer>
    );
};

export default BookmarkList;
