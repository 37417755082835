// 액션 생성
const THEME_TYPE = 'theme/THEME_TYPE';

// 액션 함수 내보내기
export const theme = (color) => ({ type: THEME_TYPE, color });

// 초기 상태 값
const initialState = {
  color: 'skyblue',
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case THEME_TYPE:
      return {
        color: action.color,
      };
    default:
      return state;
  }
};
export default reducer;
