// 액션 생성
const REGION = 'summoner/REGION';

// 액션 함수 내보내기
export const changeRegion = (result) => ({type: REGION, result});


const countryCodes = [{
    code: 'KR',
    language: 'ko',
    region: 'KR'
},{
    code: 'US',
    language: "en",
    region: 'NA',
}, {
    code : 'GB',
    language: 'en',
    region: 'EUW',
}, {
    code : 'IE',
    language: 'en',
    region: 'EUW',
}, {
    code: 'BE',
    language: 'en',
    region: 'EUW',
},{
    code: 'DK',
    language: 'en',
    region: 'EUW',
},{
    code: 'FI',
    language: 'en',
    region: 'EUW',
},{
    code: 'FR',
    language: 'en',
    region: 'EUW',
}, {
    code: 'DE',
    language: 'en',
    region: 'EUW',
},{
    code: 'IS',
    language: 'en',
    region: 'EUW',
}, {
    code: 'IT',
    language: 'en',
    region: 'EUW',
},{
    code: 'ES',
    language: 'en',
    region: 'EUW',
},{
    code: 'AD',
    language: 'en',
    region: 'EUW',
},{
    code: 'LU',
    language: 'en',
    region: 'EUW',
},{
    code: 'NL',
    language: 'en',
    region: 'EUW',
},{
    code: 'CH',
    language: 'en',
    region: 'EUW',
}]


// 초기 상태 값
const lang = window.navigator.language.toUpperCase().substring(window.navigator.language.length - 2,window.navigator.language.length);
const initialState = {
    result : lang && (countryCodes.find((data,indes) => data.code == lang))?.region || 'KR',
    // result: lang ?
    //     lang === 'ko' ? 'KR' : lang === 'en' ? 'NA' : 'EUW'
    //     : 'KR',
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REGION:
            return {
                result: action.result,
            };
        default:
            return state;
    }
};
export default reducer;
