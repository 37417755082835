import React, {useState} from 'react';
import styled from "styled-components";

const Arrow = styled.span`
  display: flex;
  flex-direction: column;
  width: 6px;
  height: 12px;
  margin-left: 7px;

  &::before {
    content: '';
    display: inline-block;
    flex: 1;
    opacity: ${props => props.arrow ? 1 : 0.4};
    background: url('/images/ico_graph_arrow_up.svg') no-repeat center top;
  }

  &::after {
    content: '';
    display: inline-block;
    flex: 1;
    opacity: ${props => props.arrow ? 0.4 : 1};
    background: url('/images/ico_graph_arrow_down.svg') no-repeat center bottom;
  }`;

const ArrowTitleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

const ChampArrow = ({title, arrow = false, extra}) => {


    return (
        <>
            <ArrowTitleWrapper>
                <span className="name">{title}
                    {extra &&
                        <i>
                            ({extra})
                        </i>
                    }
                </span>
                <Arrow arrow={arrow}/>
            </ArrowTitleWrapper>
        </>
    );
};

export default ChampArrow;