import React, {useEffect} from 'react';
import useQuery from "../../common/hooks/useQuery";
import {useHistory} from "react-router-dom";

const ToMobile = (props) => {
    const query = useQuery();
    const storedPcScreen = localStorage.getItem('pc');
    const history = useHistory();

    useEffect(() => {
        if (!storedPcScreen) {
            const pathname = window.location.pathname;
            const reg = /summoner/g;
            if (reg.test(pathname)) {
                const splited = pathname.split('/');
                const params = `/summoner?userName=${splited[2]}&region=${splited[3] ? splited[3] : 'KR'}`;
                if (splited[2]) {
                    window.location.href = 'https://m.deeplol.gg' + params;
                } else {
                    window.location.href = 'https://m.deeplol.gg'
                }
            } else {
                window.location.href = 'https://m.deeplol.gg'
            }
        } else {
            history.replace({
                pathname: "/",
            })
        }
    }, []);

    return <></>;
};

export default ToMobile;
